import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { DateForm, InputDateFormGroup } from '../forms';
import { Button } from 'react-bootstrap';

const DailyReadingsChartForm = ({
  title,
  inputName,
  labelName,
  inputValue,
  minInputDate,
  testIdValue,
  setFn,
  colResponsiveValue,
  onDownload,
  enabledDownloadBtn=false
}) => {
  const minInputValue = useMemo(() =>dayjs({ minInputDate }).format('YYYY-MM-DD'));
  const maxInputValue = useMemo(() => dayjs(new Date()).format('YYYY-MM-DD'));

  return (
    <DateForm title={title} colResponsiveValue={colResponsiveValue} extraRowClasses="align-items-center" className="date-form">
      <InputDateFormGroup
        labelName={labelName}
        controlId='dailyFormDateFilter'
        inputName={inputName}
        minInputValue={minInputValue}
        maxInputValue={maxInputValue}
        inputValue={inputValue}
        setFn={setFn}
        colResponsiveValue={colResponsiveValue}
        testIdValue={testIdValue}
      />
      <div></div>
      <div className="d-flex justify-content-end align-items-center align-self-center">
        <Button disabled={!enabledDownloadBtn} onClick={onDownload}>Descargar medidas del día</Button>
      </div>
      
    </DateForm>
  );
};

export default DailyReadingsChartForm;