import { gql } from 'apollo-boost';

const COSTS_BREAKDOWN_DATA = gql`
  query costBreakdown($clientId: ID!,  $period: Int) {
     costBreakdown(clientId: $clientId, period: $period) {
      fixedCost
      baseConsumptionCost
      intermediateConsumptionCost
      highConsumptionCost
      baseDistributionCost
      intermediateDistributionCost
      highDistributionCost
      baseCapacityCost
      intermediateCapacityCost
      highCapacityCost
      baseConsumption
      intermediateConsumption
      highConsumption
    }
  }
`;

export default COSTS_BREAKDOWN_DATA;