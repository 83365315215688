import { gql } from 'apollo-boost';

const SOLAR_SYSTEMS_PER_MONTH = gql`
  query solarSystemsPerMonth($q: BaseScalar, $start_date: ISO8601Date!, $end_date: ISO8601Date!) {
    solarSystemsPerMonth(startDate: $start_date, endDate: $end_date, q: $q) {
      solarSystems
      month
    }
  }
`;

export default SOLAR_SYSTEMS_PER_MONTH;
