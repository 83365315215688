import { gql } from "apollo-boost";

const EDIT_CLIENT = gql`
  mutation updateClientMutation(
    $clientAttributes: UserInput!,
    $homeAttributes: AddressInput,
    $cfeHomeAttributes: CfeHomeInput,
    $meterGatewaysAttributes: [GatewayInput!],
    $cfeInvoiceAttributes: CfeInvoiceInput,
    $deviceAttributes: [DeviceInput!],
    $inverterAttributes: [InverterInput!]
    ) {
    updateClientMutation(
      input: {
        clientAttributes: $clientAttributes,
        homeAttributes: $homeAttributes,
        cfeHomeAttributes: $cfeHomeAttributes,
        meterGatewaysAttributes: $meterGatewaysAttributes,
        cfeInvoiceAttributes: $cfeInvoiceAttributes,
        deviceAttributes: $deviceAttributes,
        inverterAttributes: $inverterAttributes
      }
    ) {
      client {
        id
        email
        name
      }
    }
  }
`;

export default EDIT_CLIENT;