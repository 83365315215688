export const getDisplayValue = (value) => {
  if (value === '') {
    return 'n/d'
  }

  return value ?? 'n/d';
};

export const firstLetter = (user) => {
  return user ? (user.name ? user.name[0] : user.email[0]).toUpperCase() : 'U';
};

export const toCurrency = (value) => {
  const currencyValue = Number(value).toLocaleString(
    'en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }
  );

  return currencyValue;
};

export const toCurrencyLabel = (value) => {
  const currencyValue = toCurrency(value);

  return value === 0 ? currencyValue : `${currencyValue}`;
};

export const getConfigPairs = (configs) => {
  return Object.keys(configs).map((key) => [
    key,
    configs[key]
  ]);
};

export const getLegendPayload = (configPairs) => {
  return configPairs.map(config => ({
    dataKey: config[0],
    color: config[1].color,
    name: config[1].legendName
  }));
};

export const chartMaxValue = (input) => {
  if (isNaN(input)) {
    return 0;
  }

  input = Math.round(input);
  while (!input.toString().match(/^\d(0+|5(0)+)$/)) {
    input++;
  }
  return input;
}