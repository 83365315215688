import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import ChartsContainer from './ChartsContainer/ChartsContainer';
import USERNAME_GATEWAY from '../../graphql/queries/username_gateway';
import Loader from '../shared/Loader/Loader';
import SavingsContainer from './SavingsContainer/SavingsContainer';
import ConfirmationToast from '../shared/ConfirmationToast/ConfirmationToast';
import ConsumptionSourceGraphContainer from './ConsumptionSourceGraphContainer/ConsumptionSourceGraphContainer';
import { useActiveKey } from '../../contexts/ProviderContext/ActiveKeyContext';

function Dashboard({ clientId, canEdit = false }) {
  const history = useHistory();
  const { activeKey } = useActiveKey();

  const { data: usernameGatewayData, loading: usernameGatewayLoading } = useQuery(USERNAME_GATEWAY,  {
    variables: { clientId: clientId }
  }); 
  
  const meterDevices = usernameGatewayData?.usernameGateway?.meterGateways?.map(gateway => gateway.meterDevices).flat()
  const hasTotalDevices = meterDevices?.map(device => device.measureType).includes(2) || false;
  const feeType = usernameGatewayData?.usernameGateway?.client?.cfeHome?.cfeFeeType || '';
  const [showToast, setShowToast] = useState(false);
  const devicesNames = meterDevices?.map(device => device.name);
  
  useEffect(() => {
    if (history.location.state) {
      setShowToast(true);
    }
  },[history.location.state]);

  return (
    <div>
      { usernameGatewayLoading && <Loader message="Cargando..." /> }
      { !usernameGatewayLoading &&
        <React.Fragment>
          <Container style={{fontWeight: '300'}} className="client-dashboard" data-testid='client-dashboard'  >
            <ChartsContainer 
              clientId={clientId} 
              hasTotalDevices={hasTotalDevices}
              feeType={feeType} 
              devicesNames={devicesNames} 
              activeKey={activeKey}/>
            
            {activeKey === "consumptionAndGeneration" && (
              <div className='mb-5'>
                <ConsumptionSourceGraphContainer clientId={clientId} feeType={feeType}/>
                <SavingsContainer clientId={clientId} feeType={feeType}/>
              </div>
            )}
            <Row>
              <Col>
                {history.location.state &&
                  <ConfirmationToast
                    header="Éxito"
                    message={`Se han guardado los cambios del cliente ${history.location.state.successfulResponse.name}`}
                    isSuccess={true}
                    setShowToast={setShowToast}
                    showToast={showToast}/>
                }
              </Col>
            </Row>
        </Container>
      </React.Fragment>
      }
    </div>
  );
}

export default Dashboard;
