import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import Loader from '../../../shared/Loader/Loader';

const ChartWrapper = ({ loading, graphDataLength, testIdValue, children, title}) => {
  const displayContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (graphDataLength > 0) {
      return (
        <Row data-testid={testIdValue}>
          <Col>{children}</Col>
        </Row>
      );
    } else {
      return (
        <div className="d-flex justify-content-center chart-bg" >
          <p
            className="px-5 py-5 text-muted font-weight-normal"
            data-testid="chart-container-no-data-available"
          >
            No tenemos datos para este periodo.
          </p>
        </div>
      );
    }
  };

  return (
    <Card className="accumulated-costs chart-bg h-100">
      <Card.Body>
          <h5 className="text-secondary text-center mb-3">{title}</h5>
        <Row >
          <Col>{displayContent()}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ChartWrapper;
