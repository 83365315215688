import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({
  show,
  handleClose,
  handleConfirmation,
  inputConfirmation,
  message,
  modalTitle,
  error,
}) => {
  return (
    <Modal show={show} onHide={handleClose} data-testid="confirmation-modal">
      <Modal.Header closeButton>
        <Modal.Title data-testid="modal-title">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid="modal-body">{error.length === 0 ? message : error}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleClose}
          data-testid="cancel-button"
        >
          Cancelar
        </Button>
        {error.length === 0 && (
          <Button
            variant="text-muted"
            onClick={() => handleConfirmation(inputConfirmation)}
            data-testid="ok-button"
          >
            Ok
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
