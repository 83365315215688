import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { companyName } from '../../shared/whiteLabel/whiteLabel';

function Footer() {
  return (
    <footer className="text-secondary mt-4 py-1">
        <Row className="align-items-center pb-3 text-muted">
          <Col>{companyName()} {new Date().getFullYear()} &copy;</Col>
        </Row>
    </footer>
  );
}

export default Footer;
