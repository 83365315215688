import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ClientNavbarHeader from '../shared/ClientNavbarHeader/ClientNavbarHeader';
import Footer from '../shared/Footer/Footer';
import PrivateRoute from '../../routing/PrivateRoute';
import Container from 'react-bootstrap/Container';
import Sidebar from '../SideBar/SideBar';
import { ActiveKeyProvider } from '../../contexts/ProviderContext/ActiveKeyContext';
import { Col, Row } from 'react-bootstrap';
import { FilterProvider } from '../../contexts/ProviderContext/DashboardFilterContext';
import { ListFilterProvider } from '../../contexts/ProviderContext/ClientFiltersContext';

const ClientLayout = ({ routes, match }) => {
  return (
    <React.Fragment>
      <ActiveKeyProvider> <FilterProvider> <ListFilterProvider>
        <Container fluid className="gradient-background">
          <Row className="d-flex">
            <Col className='px-0'>
            <Sidebar currentPage={'/client'} />
            </Col>
            <Col className='content-column'>
              <ClientNavbarHeader />
                <Container fluid className="my-2 client-layout">
                  <Switch>
                    {routes.map((route, _i) => {
                      return (
                        <PrivateRoute
                          path={`${match.path}${route.path}`}
                          exact={route.exact}
                          roleLevel={route.roleLevel}
                          key={route.key}
                          component={route.component}
                        />
                      );
                    })}
                <Route component={() => <h1>Not Found!</h1>} />
              </Switch>
            </Container>
            </Col>
          </Row> 
          <Row className='my-0 py-0'>
            <Container fluid className="bg-darks m-0">
              <Footer className="footer"/>
            </Container>
          </Row>


        </Container>
        </ListFilterProvider></FilterProvider></ActiveKeyProvider>
    </React.Fragment>
  );
}

export default ClientLayout;
