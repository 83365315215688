import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import bg from '../../images/login-background.png';
import Loader from '../shared/Loader/Loader';
import GATEWAY_INFO from '../../graphql/queries/gateway_info';
import { Alert } from 'react-bootstrap';
import DailyReadingsChart from '../Dashboard/ChartsContainer/DailyReadingsChart/DailyReadingsChart';
import InfoMessage from '../shared/InfoMessage/InfoMessage';
import { ALL_DAILY_READINGS_CHART_CONFIGS } from '../shared/Charts/chartsConfig';
import { homeLogo, homeLogoAlt } from '../shared/whiteLabel/whiteLabel';

const currentDate = dayjs(new Date()).format('YYYY-MM-DD');

const GatewayInfo = ({ match }) => {
  const { gatewayName } = match.params;
  const { data: gatewayInfoData, loading: loadingGateway, error: gatewayError } = useQuery(GATEWAY_INFO, {
    variables: { meterGatewayName: gatewayName }
  });
  const activationCode = gatewayInfoData?.gatewayInfo.meterGateway.activationCode;
  const invalidIdInfoMessage = 'Este código de validación se le debe compartir al instalador para finalizar la instalación de este cliente.';

  const isGatewayInvalid = () => {
    return gatewayInfoData?.gatewayInfo.meterGateway.status === 'invalid';
  }

  const displayInvalidInstalationId = () => {
    return (
      <Alert variant="warning" className="w-100 text-center m-0 mt-4" data-testid="invalid-installation">
        <FontAwesomeIcon icon="exclamation-triangle" className="warning mr-2"/>
        La instalación de este cliente no ha sido validada, por lo que puede tener información incorrecta/incompleta.
        {activationCode && 
          <div> El código de validación es: <span className="text-muted">{activationCode ?? 'n/d'} </span> 
          <InfoMessage placement="left" message={invalidIdInfoMessage}/> </div>
        }
        
      </Alert>
    )
  }

  if (loadingGateway) {
    return <Loader message="Buscando Gateway..." />
  }

  return (
    <Container fluid className="d-flex flex-column align-items-center">
      <img src={bg} alt="Background" className="login-bg" />
      <Row className="my-2 w-100">
        <Col className="d-flex flex-column justify-content-center">
          <Row>
            <Col className="text-center">
              <img
                src={homeLogo()}
                alt={homeLogoAlt()}
                className="home-logo"
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="text-center">
              <div>
                <h4 className="font-weight-bold text-muted">
                  Lecturas del Gateway: {gatewayName}
                </h4>
              </div>
              <div>
                {gatewayError &&
                  <Alert variant="danger">
                    {gatewayError.graphQLErrors.map(({ message }, i) => (
                        <div key={i}>{message}</div>
                      ))
                    }
                  </Alert>
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="w-100 mt-5 d-flex flex-column text-center">
        <Col>
          {!gatewayError && !isGatewayInvalid() &&
            <Alert variant="danger">
              Este gateway ya fue validado. Solo el proveedor o el cliente pueden ver las lecturas <Link to="/">iniciando sesión</Link>.
            </Alert>
          }
          {!gatewayError && isGatewayInvalid() &&
            <Row className="m-0 d-flex justify-content-center justify-content-md-end">
              {displayInvalidInstalationId()}
              <DailyReadingsChart clientId={gatewayInfoData.gatewayInfo.clientId} dateFilter={currentDate} linesConfig={ALL_DAILY_READINGS_CHART_CONFIGS} />
            </Row>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default GatewayInfo;
