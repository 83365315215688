import { gql } from 'apollo-boost';

const GATEWAY_INFO = gql`
  query gatewayInfo($meterGatewayName: String!) {
    gatewayInfo(meterGatewayName: $meterGatewayName) {
      meterGateway {
        name
        status
        activationCode
      }
      clientId
    }
  }
`;

export default GATEWAY_INFO;
