import React, { useState } from 'react';
import { Tabs, Tab, Row, Col, Container } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import DailyReadingsChart from './DailyReadingsChart/DailyReadingsChart';
import { useDailyReadingsChart } from './DailyReadingsChart/useDailyReadingsChart';
import LiveConsumption from './LiveConsumption/LiveConsumption';
import { useLiveConsumption } from './LiveConsumption/useLiveConsumption';
import GdmthDayConsumption from './GdmthDayConsumption/GdmthDayConsumption';
import { useTotalGdmthDayConsumption } from './GdmthDayConsumption/useGdmthDayConsumption';
import GdmthDemand from './GdmthDayConsumption/DemandChart/DemandChart';
import { useCfeDemandChart, useTotalDemandChart } from './GdmthDayConsumption/DemandChart/useDemandChart';
import MonthlyConsumption from './MonthlyConsumption/MonthlyConsumption';
import { useMonthlyConsumption } from './MonthlyConsumption/useMonthlyConsumption';
import { useOperativeReadingsChart } from './OperativeReadingsChart/useOperativeReadings';
import OperativeReadingsCharts from './OperativeReadingsChart/OperativeReadingsCharts';
import {
  ALL_DAILY_READINGS_CHART_CONFIGS,
  ALL_LIVE_AND_MONTHLY_CHART_CONFIGS,
  ALL_DAILY_READINGS_CHART_CONSUMPTION_CONFIGS,
  ALL_LIVE_AND_MONTHLY_CHART_CONSUMPTION_CONFIGS,
  MEDIUM_VOLTAGE_CONSUMPTION_CONFIGS,
  MEDIUM_VOLTAGE_GENERATION_CONFIGS,
  TOTAL_DEMAND_CONFIGS,
  CFE_DEMAND_CONFIGS,
  OPERATIVE_LINES_CONFIGS,
  selectConfigs
} from '../../../components/shared/Charts/chartsConfig';
import ChartWrapper from './ChartWrapper/ChartWrapper';
//import MonthlyConsumptionForm from './ChartsForms/MonthlyConsumptionForm/MonthlyConsumptionForm';
import DatesRangeForm from './ChartsForms/DatesRangeForm/DatesRangeForm';
import DailyReadingsChartForm from './ChartsForms/DailyReadingsChartForm/DailyReadingsChartForm';
import { FEE_TYPES } from '../../shared/CfeFees/feeTypes';
import { downloadToCSV } from '../../shared/sharedFunctions/export_excel';
import { fetchReadings } from '../../../services/greenapsis_service/readings_service';
import DemandSwitch from './GdmthDayConsumption/DemandChart/DemandSwitch/DemandSwitch';
import OperativeReadingsChartForm from './ChartsForms/OperativeReadingsChartForm/OperativeReadingsChartForm';
import { I_ICON_MESSAGES } from '../../shared/InfoMessage/messages';
import { useActiveKey } from '../../../contexts/ProviderContext/ActiveKeyContext';


dayjs.extend(utc);

const getMonthlyFormatDate = (startDate, endDate) => {
  const monthStartDate = dayjs(startDate).format('YYYY-MM');
  const monthEndDate = dayjs(endDate).format('YYYY-MM');

  const [year, month, day] = monthEndDate.split('-');
  let daysInEndDate = new Date(year, month, 0);
  daysInEndDate = dayjs(daysInEndDate).format('DD');

  const formatedStartDate = `${monthStartDate}-01`;
  const formatedEndDate = `${monthEndDate}-${daysInEndDate}`;

  return { formatedStartDate, formatedEndDate };
};

const getDemandStartDate = (startDate, endDate) => {
  const monthStart = dayjs(endDate).format('YYYY-MM');

  const formatedStartDate = `${monthStart}-01`;
  const formatedEndDate = `${endDate}`;

  return { formatedStartDate, formatedEndDate };
};

const ChartsContainer = ({ clientId, hasTotalDevices, devicesNames, feeType='' }) => {
  const { activeKey, setActiveKey } = useActiveKey();
  
  let currentDate = dayjs(new Date()).format('YYYY-MM-DD');
  let prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 30);
  prevDate = dayjs(prevDate).format('YYYY-MM-DD');

  let currentMonthlyDate = dayjs(new Date()).format('YYYY-MM');
  let prevMonthlyDate = new Date();
  prevMonthlyDate.setDate(prevMonthlyDate.getDate() - 30);
  prevMonthlyDate = dayjs(prevDate).format('YYYY-MM');


  const [consumptionAndGenerationDate, setConsumptionAndGenerationDate] = useState({
    dateFilter: currentDate
  });

  const [operativeDate, setOperativenDate] = useState({
    dateFilter: currentDate
  });

  const [dailyAndMonthDate, setDailyAndMonthDate] = useState({
    startDate: prevDate,
    endDate: currentDate
  })

  const [isDemandSwitchOn, setIsDemandSwitchOn] = useState(true);

  const OperativeTitles = {
    amps: 'Amps',
    volts: 'Volts',
    watts: 'Watts',
    pf: 'Factor de Potencia'
  }

  function dataTypeTitle(dataType) {
    return OperativeTitles[dataType] || dataType;
  }

  // tiempo real
  const {
    loading: loadingReadings,
    graphData: dailyReadingsData,
    minValue: dailyReadingsMinValue,
    maxValue: dailyReadingsMaxValue
  } = useDailyReadingsChart(
    Number(clientId),
    consumptionAndGenerationDate.dateFilter
  );

  const monthlyFormatedDateHookVariables = getMonthlyFormatDate(
    dailyAndMonthDate.startDate,
    dailyAndMonthDate.endDate
  );

  const {
    loading: loadingMonthly,
    graphData: monthlyData,
    minValue: monthlyMinValue,
    maxValue: monthlyMaxValue
  } = useMonthlyConsumption(
    Number(clientId),
    monthlyFormatedDateHookVariables.formatedStartDate,
    monthlyFormatedDateHookVariables.formatedEndDate
  );
  // diaria
  const {
    loading: loadingLive,
    graphData: liveData,
    minValue: liveMinValue,
    maxValue: liveMaxValue
  } = useLiveConsumption(
    Number(clientId),
    dailyAndMonthDate.startDate,
    dailyAndMonthDate.endDate
  );

  // gdmth Diario
  const {
    loading: loadingGdmthDay,
    graphData: GdmthDayData,
    minValue: GdmthDayMinValue,
    maxValue: GdmthDayMaxValue
  } = useTotalGdmthDayConsumption(
    Number(clientId),
    dailyAndMonthDate.startDate,
    dailyAndMonthDate.endDate
  );

  const demandFormatedDateHookVariables = getDemandStartDate(
    dailyAndMonthDate.startDate,
    dailyAndMonthDate.endDate
  );
   // GDMTH Demanda CFE
   const {
    loading: loadingCfeDemand,
    graphData: CfeDemandData,
    minValue: CfeDemandMinValue,
    maxValue: CfeDemandMaxValue
  } = useCfeDemandChart(
    Number(clientId),
    demandFormatedDateHookVariables.formatedStartDate,
    demandFormatedDateHookVariables.formatedEndDate
  );

   // GDMTH total Demanda
   const {
    loading: loadingTotalDemand,
    graphData: TotalDemandData,
    minValue: TotalDemandMinValue,
    maxValue: TotalDemandMaxValue
  } = useTotalDemandChart(
    Number(clientId),
    demandFormatedDateHookVariables.formatedStartDate,
    demandFormatedDateHookVariables.formatedEndDate
  );

   // Operative
   const {
    loading: loadingOperative,
    graphData: operativeData,
    minValue: minValueOperative,
    maxValue: maxValueOperative
  } = useOperativeReadingsChart(
    Number(clientId),
    operativeDate.dateFilter
  );
  
  const [fetchingGreenapsis, setFetchingGreenapsis] = useState(false);

  const onDownload = (e) => {
    e.preventDefault();
    if (devicesNames.length === 0) return;

    const date = new Date(`${consumptionAndGenerationDate.dateFilter} 00:00:00`);
    const filename = `medidas-del-dia-${date.toISOString().split('T')[0]}`.replace(/\s/g, '');
    const startDay = dayjs(date).startOf('day').utc().unix() * 1000;
    const endDay = dayjs(date).endOf('day').utc().unix() * 1000;

    let devicesReadings = {}
    setFetchingGreenapsis(true);
    Promise.all(devicesNames.map(name => fetchReadings(name, startDay, endDay)))
           .then(responses => {
             devicesNames.forEach((deviceName, index) => {
               const data = responses[index].data.data;
               const processedData = data.map(d => {
                let lines = {}
                const { timestamp, fkwh, nkwh, rkwh } = d;
                d.lines.forEach((line, idx) => {
                  lines[`nline_${idx}_amps`] = line.amps;
                  lines[`nline_${idx}_volts`] = line.volts;
                  lines[`nline_${idx}_pf`] = line.pf;
                  lines[`nline_${idx}_watts`] = line.watts;
                })
                return { timestamp, fkwh, nkwh, rkwh, ...lines }
               })
               devicesReadings[deviceName] = processedData;
             })
             downloadToCSV(devicesReadings, filename);
             setFetchingGreenapsis(false);
           })
           .catch(err => {
             alert('No se pudo obtener los datos de greenapsis');
           })
  }

  return (
    <div>
    <Tab.Container id="chartsContainer" data-testid='charts-container' activeKey={activeKey} className="d-flex">
      <Row>
        <Col>
          <Tab.Content className='mb-3'data-testid='tab-content' >
            {/* tiempo real */}
            <Tab.Pane eventKey="consumptionAndGeneration">
              <OperativeReadingsChartForm
                title="Tu consumo y generación de energía"
                inputName="dateFilter"
                labelName="Selecciona el día"
                inputValue={consumptionAndGenerationDate.dateFilter}
                minInputDate="01-01-2020"
                testIdValue="daily-readings-form"
                setFn={setConsumptionAndGenerationDate}
                colResponsiveValue={4}
                onDownload={onDownload}
                enabledDownloadBtn={devicesNames?.length > 0 && !fetchingGreenapsis}
              />
              {hasTotalDevices && (
                <Row data-testid="total-daily-readings" className='mt-5'>
                  <Col>
                    <ChartWrapper
                      loading={loadingReadings}
                      graphDataLength={dailyReadingsData.length}
                      testIdValue="daily-readings-chart"
                    >
                      <DailyReadingsChart
                        graphData={dailyReadingsData}
                        minValue={dailyReadingsMinValue}
                        maxValue={dailyReadingsMaxValue}
                        hasTotalDevices={true}
                        configs={selectConfigs(
                          ['cfeConsumption', 'solarConsumption', 'injectedGeneration'],
                          ALL_DAILY_READINGS_CHART_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                </Row>
              )} 
             {!hasTotalDevices && (
                <Row data-testid="no-total-daily-readings" className='mt-5'>
                  <Col className='my-3'>
                    <ChartWrapper
                      loading={loadingReadings}
                      graphDataLength={dailyReadingsData.length}
                      testIdValue="daily-readings-chart"
                    >
                      <DailyReadingsChart
                        graphData={dailyReadingsData}
                        minValue={dailyReadingsMinValue}
                        maxValue={dailyReadingsMaxValue}
                        hasTotalDevices={false}
                        configs={selectConfigs(
                          ['cfeConsumption', 'consumedGeneration'],
                          ALL_DAILY_READINGS_CHART_CONSUMPTION_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                </Row>
              )}
           
            </Tab.Pane > 
            {/* Consumo y Generación por Horario */}
              <Tab.Pane eventKey="gdmthDaily">
                <DatesRangeForm
                  startDate={dailyAndMonthDate.startDate}
                  endDate={dailyAndMonthDate.endDate}
                  title="Consumo por día"
                  labelNames={{ startDate: 'Desde', endDate: 'Hasta' }}
                  minInputDate="01-01-2020"
                  inputValues={{
                    startDate: dailyAndMonthDate.startDate,
                    endDate: dailyAndMonthDate.endDate,
                  }}
                  inputNames={{ startDate: 'startDate', endDate: 'endDate' }}
                  setFn={setDailyAndMonthDate}
                  testIdValues={{
                    startDate: 'start-day-input',
                    endDate: 'end-day-input',
                  }}
                  colResponsiveValue={4}
                  controlIdStart='gdmthFormStartDate'
                  controlIdEnd='gdmthFormEndDate'
                />
                {feeType === FEE_TYPES['gdmth'] && (
                <div>
                  <h5 className="mt-5 text-secondary text-center">{"Consumo y Generación por Horario"}</h5>                  
                  <Row data-testid="gdmth-chart" >
                    <Col className='mr-4 my-4'>
                      <ChartWrapper
                        loading={loadingGdmthDay}
                        graphDataLength={GdmthDayData.length}
                        testIdValue="gdmth-consumption"
                        title='Consumo'
                      >
                        <GdmthDayConsumption
                          graphData={GdmthDayData}
                          minValue={GdmthDayMinValue}
                          maxValue={GdmthDayMaxValue}
                          hasTotalDevices={false}
                          gdmthDayConsumptionConfig={selectConfigs(
                            ['baseConsumption', 'intermediateConsumption', 'highConsumption'],
                            MEDIUM_VOLTAGE_CONSUMPTION_CONFIGS
                          )}
                        />
                      </ChartWrapper>
                    </Col>
                    <Col className='ml-4 my-4'>
                      <ChartWrapper
                        loading={loadingGdmthDay}
                        graphDataLength={liveData.length}
                        testIdValue="gdmth-generation"
                        title='Generación'
                      >
                        <GdmthDayConsumption
                          graphData={GdmthDayData}
                          minValue={GdmthDayMinValue}
                          maxValue={GdmthDayMaxValue}
                          hasTotalDevices={false}
                          gdmthDayConsumptionConfig={selectConfigs(
                            ['baseGeneration', 'intermediateGeneration', 'highGeneration'],
                            MEDIUM_VOLTAGE_GENERATION_CONFIGS
                          )}
                        />
                      </ChartWrapper>
                    </Col>
                  </Row>
                  <Container className='chart-bg pt-3 mt-5'>
                    <h5 className="mt-3 mb-2 text-secondary text-center">{"Demanda "}</h5>
                    <div className="mt-3 justify-content-center">
                      <DemandSwitch isDemandSwitchOn={isDemandSwitchOn} setIsDemandSwitchOn={setIsDemandSwitchOn}/>
                    </div>
                    {isDemandSwitchOn && (
                      <Row data-testid="demand-chart">
                        <Col className='mr-4'>
                          <ChartWrapper
                            loading={loadingTotalDemand}
                            graphDataLength={TotalDemandData?.days?.length}
                            testIdValue="gdmth-demanda-total"
                            className="mt-0"
                          >
                            <GdmthDemand
                              graphData={TotalDemandData}
                              minValue={TotalDemandMinValue}
                              maxValue={TotalDemandMaxValue}
                              hasTotalDevices={false}
                              gdmthDemandConfig={selectConfigs(
                                ['baseDemand', 'intDemand', 'highDemand','maxBaseDemand', 'maxIntDemand', 'maxHighDemand'],
                                TOTAL_DEMAND_CONFIGS
                              )}
                            />
                          </ChartWrapper>
                        </Col>
                      </Row>
                  )}

                  {!isDemandSwitchOn && (
                    <Row data-testid="demand-chart" >
                    <Col className='mr-4'>
                      <ChartWrapper
                        loading={loadingCfeDemand}
                        graphDataLength={CfeDemandData?.days?.length}
                        testIdValue="gdmth-demanda-cfe"
                      >
                        <GdmthDemand
                          graphData={CfeDemandData}
                          minValue={CfeDemandMinValue}
                          maxValue={CfeDemandMaxValue}
                          hasTotalDevices={false}
                          gdmthDemandConfig={selectConfigs(
                            ['baseDemand', 'intDemand', 'highDemand','maxBaseDemand', 'maxIntDemand', 'maxHighDemand'],
                            CFE_DEMAND_CONFIGS
                          )}
                        />
                      </ChartWrapper>
                    </Col>
                  </Row>
                  )}
                  </Container>
                </div>
              )}
              {hasTotalDevices && (
                <div className='mt-5'>
                <h5 className="mt-5 text-secondary text-center">{"Diaria"}</h5>
                <Row data-testid="total-day-chart"  >
                  <Col className='mr-4 my-4'>
                    <ChartWrapper
                      loading={loadingLive}
                      graphDataLength={liveData.length}
                      testIdValue="live-consumption"
                      title="Consumo"
                    >
                      <LiveConsumption
                        graphData={liveData}
                        minValue={liveMinValue}
                        maxValue={liveMaxValue}
                        hasTotalDevices={true}
                        liveConsumptionConfig={selectConfigs(
                          ['cfeConsumption', 'solarConsumption'],
                          ALL_LIVE_AND_MONTHLY_CHART_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                  <Col className='ml-4 my-4'>
                    <ChartWrapper
                      loading={loadingLive}
                      graphDataLength={liveData.length}
                      testIdValue="live-consumption"
                      title="Generación"
                    >
                      <LiveConsumption
                        graphData={liveData}
                        minValue={liveMinValue}
                        maxValue={liveMaxValue}
                        hasTotalDevices={true}
                        liveConsumptionConfig={selectConfigs(
                          ['consumedGeneration', 'injectedGeneration'],
                          ALL_LIVE_AND_MONTHLY_CHART_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                </Row>
                <h5 className="mt-5 text-secondary text-center">{"Mensual"}</h5>
                <Row data-testid="total-monthly-chart"  >
                <Col className='mr-4 my-4'>
                  <ChartWrapper
                    loading={loadingMonthly}
                    graphDataLength={monthlyData.length}
                    testIdValue="monthly-consumption"
                  >
                    <MonthlyConsumption
                      graphData={monthlyData}
                      minValue={monthlyMinValue}
                      maxValue={monthlyMaxValue}
                      hasTotalDevices={true}
                      monthlyConsumptionConfig={selectConfigs(
                        ['cfeConsumption', 'solarConsumption'],
                        ALL_LIVE_AND_MONTHLY_CHART_CONFIGS
                      )}
                    />
                  </ChartWrapper>
                </Col>
                <Col className='ml-4 my-4'>
                  <ChartWrapper
                    loading={loadingMonthly}
                    graphDataLength={monthlyData.length}
                    testIdValue="monthly-consumption"
                  >
                    <MonthlyConsumption
                      graphData={monthlyData}
                      minValue={monthlyMinValue}
                      maxValue={monthlyMaxValue}
                      hasTotalDevices={true}
                      monthlyConsumptionConfig={selectConfigs(
                        ['consumedGeneration', 'injectedGeneration'],
                        ALL_LIVE_AND_MONTHLY_CHART_CONFIGS
                      )}
                    />
                  </ChartWrapper>
                </Col>
              </Row>
              </div>
              )}
              {!hasTotalDevices && (
                <div>
                <h5 className="mt-5 text-secondary text-center">{"Diaria"}</h5>
                <Row data-testid="no-total-day-chart"  >
                  <Col className='mr-4 my-4'>
                    <ChartWrapper
                      loading={loadingLive}
                      graphDataLength={liveData.length}
                      testIdValue="live-consumption"
                      title="Consumo"
                    >
                      <LiveConsumption
                        graphData={liveData}
                        minValue={liveMinValue}
                        maxValue={liveMaxValue}
                        hasTotalDevices={false}
                        liveConsumptionConfig={selectConfigs(
                          ['cfeConsumption'],
                          ALL_LIVE_AND_MONTHLY_CHART_CONSUMPTION_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                  <Col className='ml-4 my-4'>
                    <ChartWrapper 
                      loading={loadingLive}
                      graphDataLength={liveData.length}
                      testIdValue="live-consumption"
                      title="Generación"
                    >
                      <LiveConsumption
                        graphData={liveData}
                        minValue={liveMinValue}
                        maxValue={liveMaxValue}
                        hasTotalDevices={false}
                        liveConsumptionConfig={selectConfigs(
                          ['consumedGeneration'],
                          ALL_LIVE_AND_MONTHLY_CHART_CONSUMPTION_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                </Row>
                <h5 className="mt-5 text-secondary text-center">{"Mensual"}</h5>
                <Row data-testid="no-total-monthly-chart"  >
                  <Col className='mr-4 my-4'>
                    <ChartWrapper
                      loading={loadingMonthly}
                      graphDataLength={monthlyData.length}
                      testIdValue="monthly-consumption"
                    >
                      <MonthlyConsumption
                        graphData={monthlyData}
                        minValue={monthlyMinValue}
                        maxValue={monthlyMaxValue}
                        hasTotalDevices={false}
                        monthlyConsumptionConfig={selectConfigs(
                          ['cfeConsumption'],
                          ALL_LIVE_AND_MONTHLY_CHART_CONSUMPTION_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                  <Col className='ml-4 my-4'>
                    <ChartWrapper
                      loading={loadingMonthly}
                      graphDataLength={monthlyData.length}
                      testIdValue="monthly-consumption"
                    >
                      <MonthlyConsumption
                        graphData={monthlyData}
                        minValue={monthlyMinValue}
                        maxValue={monthlyMaxValue}
                        hasTotalDevices={false}
                        monthlyConsumptionConfig={selectConfigs(
                          ['consumedGeneration'],
                          ALL_LIVE_AND_MONTHLY_CHART_CONSUMPTION_CONFIGS
                        )}
                      />
                    </ChartWrapper>
                  </Col>
                </Row>
                </div>
              )}
            </Tab.Pane>
            {/* Operativas */}
            <Tab.Pane eventKey="operativeData">
              <DailyReadingsChartForm
                title="Tus gráficas operativas"
                inputName="dateFilter"
                labelName="Selecciona el día"
                inputValues={operativeDate.dateFilter}
                minInputDate="01-01-2020"
                testIdValue="operative-readings-form"
                setFn={setOperativenDate}
                colResponsiveValue={4}
              />
                <Row className="mx-4 mt-5">
                  <Tabs id="OperativeDevices" data-testid="operative-charts-container">
                    {Object.keys(operativeData?.ampData?.meterDevices || {}).map((meterDevice, index) => (
                      <Tab
                        key={index}
                        eventKey={`meterDeviceCharts-${index}`}
                        title={<span className="text-dark-cyan">{operativeData?.ampData?.meterDevices[meterDevice]?.name}</span>}
                        className="px-4 pb-4 mb-4"
                        >
                          <Row data-testid="device-operative-charts" className='d-flex justify-content-between'>
                            {['amps', 'volts', 'watts', 'pf'].map((dataType) => (
                              <Col md={6} key={dataType} className="my-4 align-items-center">
                                <ChartWrapper
                                  loading={loadingOperative}
                                  graphDataLength={operativeData?.ampData?.meterDevices[meterDevice]?.lines?.length || 0}
                                  testIdValue={`${dataType}-readings-chart`}
                                  >
                                    <OperativeReadingsCharts
                                      title={dataTypeTitle(dataType)}
                                      message={I_ICON_MESSAGES.operativeChart[dataType]}
                                      graphData={operativeData}
                                      minValue={minValueOperative[dataType]}
                                      maxValue={maxValueOperative[dataType]}
                                      meterDeviceIndex={index}
                                      hasTotalDevices={true}
                                      dataType={dataType}
                                      configs={selectConfigs(
                                        ['l1', 'l2', 'l3'],
                                        OPERATIVE_LINES_CONFIGS
                                      )}
                                    />
                                </ChartWrapper>
                              </Col>
                            ))}
                          </Row>
                        </Tab>
                      ))}
                  </Tabs>
                </Row>
            </Tab.Pane >
            {/* EM Score */}
            <Tab.Pane eventKey="emScore">
              <h4 className='text-center text-secondary'>Coming soon</h4>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    </div>
  );
};

export default ChartsContainer;
