import { gql } from 'apollo-boost';

const ACCEPT_INVITATION = gql`
  mutation acceptInvitationMutation(
    $invitationToken: String!
    $password: String!
  ) {
    acceptInvitationMutation(
      input: { invitationToken: $invitationToken, password: $password }
    ) {
      user {
        name
        email
      }
    }
  }
`;

export default ACCEPT_INVITATION;
