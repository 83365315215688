import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks';

import { useDashboardFilters } from '../../../contexts/ProviderContext/DashboardFilterContext';
import SUMMARY from '../../../graphql/queries/providers/summary';

const useClientsSummary = () => {
  const { dashboardFilters, setDashboardFilters } = useDashboardFilters();
  
  useEffect(() => {}, [dashboardFilters])

  const { data, loading } = useQuery(SUMMARY, {
    variables: {
      q: {
        ...dashboardFilters
      }
    }
  });

  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    if (data) {
      setCurrentData(data);
    }
  }, [data]);

  return {
    currentData,
    loading,
    setDashboardFilters,
    dashboardFilters
  };
}

export default useClientsSummary;
