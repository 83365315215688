import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import LIVE_CONSUMPTION from '../../../../graphql/queries/live_consumption';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { chartMaxValue } from '../../../shared/sharedFunctions/sharedFunctions';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.locale('es');

const reducedDates = (data) => {
  return data.reduce((acc, item) => {
    const value = Number((item.y).toFixed(2));
    const newFormatDate = dayjs(item.x, "DD/MM/YYYY").format('MMMM YYYY');

    if (acc[newFormatDate] === undefined) {
       acc[newFormatDate] = 0
    }

    acc[newFormatDate] += value;

    return acc;
  }, {});
}

export const useMonthlyConsumption = (
  clientId,
  startDate = '',
  endDate = ''
) => {
  let minValue = Infinity;
  let maxValue = -Infinity;
  const { loading, error, data } = useQuery(LIVE_CONSUMPTION, {
    variables: { clientId, startDate, endDate },
  });

  const graphData = useMemo(() => {
    const cfeConsumption = data?.liveConsumption?.cfeConsumption || [];
    const solarConsumption = data?.liveConsumption?.solarConsumption || [];
    const injectedGeneration = data?.liveConsumption?.injectedGeneration || [];

    if ( cfeConsumption.length || solarConsumption.length ||
        injectedGeneration.length) {

      const reducedCfeConsumptionDates = reducedDates(cfeConsumption);
      const reducedSolarConsumptionDates = reducedDates(solarConsumption);
      const reducedInjectedGenerationDates = reducedDates(injectedGeneration);

      const dates = [
        ...Object.keys(reducedCfeConsumptionDates),
        ...Object.keys(reducedSolarConsumptionDates),
        ...Object.keys(reducedInjectedGenerationDates)
      ].sort((a, b) => {
        const dateA = dayjs(a, 'MMMM YYYY', 'es');
        const dateB = dayjs(b, 'MMMM YYYY', 'es');

        return dateA.isBefore(dateB) ? -1 : 1;
      });

      const uniqueDates = Array.from(new Set(dates));

      const months = uniqueDates.map(date => {
         return {
           date,
           cfeConsumption: reducedCfeConsumptionDates[date] ? Number(reducedCfeConsumptionDates[date].toFixed(2)) : 0,
           solarConsumption: reducedSolarConsumptionDates[date] ? Number(reducedSolarConsumptionDates[date].toFixed(2)) : 0,
           consumedGeneration: reducedSolarConsumptionDates[date] ? Number(reducedSolarConsumptionDates[date].toFixed(2)) : 0,
           injectedGeneration: reducedInjectedGenerationDates[date] ? Number(reducedInjectedGenerationDates[date].toFixed(2)) : 0
         }
      });

      return months;
    }

    return [];
  }, [data]);

  if (graphData.length) {
    graphData.forEach(data => {
       const consumption = data.solarConsumption + data.cfeConsumption;
       const generation = data.consumedGeneration + data.injectedGeneration;

       minValue = Math.min(minValue, consumption, generation);
       maxValue = chartMaxValue(Math.max(maxValue, consumption, generation));
    })
  }
  
  return {
    loading,
    error,
    graphData,
    minValue: Math.floor(minValue),
    maxValue: Math.ceil(maxValue),
  };
};
