import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';

import ConsumptionSourceGraph from './ConsumptionSourceGraph/ConsumptionSourceGraph';

import CURRENT_PERIOD_COSTS_PROJECTIONS from '../../../graphql/queries/current_period_costs_projections';
import LIVE_CONSUMPTION from '../../../graphql/queries/live_consumption';
import SAVINGS_WITH_PANELS from '../../../graphql/queries/savings_with_panels'
import SavingsWithPanels from '../SavingsContainer/SavingsWithPanels/SavingsWithPanels';
import USERNAME_GATEWAY from '../../../graphql/queries/username_gateway';

const ConsumptionSourceGraphContainer = ({ clientId }) => {
  const { data: dataCostsProjections, loading: loadingCostsProjections } = useQuery(CURRENT_PERIOD_COSTS_PROJECTIONS, {
    variables: { clientId }
  });
  let currentDate = dayjs(new Date()).format('YYYY-MM-DD');
  const { data: dataConsumptionGraph, loading: loadingConsumptionGraph } = useQuery(LIVE_CONSUMPTION, {
    variables: {clientId, currentDate}
  });
  const { data: dataSavingsWithPanels, loading: loadingSavingWithPanels } = useQuery(SAVINGS_WITH_PANELS, {
    variables: {clientId, currentDate}
  });
  const { data: usernameGatewayData, loading: usernameGatewayLoading } = useQuery(USERNAME_GATEWAY,  {
    variables: { clientId: clientId }
  });
  const feeType = usernameGatewayData?.usernameGateway?.client?.cfeHome?.cfeFeeType || '';

  return (
    <>
      <Row className="d-flex mt-2 mb-4" data-testid="consumptions-container">
        <Col className='my-4'>
          <ConsumptionSourceGraph data={dataConsumptionGraph} loading={loadingConsumptionGraph} feeType={feeType}/>
        </Col>
        <Col className="my-4 ml-4" >
          <SavingsWithPanels costProjections={dataCostsProjections} savings={dataSavingsWithPanels} loading={loadingCostsProjections} />
        </Col>
      </Row>
    </>
  );
};

export default ConsumptionSourceGraphContainer;
