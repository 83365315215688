import React, { useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import Loader from './../../../shared/Loader/Loader';
import { toCurrencyLabel } from '../../../shared/sharedFunctions/sharedFunctions';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Surface,
  Symbols,
  Rectangle,
  Line,
  ComposedChart,
} from 'recharts';
import { CHARTS_COLORS } from '../../../shared/Charts/ChartsColors';
import InfoMessage from '../../../shared/InfoMessage/InfoMessage';
import { I_ICON_MESSAGES } from '../../../shared/InfoMessage/messages';

const CurrentPeriodCostsProjections = ({ data, loading, setPeriod }) => {
  const graphData = data?.currentPeriodCostsProjections?.costsProjectionsByDay || [];

  const [disabledKeys, setDisabledKeys] = useState([]);
  
  const areasConfig = {
    costsWithPanels: {
      color: CHARTS_COLORS.blue,
      legendName: 'Costo actual con paneles',
      opacity: 1,
    },
    projectedCostsWithPanels: {
      color: CHARTS_COLORS.blue,
      legendName: 'Costo estimado con paneles',
      opacity: 1,
      strokeDash: '3 3',
    },
  };

  const linesConfig = {
    costsWithoutPanels: {
      color: CHARTS_COLORS.yellow,
      legendName: 'Costo actual sin paneles',
      opacity: 1,
      strokeDash: undefined,
    },
    projectedCostsWithoutPanels: {
      color: CHARTS_COLORS.yellow,
      legendName: 'Costo estimado sin paneles',
      opacity: 1,
      strokeDash: '3 3',
    },
  };

  const lineConfigPairs = Object.keys(linesConfig).map((key) => [
    key,
    linesConfig[key],
  ]);

  const areaConfigPairs = Object.keys(areasConfig).map((key) => [
    key,
    areasConfig[key],
  ]);

  const lineAndAreasConfig = lineConfigPairs.concat(areaConfigPairs);
  const legendPayload = lineAndAreasConfig.map((config) => ({
    dataKey: config[0],
    color: config[1].color,
    name: config[1].legendName,
  }));

  const handleDisplay = (dataKey) => {
    if (disabledKeys.includes(dataKey)) {
      setDisabledKeys(disabledKeys.filter((obj) => obj !== dataKey));
    } else {
      setDisabledKeys(disabledKeys.concat([dataKey]));
    }
  };

  const renderCustomizedLegend = ({ payload }) => {
    const [
      withoutPanels,
      projectedWithoutPanels,
      withPanels,
      projectedWithPanels,
    ] = payload;

    const noProjectedCosts = [withPanels, withoutPanels];
    const projectedCosts = [projectedWithPanels, projectedWithoutPanels];

    return (
      <Row className="mb-5 ml-1">
        <LegendContent payload={noProjectedCosts}/>
        <LegendContent payload={projectedCosts}/>
      </Row>
    );
  };

  const LegendContent = ({ payload }) => {
    return (
      <Col sm={6}>
        {payload.map((entry) => {
          const { dataKey, color, name } = entry;
          const active = !disabledKeys.includes(dataKey);

          return (
            <div key={dataKey + color}>
              <span
                key={dataKey}
                className={`${active ? 'text-secondary' : 'text-muted'} legend-item`}
                onClick={() => handleDisplay(dataKey)}
              >
                <Surface
                  width={20}
                  height={10}
                  viewBox={{ x: 0, y: 0, width: 20, height: 10 }}
                >
                  {dataKey === 'costsWithPanels' || dataKey === 'costsWithoutPanels'
                    ? <Rectangle y={2} width={12} height={3} fill={color} />
                    : <>
                        <Symbols cx={2} cy={5} type="circle" size={10} fill={color} />
                        <Symbols cx={7} cy={5} type="circle" size={10} fill={color} />
                        <Symbols cx={12} cy={5} type="circle" size={10} fill={color} />
                      </>
                  }
                </Surface>
                <span>{name}</span>
              </span>
            </div>
          );
        })}
      </Col>
    );
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    const day = Number(dayOfMonth(payload.value)); 
  
    if (day % 2 === 0) { 
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fill={CHARTS_COLORS.gray40}
            fontSize={12}
          >
            {day}
          </text>
        </g>
      );
    } else {
      return null; 
    }
  };

  const handleSelect = (e)=>{
    const val = parseInt(e.target.value)
    setPeriod(val)
  }

  const getMonthAndYear = (graphData) => {
    if (graphData){
      let date = graphData[3] ? graphData[3].date : graphData[0].date;
      const [day, month, year] = date.split('/');
      let monthName;
      switch (month) {
        case '01': monthName = "Enero" + year; break;
        case '02': monthName = "Febrero" + year; break;
        case '03': monthName = "Marzo" + year; break;
        case '04': monthName = "Abril" + year; break;
        case '05': monthName = "Mayo" + year; break;
        case '06': monthName = "Junio" + year; break;
        case '07': monthName = "Julio" + year; break;
        case '08': monthName = "Agosto" + year; break;
        case '09': monthName = "Septiembre" + year; break;
        case '10': monthName = "Octubre" + year; break;
        case '11': monthName = "Noviembre" + year; break;
        case '12': monthName = "Diciembre" + year; break;
        default: monthName = date; break;
      }
        return monthName;
      
    }
  }

  const dayOfMonth = (date) => {
    const [day, month, year] = date.split('/');
    return day;
  };
  
  return (
    <Card
      className="accumulated-costs h-100 chart-bg mt-2 mb-4"
      data-testid="current-period-costs-projections"
    >
      <Card.Body>
        <Card.Title className="text-secondary">
          {I_ICON_MESSAGES.costs.header} <InfoMessage placement="right" message={I_ICON_MESSAGES.costs.message} />
        </Card.Title>
        <Row className="mb-3 ml-1">
          <Form.Group>
            <Form.Control onChange={handleSelect} as="select" name="cfe_payment_period" className="select cyan-form">
              <option value='0' style={{ color:  'black' }}>Periodo Actual</option>
              <option value='1' style={{ color:  'black' }}>Periodo pasado</option>
              <option value='2' style={{ color:  'black' }}>Dos periodos antes</option>
            </Form.Control>
            </Form.Group>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Loader />
            ) : graphData.length ? (
              <>
                <Row>
                  <Col>
                    <ResponsiveContainer height={465} debounce={5}>
                      <ComposedChart
                        data={graphData}
                        margin={{ top: 0, right: 0, left: 0, bottom: 40 }}
                      >
                        <defs>
                          <linearGradient
                            id="colorCostsWithPanels"
                            x1="0"
                            y1="0"
                            y2="0"
                          >
                            <stop
                              offset="0%"
                              stopColor={CHARTS_COLORS.blue}
                              stopOpacity={1}
                            />
                            <stop
                              offset="100%"
                              stopColor={CHARTS_COLORS.blue}
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis
                          dataKey="date"
                          tickFormatter={(value) => dayOfMonth(value)}
                          tick={<CustomizedAxisTick />}
                          label={{ value: `${getMonthAndYear(graphData)}`, position: 'bottom', style: { fill: '#EDEDED' } }}
                        />
                        <YAxis
                          tickFormatter={(value) => toCurrencyLabel(value)}
                          tick={{ fill: '#EDEDED', fontSize: 12}}
                        />
                        <CartesianGrid  stroke="rgba(245,245,245, 0.3)" horizontal={true} vertical={false}/>
                        <Tooltip
                          formatter={(value) => toCurrencyLabel(value)}
                          contentStyle={{
                            backgroundColor: '#0d122e',
                            borderColor: '#454a61',
                            borderWidth: "1px",
                            borderRadius: '10px',
                            boxShadow: '0 0 50px rgba(0,0,0,.25)',
                            color: '#EDEDED'
                          }}
                        />
                        <Legend
                          verticalAlign="top"
                          content={renderCustomizedLegend}
                          payload={legendPayload}
                          align="center"
                        />
                        {lineConfigPairs
                          .filter((pair) => !disabledKeys.includes(pair[0]))
                          .map((config) => {
                            return (
                              <Line
                                key={config[0]}
                                type="monotone"
                                dataKey={config[0]}
                                name={config[1].legendName}
                                strokeWidth={3}
                                stroke={config[1].color}
                                dot={false}
                                strokeDasharray={config[1].strokeDash}
                              />
                            );
                          })}
                        {areaConfigPairs
                          .filter((pair) => !disabledKeys.includes(pair[0]))
                          .map((config) => {
                            return (
                              <Line
                                key={config[0]}
                                dataKey={config[0]}
                                name={config[1].legendName}
                                strokeWidth={3}
                                stroke={config[1].color}
                                dot={false}
                                connectNulls={true}
                                fill={config[1].color}
                                type="monotone"
                                strokeDasharray={config[1].strokeDash}
                              />
                            );
                          })}
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <p
                  className="px-5 py-5 text-muted font-weight-normal"
                  data-testid="messageInCurrentPeriodCosts"
                >
                  No tenemos datos de tu recibo actual.
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CurrentPeriodCostsProjections;
