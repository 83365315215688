import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Badge from 'react-bootstrap/Badge';

import INVITE_CLIENT from '../../../graphql/mutations/inviteClient';
import USERNAME_GATEWAY from '../../../graphql/queries/username_gateway';
import AcceptInvitationModal from './AcceptInvitationModal/AcceptInvitationModal';
import Loader from '../../shared/Loader/Loader';
import InfoMessage from '../../shared/InfoMessage/InfoMessage';

function InvitationStatus({ clientId, clientEmail, hasBeenInvited, hasAcceptedInvitation, acceptInvitationUrl }) {
  const MUTATION_OPTIONS = {
    errorPolicy: 'all',
    refetchQueries: [
      { query: USERNAME_GATEWAY, variables: { clientId: clientId } }
    ]
  }
  const [inviteClient, { loading }] = useMutation(INVITE_CLIENT, MUTATION_OPTIONS);
  const [show, setShow] = useState(false);
  const alreadyInvitedMsg = `La invitación fue enviada a ${clientEmail}. Haz click en la "i" para más opciones de envío.`;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const triggerInvitation = (_e) => {
    inviteClient({ variables: { clientId: clientId, skipInvitationDelivery: false } })
      .catch((_err) => {
        alert('Ocurrió un error, intenta de nuevo más tarde.');
      })
  }

  if(loading) {
    return <div className="ml-1"><Loader message={"Enviando invitación..."} /></div>
  }

  if(hasAcceptedInvitation) {
    return (
      <Badge pill variant="primary" className="outlined-badge align-middle">
        <p className="m-0">Invitación Aceptada</p>
      </Badge>
    )
  }

  if(!hasBeenInvited) {
    return (
      <Badge pill variant="primary" className="outlined-badge align-middle" as="a" href="#" onClick={triggerInvitation}>
        <p className="m-0">Invitar Cliente</p>
      </Badge>
    )
  }

  if(hasBeenInvited && !hasAcceptedInvitation) {
    return (
      <>
        <Badge className="outlined-badge align-middle d-flex justify-content-center align-items-center" as="a" href="#" onClick={handleShow}>
          <p className="m-0">Invitación Enviada</p>
          <div className='ml-2'>
            <InfoMessage message={alreadyInvitedMsg} placement="bottom" />
          </div>
        </Badge>
        <AcceptInvitationModal
          show={show}
          clientId={clientId}
          handleClose={handleClose}
          invitationUrl={acceptInvitationUrl}
          resetInvitation={triggerInvitation} 
        />
      </>
    )
  }
}

export default InvitationStatus;
