import faker from 'faker';
import ROLES from '../../routing/roles';

// These ones are just for login
export const users = [
  {
    id: 1,
    name: 'Provider 1',
    email: 'user@provider.com',
    password: 'password',
    authenticationToken: 'dev_token_1',
    authorityLevel: ROLES['provider']
  },
  {
    id: 2,
    name: 'User 2',
    email: 'user@user.com',
    password: 'password',
    authenticationToken: 'dev_token_2',
    authorityLevel: ROLES['user'],
    home: {
      address: faker.address.streetAddress(),
      city: faker.address.city(),
      zipcode: faker.address.zipCode(),
      state: {
        cfeShortName: faker.address.state()
      }
    }
  },
  {
    id: 3,
    name: 'Galt 3',
    email: 'admin@galt.mx',
    password: 'password',
    authenticationToken: 'dev_token_3',
    authorityLevel: ROLES['galt']
  }
]

let fakeClients = [];
for (let i = 1; i <= 100; i++) {
  let state = faker.address.state();
  fakeClients.push(
    {
      id: users.length + i,
      name: faker.name.firstName(),
      email: faker.internet.email(),
      setupToken: i%2 === 0? faker.random.uuid() : null,
      password: 'password',
      authorityLevel: ROLES['user'],
      authenticationToken: `dev_token_${users.length + i}`,
      invitationAccepted: faker.random.boolean(),
      invitedToSignUp: faker.random.boolean(),
      channel: faker.name.jobTitle(),
      customId: `Custom ID ${users.length + i}`,
      meterGateway: {
        id: faker.random.number(),
        name: faker.company.companyName(),
        active: faker.random.boolean(),
        currentPeriodAvgKwhConsumption: Number(faker.finance.amount(0, 50, 2)),
        yieldGen: Number(faker.finance.amount(0, 50, 2)),
        kwhGenYesterday: Number(faker.finance.amount(0, 50, 2)),
        invalidGatewayAlert: faker.random.boolean(),
        lowYieldAlert: faker.random.boolean(),
        cfeInterconnectionAt: faker.date.past().toISOString(),
      },
      cfeHome: {
        installedKw: Number(faker.finance.amount(0, 50, 2)),
        currentPeriodCfeKwhPrice: Number(faker.finance.amount(0, 50, 4))
      },
      home: {
        address: faker.address.streetAddress(),
        city: faker.address.city(),
        zipcode: faker.address.zipCode(),
        state: {
          id: i,
          name : state,
          cfeShortName: state
        }
      }
    }
  )
}

export const clients = fakeClients;
export const findUserByEmail = (userEmail) => {
  return users.filter(u => u.email === userEmail)[0];
}

export const findUserByToken = (token) => {
  return users.filter(u => u.authenticationToken === token)[0];
}

export const findClientById = (id) => {
  return clients.filter(u => u.id === id)[0];
}

export const states = fakeClients.map((client) => (client.home.state))

export const cities = fakeClients.map((client) => ({ state: client.home.state, cities: [client.home.city]}))

export const providerChannels = fakeClients.map((client) => (client.channel))

export const cfeFees = [
  {
    feeType: 'C1',
    id: 0,
  },
  {
    feeType: 'C2',
    id: 1,
  },
  {
    feeType: 'C3',
    id: 2,
  }
]

export const createHome = () => {
  return {
    id: faker.random.number(),
    street: faker.address.streetName(),
    externalNumber: faker.random.number(),
    neighborhood: faker.address.county(),
    zipcode: '12345',
    city: faker.address.city(),
    stateId: states[Math.floor(Math.random() * states.length)].id,
    latitude: faker.address.latitude(),
    longitude: faker.address.longitude()
  }
}

export const createCfeHome = () => {
  return {
    id: faker.random.number(),
    cfeRpu: '123456789098',
    cfeName: faker.name.firstName(),
    cfeFeeId: cfeFees[Math.floor(Math.random() * cfeFees.length)].id,
    installedKw: faker.random.number({ min: 1.0, max: 5000.0, precision: 0.2 }),
    currentPeriodCfeKwhPrice: faker.random.number({ min: 1.0, max: 5000.0, precision: 0.2 })
  }
}

export const createMeterGateway = () => {
  return {
    id: faker.random.number(),
    key: faker.random.alphaNumeric(),
    name: faker.company.companyName(),
    active: faker.random.boolean(),
    status: Math.floor(Math.random() * (2 - 0) + 0) === 0 ? 'invalid' : 'validated',
    activationCode: faker.random.number()
  }
}

export const createCfeInvoice = () => {
  return {
    id: faker.random.number(),
    periodStartDate: faker.date.past().toISOString(),
    periodEndDate: faker.date.future().toISOString(),
    consumption: faker.random.number(),
    generation: faker.random.number()
  }
}

export const createMeterDevice = () => {
  return {
    id: faker.random.number(),
    name: faker.commerce.productName(),
    measureType: faker.random.number({ min: 0, max: 2 }),
    positive: faker.random.boolean()
  }
}

export const createMeterReading = () => {
  return {
    id: faker.random.number(),
    kwhTot: faker.random.number({ min: 1.0, max: 5000.0, precision: 0.2 }),
    wattsTot: faker.random.number({ min: 1.0, max: 5000.0, precision: 0.2 }),
    ampTot: faker.random.number({ min: 1.0, max: 5000.0, precision: 0.2 }),
    voltTot: faker.random.number({ min: 1.0, max: 5000.0, precision: 0.2 })
  }
}
