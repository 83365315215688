import { gql } from "apollo-boost";

const USER_FROM_INVITATION_TOKEN = gql`
  query userFromInvitationToken($invitationToken: String!) {
    userFromInvitationToken(invitationToken: $invitationToken) {
        name
        email
    }
  }
`

export default USER_FROM_INVITATION_TOKEN;
