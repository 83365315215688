import React, { useRef, useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Map } from '../../shared/Map/Map';
import { handleGeneralInputChange } from '../../../services/inputs_service/inputs_service';
import { GatewayDevices } from '../gatewayDevices/gatewayDevices';
import { useLazyQuery } from "@apollo/react-hooks";
import GATEWAY_DEVICES from "../../../graphql/queries/gateway_devices";
import { stripTypenames } from "../../../graphql/client";

export const PersonalInfoSection = ({ client, setAttributes }) => {
  return(
    <Form.Row >
      <Form.Group as={Col}  controlId="formGridFullName" className="mr-4 mt-2">
        <Form.Label className="mb-1">Nombre del proyecto*</Form.Label>
        <Form.Control
          className="mute-form p-3"
          required
          name="name"
          value={client.name || ''}
          onChange={(e) =>
            handleGeneralInputChange(e, setAttributes, 'string')
          }
        />
        <Form.Control.Feedback type="invalid">
          Por favor ingrese nombre del proyecto
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} controlId="formGridEmail" className="mr-4 mt-2">
       <Form.Label className="mb-1">Email*</Form.Label>
        <Form.Control
          className="mute-form p-3"
          required
          pattern="^[^@\s]+@[^@\s]+$"
          name="email"
          value={client.email || ''}
          onChange={(e) =>
            handleGeneralInputChange(e, setAttributes, 'string')
          }
        />
        <Form.Control.Feedback type="invalid">
          Por favor ingrese su email
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col}  controlId="formGridChannel" className="mr-4 mt-2">
       <Form.Label className="mb-1">Channel</Form.Label>
        <Form.Control
          name="channel"
          className="mute-form p-3"
          value={client.channel || '' }
          onChange={ (e) =>
            handleGeneralInputChange(e, setAttributes, 'string')
          }
        />
        <Form.Control.Feedback type="invalid">
          Por favor ingrese su channel
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} controlId="formGridCustomId" className="mr-4 mt-2">
       <Form.Label className="mb-1">Custom ID</Form.Label>
        <Form.Control
          name="customId"
          className="mute-form p-3"
          value={client.customId || ''}
          onChange={ (e) =>
            handleGeneralInputChange(e, setAttributes, 'string')
          }
        />
        <Form.Control.Feedback type="invalid">
          Por favor ingrese un custom ID
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
}

export const AddressSection = ({
  street,
  externalNumber,
  neighborhood,
  zipcode,
  city,
  stateId,
  latitude,
  longitude,
  setAttributes,
  states
}) => {
  const textState = useRef('');

  const handleOnBlurEvent = (e) => {
    if (stateId !== undefined && e.currentTarget.name === 'stateId') {
      let index = e.currentTarget.options.selectedIndex;
      textState.current = e.currentTarget.options[index].text;
    }

    if (
      street &&
      externalNumber &&
      city &&
      neighborhood &&
      stateId &&
      textState.current &&
      zipcode
    ) {
      const cleanStreet = street.split(' ');
      const cleanExternalNumber = externalNumber.split(' ');
      const cleanNeighborhood = neighborhood.split(' ');
      const cleanZipcode = zipcode;
      const cleanCity = city.split(' ');
      const cleanState = textState.current.split(' ');

      const finalResult = cleanStreet
        .concat(cleanExternalNumber, cleanNeighborhood, cleanZipcode, cleanCity, cleanState)
        .join('+');

      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${finalResult}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          const result = data.results[0]?.geometry.location;
          let latitude = 0;
          let longitude = 0;
          if (result) {
            latitude = parseFloat(result.lat.toFixed(6));
            longitude = parseFloat(result.lng.toFixed(6));
          }
          setAttributes((prevState) => {
            return {
              ...prevState,
              latitude: latitude,
              longitude: longitude,
            };
          });
        });
    }
  };

  return (
    <Form.Row>
      <Col>
        <Form.Row>
          <Form.Group as={Col} md={5} controlId="formGridAddress" className="mr-4 mt-2">
           <Form.Label className="mb-1">Calle*</Form.Label>
            <Form.Control
              className="mute-form p-3"
              required
              name="street"
              value={street }
              onChange={(e) =>
                handleGeneralInputChange(e, setAttributes, 'string')
              }
              onBlur={handleOnBlurEvent}
              data-testid="street"
            />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese la calle
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={5} controlId="formGridExternalNumber" className="mr-4 mt-2">
           <Form.Label className="mb-1">Número Exterior*</Form.Label>
            <Form.Control
              required
              className="mute-form p-3"
              name="externalNumber"
              value={externalNumber }
              onChange={(e) =>
                handleGeneralInputChange(e, setAttributes, 'string')
              }
              onBlur={handleOnBlurEvent}
            />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese el número exterior
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={5} controlId="formGriSector" className="mr-4 mt-2">
           <Form.Label className="mb-1">Colonia*</Form.Label>
            <Form.Control
              required
              className="mute-form p-3"
              name="neighborhood"
              value={neighborhood }
              onChange={(e) =>
                handleGeneralInputChange(e, setAttributes, 'string')
              }
              onBlur={handleOnBlurEvent}
            />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese la colonia
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={5} controlId="formGridZipCode" className="mr-4 mt-2">
           <Form.Label className="mb-1">Código Postal*</Form.Label>
            <Form.Control
              required
              className="mute-form p-3"
              maxLength={5}
              minLength={5}
              pattern="[0-9]*"
              name="zipcode"
              placeholder="5 dígitos"
              value={zipcode || ''}
              onChange={(e) =>
                handleGeneralInputChange(e, setAttributes, 'string')
              }
              onBlur={handleOnBlurEvent}
            />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese el código postal (5 dígitos)
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={5} controlId="formGridCity" className="mr-4 mt-2">
           <Form.Label className="mb-1">Ciudad*</Form.Label>
            <Form.Control
              required
              className="mute-form p-3"
              name="city"
              value={city || ''}
              onChange={(e) =>
                handleGeneralInputChange(e, setAttributes, 'string')
              }
              onBlur={handleOnBlurEvent}
            />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese la ciudad
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md={5} controlId="formGridState" className="mr-4 mt-2">
           <Form.Label className="mb-1">Estado*</Form.Label>
            <Form.Control
              required
              className="mute-form select"
              as="select"
              name="stateId"
              value={stateId || ''}
              onChange={(e) =>
                handleGeneralInputChange(e, setAttributes, 'ID')
              }
              onBlur={handleOnBlurEvent}
            >
              <option value="">Seleccionar Estado</option>
              {states.map((state) => {
                return (
                  <option 
                    key={state.id} 
                    value={state.id}
                    style={{ color: stateId === state.selected ? 'white' : 'black' }}
                  >
                      {state.name}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Por favor ingrese el estado
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      </Col>
      <Col className="col-container">
        <div className="create-map-container">
          <Map
            lat={latitude}
            lng={longitude}
          ></Map>
        </div>
      </Col>
    </Form.Row>
  );
}

const CfeHomeInfo = ({ 
  cfeName, 
  cfeRpu, 
  consumption, 
  generation, 
  setCfeHomeAttr, 
  setCfeInvoiceAttr 
}) => {
  return (
    <>
      <Form.Row>
        <Form.Group as={Col}  controlId="formGridCfeName" className="mr-4 mt-2">
         <Form.Label className="mb-1">CFE-nombre</Form.Label>
          <Form.Control
            name="cfeName"
            className="mute-form p-3"
            value={cfeName || ''}
            onChange={(e) =>
              handleGeneralInputChange(e, setCfeHomeAttr, 'string')
            }
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingrese el CFE-nombre
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col}  controlId="formGridCfeRpu" className="mr-4 mt-2">
         <Form.Label className="mb-1">CFE-rpu</Form.Label>
          <Form.Control
            maxLength={12}
            minLength={12}
            className="mute-form p-3"
            pattern="[0-9]*"
            type="text"
            name="cfeRpu"
            placeholder="12 dígitos"
            value={cfeRpu || ''}
            onChange={(e) =>
              handleGeneralInputChange(e, setCfeHomeAttr, 'string')
            }
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingrese el CFE-rpu (12 dígitos)
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col}  controlId="formGridLastCfeConsumption" className="mr-4 mt-2">
         <Form.Label className="mb-1">Última lectura de consumo</Form.Label>
          <Form.Control
            maxLength={7}
            minLength={7}
            className="mute-form p-3"
            pattern="[0-9]*"
            type="text"
            name="consumption"
            placeholder="7 dígitos"
            value={consumption || ''}
            onChange={(e) =>
              handleGeneralInputChange(e, setCfeInvoiceAttr, 'number')
            }
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingrese la última medida del medidor de consumo (7 dígitos).
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col}  controlId="formGridLastCfeGeneration" className="mr-4 mt-2">
         <Form.Label className="mb-1">Última lectura de generación</Form.Label>
          <Form.Control
            maxLength={7}
            minLength={7}
            className="mute-form p-3"
            pattern="[0-9]*"
            type="text"
            name="generation"
            placeholder="7 dígitos"
            value={generation || ''}
            onChange={(e) =>
              handleGeneralInputChange(e, setCfeInvoiceAttr, 'number')
            }
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingrese la última medida del medidor de generación (7 dígitos).
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </>
  );
}

const CfeFeeInfo = ({
  cfeFeeId,
  cfeFees,
  periodStartDate,
  periodEndDate,
  setCfeInvoiceAttr,
  setCfeHomeAttr
}) => {
  return (
    <Form.Row>
      <Form.Group as={Col}  controlId="formGridCfeRate" className="mr-4 mt-2">
       <Form.Label className="mb-1">CFE-tarifa*</Form.Label>
        <Form.Control
          required
          className="mute-form select"
          as="select"
          name="cfeFeeId"
          value={cfeFeeId || ''}
          onChange={(e) =>
            handleGeneralInputChange(e, setCfeHomeAttr, 'ID')
          }
        >
          <option value="">Seleccionar tarifa</option>
          {cfeFees.map((fee) => {
            return (
              <option key={fee.id} value={fee.id} style={{ color: cfeFeeId === fee.id ? 'white' : 'black' }}>
                {fee.feeType}
              </option>
            );
          })}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          Por favor ingrese la tarifa
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} controlId="formGridStartDateCFE" className="mr-4 mt-2">
       <Form.Label className="mb-1">Fecha de inicio de último recibo*</Form.Label>
        <Form.Control
          required
          className="mute-form p-3"
          type="date"
          name="periodStartDate"
          value={periodStartDate || ''}
          onChange={(e) =>
            handleGeneralInputChange(e, setCfeInvoiceAttr, 'string')
          }
        />
        <Form.Control.Feedback type="invalid">
          Por favor ingrese la fecha de inicio de último recibo
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col}  controlId="formGridEndCfeDate" className="mr-4 mt-2">
       <Form.Label className="mb-1">Fecha de fin de último recibo*</Form.Label>
        <Form.Control
          required
          className="mute-form p-3"
          type="date"
          name="periodEndDate"
          value={periodEndDate || ''}
          onChange={(e) =>
            handleGeneralInputChange(e, setCfeInvoiceAttr, 'string')
          }
        />
        <Form.Control.Feedback type="invalid">
          Por favor ingrese la fecha de fin de último recibo
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
}

export const CfeInfoSection = ({
  cfeName,
  cfeRpu,
  consumption,
  generation,
  cfeFeeId,
  cfeFees,
  periodStartDate,
  periodEndDate,
  setCfeHomeAttr,
  setCfeInvoiceAttr
}) => {
  return (
    <React.Fragment>
      <CfeHomeInfo
        cfeName={cfeName}
        cfeRpu={cfeRpu}
        consumption={consumption}
        generation={generation}
        setCfeHomeAttr={setCfeHomeAttr}
        setCfeInvoiceAttr={setCfeInvoiceAttr} />
      <CfeFeeInfo
        cfeFeeId={cfeFeeId}
        cfeFees={cfeFees}
        periodStartDate={periodStartDate}
        periodEndDate={periodEndDate}
        setCfeHomeAttr={setCfeHomeAttr}
        setCfeInvoiceAttr={setCfeInvoiceAttr} />
    </React.Fragment>
  );
}

export const PanelsInfoSection = ({
  isEdit,
  cfeHomeAttributes,
  setCfeHomeAttr,
  requiredField=false,
  hideGatewayFields={},
}) => {

  return (
    <Form.Row>
      <Form.Group as={Col} controlId="formGridKwInstalledPanels" className="mr-4 mt-2">
       <Form.Label className="mb-1">kWp SFV instalados {!requiredField && '(si aplica)'}</Form.Label>
        <Form.Control
          required={requiredField}
          className="mute-form p-3"
          name="installedKw"
          placeholder="Usar decimales"
          type="number"
          maxLength={7}
          value={cfeHomeAttributes.installedKw || ''}
          onChange={(e) =>
            handleGeneralInputChange(e, setCfeHomeAttr, 'number')
          }
        />
        <Form.Control.Feedback type="invalid">
          Por favor ingrese los kilowatts de los paneles instalados
        </Form.Control.Feedback>
      </Form.Group>

      {!hideGatewayFields['cfeInterconnectionAt'] && isEdit &&
          <Form.Group as ={Col} controlId="formGridCfeHomeInterconnectionAt" className="mr-4 mt-2">
           <Form.Label className="mb-1">Fecha Interconexión CFE (menor o igual a fecha actual)</Form.Label>
            <Form.Control
              className="mute-form p-3"
              type="date"
              name="cfeInterconnectionAt"
              value={cfeHomeAttributes.cfeInterconnectionAt || ''}
              onChange={(e) =>
                handleGeneralInputChange(e, setCfeHomeAttr, 'date')
              }
            />
            <Form.Control.Feedback type="invalid">
              Ingrese una fecha menor o igual a la actual
            </Form.Control.Feedback>
          </Form.Group>
      }

    </Form.Row>
  );
}

export const GatewaySection = ({
  meterGateway,
  setMeterGateway,
  hideFields={},
  requiredFields={},
  setDeviceAttributes,
  setGatewayError,
  index,
}) => {
  const [errorInGateway, setErrorInGateway] = useState("");

  const [
    loadGatewayDevices,
    { error: gatewayError, loading: gatewayLoading, data: devicesData },
  ] = useLazyQuery(GATEWAY_DEVICES);

  useEffect(() => {
    if (gatewayError !== undefined) {
      const updatedGatewayError = gatewayError.message.replace(
        "GraphQL error:",
        ""
      );
      setGatewayError(true);
      setErrorInGateway(updatedGatewayError);
    } else {
      setGatewayError(false);
      setErrorInGateway('')
    }
  }, [gatewayError]);

  useEffect(() => {
    if (devicesData) {
      const removeTypename = devicesData.gatewayDevices.map(stripTypenames);
      setDeviceAttributes(removeTypename);
      meterGateway.meterDevices = removeTypename;
    }
  }, [devicesData]);

  useEffect(() => {
    if (meterGateway) {
      loadGatewayDevices({
        variables: {
          key: meterGateway.key,
          name: meterGateway.name,
        },
      });
    }
  }, [meterGateway, loadGatewayDevices]);

  const updateDevicesData = (identifier, event) => {
    const target = event.target;
    const newValue = target.type === "checkbox" ? target.checked : parseInt(target.value);
    const attributeName = target.getAttribute("data-attribute-name");
    let copy = [...meterGateway.meterDevices];
    let deviceToUpdate = copy.find(
      (device) => device.id === identifier || device.name === identifier
    );
    deviceToUpdate[attributeName] = newValue;
    setDeviceAttributes(copy);
  };
  
  const updateGatewayData = (event) => {
    const target = event.target;
    const attributeName = target.getAttribute("name");
    meterGateway[attributeName] = target.value
    setMeterGateway(index, meterGateway);
  };


  const removeDevices = () => {
    meterGateway.meterDevices = [];
    setDeviceAttributes([]);
  }

  const handleBlurEventInGateway = (event) => {
    if (meterGateway.key.length !== 0 && meterGateway.name.length !== 0) {
      loadGatewayDevices({
        variables: {
          key: meterGateway.key,
          name: meterGateway.name,
        },
      });
    } else {
      if (meterGateway.key.length === 0 && meterGateway.name.length === 0) {
        setGatewayError(false);
        setErrorInGateway('');
        removeDevices();
      }
    }
  };

  return (
    <React.Fragment>
      <Form.Row >
        <Form.Group as={Col} controlId={`formGridGatewayName-${index}`} className="mr-4 mt-2">
         <Form.Label className="mb-1">Núm. de serie de Gateway</Form.Label>
         <Form.Control
            required={requiredFields?.gatewayName}
            identifier={meterGateway.id}
            className="mute-form p-3"
            name="name"
            value={meterGateway.name || ''}
            onChange={updateGatewayData}
            onBlur={handleBlurEventInGateway}
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingrese el id del gateway
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId={`formGridGatewayToken-${index}`} className="mr-4 mt-2">
         <Form.Label className="mb-1">Token de Gateway</Form.Label>
          <Form.Control
            required={requiredFields?.gatewayToken}
            identifier={meterGateway.id}
            className="mute-form p-3"
            name="key"
            value={meterGateway.key || ''}
            onChange={updateGatewayData}
            onBlur={handleBlurEventInGateway}
          >
            </Form.Control>
          <Form.Control.Feedback type="invalid">
            Por favor ingrese el token del gateway
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <GatewayDevices
          devices={meterGateway.meterDevices}
          devicesData={devicesData}
          loading={gatewayLoading}
          error={errorInGateway}
          updateDevicesData={updateDevicesData}
          hideFields={hideFields}
        />
      </Form.Row>
    </React.Fragment>
  );
}

export const InversorsSection = ({
  index,
  inverter,
  setInverter,
}) => {
  const marcas = [
    { name: 'Solis', key: 0 },
    { name: 'Otro', key: 1 }
  ];
  const updateInvertersData = (event, attributeName, index) => {
    const { target } = event;
    const { value } = target;
    setInverter(index, { ...inverter, [attributeName]: value });
  };
  return (
    <React.Fragment>
        <Form.Row>
          <Form.Group as={Col} controlId={`formGridInversorBrand`} className="mr-4 mt-2">
            <Form.Label className="mb-1">Marca</Form.Label>
            <Form.Control
              required
              className="mute-form select"
              as="select"
              name="inverterBrandId"
              value={inverter.inverterBrandId || ''}
              onChange={(e) => 
                updateInvertersData(e, "inverterBrandId", index)
              }
            >
              <option value="">Seleccionar tarifa</option>
              {marcas.map((marca, index) => (
                <option key={marca.key} value={marca.key}>{marca.name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Por favor ingrese la marca del inversor
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId={`formGridInversorSN`} className="mr-4 mt-2">
            <Form.Label className="mb-1">Número de Serie</Form.Label>
            <Form.Control
              required
              className="mute-form p-3"
              name="sn"
              value={inverter.sn || ''}
              onChange={(e) => 
                updateInvertersData(e, "sn", index)
              }
            />
            <Form.Control.Feedback type="invalid">
              Por favor ingrese el id del inversor
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
    </React.Fragment>
  );
}