import { gql } from 'apollo-boost';

const OPERATIONAL_DATA = gql`
  query operationalData($clientId: ID!, $dateFilter: ISO8601Date, $intervalMinutes: Int) {
     operationalData(clientId: $clientId, dateFilter: $dateFilter, intervalMinutes: $intervalMinutes) {
      meterReadingLines {
        meterDeviceName
        readingLines {
          t
          l {
            n
            a
            v
            w
            p            
          }
        }
      }
    }
  }
`;

export default OPERATIONAL_DATA;