import React, {useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';


import ClientInvitationForm from './ClientInvitationForm';
import bg from '../../images/login-background.png';
import USER_FROM_INVITATION_TOKEN from '../../graphql/queries/user_from_invitation_token';
import Loader from '../shared/Loader/Loader';
import { homeLogo, homeLogoAlt } from '../shared/whiteLabel/whiteLabel';

const ClientInvitation = () => {
  const { invitationToken } = useParams();
  const { data, loading } = useQuery(USER_FROM_INVITATION_TOKEN, {
    variables: { invitationToken: String(invitationToken) },
  });

  const clientName = data?.userFromInvitationToken?.name;
  const [succesfulResponse, setSuccesfulResponse] = useState({});
  const [errorResponse, setErrorResponse] = useState('');
  const getResponse = (response, setState) => {
     setState(response);
  }

  const displayContent = () => {
    if (loading) {
      return <Loader message="Validando token" />;
    }

    if (data?.userFromInvitationToken === null) {
      return <h1 className="text-muted" data-testid="invalid-token">Lo sentimos, token inválido</h1>;
    }

    if (Object.keys(succesfulResponse).length > 0) {
      let {name} = succesfulResponse.user;
      return <h6 className="text-muted" data-testid="password-confirmation-message">{name}, tu contraseña ha sido creada. Ya puedes <Link to="/">iniciar sesion</Link>.</h6>
    }

    return <ClientInvitationForm getResponse={getResponse} setSuccesfulResponse={setSuccesfulResponse} setErrorResponse={setErrorResponse}/>
  };

  return (
    <Container fluid className="d-flex flex-column align-items-center">
      <img src={bg} alt="Background" className="login-bg" />
      <Row className="my-1 h-25">
        <Col className="d-flex flex-column justify-content-center">
          <Row>
            <Col className="text-center">
              <img
                src={homeLogo()}
                alt={homeLogoAlt()}
                className="home-logo"
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="text-center">
              <div>
                <h4 className="font-weight-bold text-muted" data-testid="welcome-message">
                  Bienvenido {clientName ? clientName : ''}
                </h4>
              </div>
              <div>
                <h4 className="font-weight-bold text-primary">
                  Configura tu contraseña
                </h4>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="h-50 mt-5 d-flex flex-column">
        <Col>
          {displayContent()}
        </Col>
      </Row>
      {errorResponse.length > 0 && <Row className="text-muted mt-4" data-testid='mutation-graphql-error'>{errorResponse}</Row>}
    </Container>
  );
};

export default ClientInvitation;
