import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Col, Alert } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';

import ROUTES from '../../../routing/routes';
import CREATE_CLIENT from '../../../graphql/mutations/createClient';
import EDIT_CLIENT from '../../../graphql/mutations/editClient';
import CLIENT_INFO from '../../../graphql/queries/client_info';
import STATES from '../../../graphql/queries/states';
import CFE_FEES from '../../../graphql/queries/cfeFees';
import { PersonalInfoSection, AddressSection, CfeInfoSection, PanelsInfoSection, GatewaySection, InversorsSection } from './ClientFormSections';
import Loader from '../../shared/Loader/Loader';
import { stripTypenames } from '../../../graphql/client';
import ConfirmationToast from '../../shared/ConfirmationToast/ConfirmationToast';
import DELETE_CLIENT from '../../../graphql/mutations/deleteClient';
import ConfirmationModal from '../../shared/ConfirmationModal/ConfirmationModal';
import { nullifyEmptyStrings } from '../../../services/inputs_service/inputs_service';

function ClientForm({ isEdit = false }) {
  const history = useHistory();
  const { clientId } = useParams();
  const [errorResponse, setErrorResponse]= useState('');
  const [validated, setValidated] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorInModal, setErrorInModal] = useState('');

  const { data: dataStates } = useQuery(STATES);
  const { data: dataCfeFees } = useQuery(CFE_FEES);
  const [deleteClient] = useMutation(DELETE_CLIENT);
  const [getClientData, { called: calledClientData, loading: loadingClientData, data: clientData }] = useLazyQuery(
    CLIENT_INFO,
    { variables: { clientId: Number(clientId) } }
  ); 
  const mutationOptions =
    isEdit ? {
      errorPolicy: 'all',
      refetchQueries: [
        { query: CLIENT_INFO, variables: { clientId: Number(clientId) } }
      ]
    }
    : {
      errorPolicy: 'all'
    };
  const [clientMutation] = useMutation(isEdit ? EDIT_CLIENT : CREATE_CLIENT, mutationOptions);
  const [meterGateways, setMeterGateways] = useState([{ name: '', key: '' }]);
  const [gatewayError, setGatewayError] = useState();
  const [newMeterGateway, setNewMeterGateway] = useState({ name: '', key: '' });
  const [inverters, setInverters] = useState([]);

  const cfeFees = dataCfeFees?.cfeFees || [];
  const states = dataStates?.states || [];

  const [clientAttributes, setClientAttributes] = useState({
    id: '',
    name: '',
    email: '',
    channel: '',
    customId: '',
  });

  const [homeAttributes, setHomeAttributes] = useState({
    street: '',
    externalNumber: '',
    neighborhood: '',
    zipcode: '',
    city: '',
    stateId: '',
    latitude: 0,
    longitude: 0,
  });

  const [cfeHomeAttributes, setCfeHomeAttributes] = useState({
    cfeName: '',
    cfeRpu: '',
    cfeFeeId: '',
    installedKw: '',
    cfeInterconnectionAt: '',
  });

  const [cfeInvoiceAttributes, setCfeInvoiceAttributes] = useState({
    periodStartDate: '',
    periodEndDate: '',
    generation: '',
    consumption: ''
  });

  const [meterDevicesAttributes, setDeviceAttributes] = useState({
    key: '',
    name: ''
  });

  const [inverterAttributes, setInverterAttributes] = useState({
    id: '',
    inverterBrandId: '',
    sn: ''
  });

  const [copySuccess, setCopySuccess] = useState('Copiar Link');
  const [setupToken, setSetupToken] = useState(null);

  useEffect(() => {
    if(isEdit) {
      getClientData();
    }
  }, [isEdit, getClientData])

  useEffect(() => {
    if(clientData) {
      const response = stripTypenames(clientData.clientInfo);
      let cfeInvoice = response?.cfeInvoices?.length
        ? response.cfeInvoices[0] :
        { periodStartDate: '', periodEndDate: '', generation: '', consumption: '' };
      if (cfeInvoice.periodStartDate) {
        cfeInvoice.periodStartDate = cfeInvoice.periodStartDate.substring(0, 10);
      }
      if (cfeInvoice.periodEndDate) {
        cfeInvoice.periodEndDate = cfeInvoice.periodEndDate.substring(0, 10);
      }
      setClientAttributes(prevState => {
        const clientResp = response.client
        const { setupToken, ...noSetupToken } = clientResp
        return {...prevState, ...noSetupToken};
      });
      setHomeAttributes(prevState => {
        return {...prevState, ...response.home};
      });
      setCfeHomeAttributes(prevState => {
        return {...prevState, ...response.cfeHome};
      });
      setMeterGateways(response.meterGateways || []);
      setCfeInvoiceAttributes(prevState => {
        return {...prevState, ...cfeInvoice};
      });
      setDeviceAttributes(response.meterDevices || []);
      setInverters(response.inverters || []);
      setSetupToken(response.client.setupToken);
    }
  }, [clientData])

  const handleCancelForm = (_e) => {
   history.push(ROUTES[1].path);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let cfeHomeParams = { ...cfeHomeAttributes };
      cfeHomeParams.cfeInterconnectionAt = cfeHomeParams.cfeInterconnectionAt === '' ? null : cfeHomeParams.cfeInterconnectionAt;
      const formattedMeterGateways = meterGateways.filter(item => item.name !== '' && item.key !== '');
      const formattedInverters = inverters.filter(item => item.id !== '' && item.inverterBrandId !== '');
      clientMutation({
        variables: {
          clientAttributes: nullifyEmptyStrings(clientAttributes),
          homeAttributes: nullifyEmptyStrings(homeAttributes),
          cfeHomeAttributes: nullifyEmptyStrings(cfeHomeParams),
          meterGatewaysAttributes: nullifyEmptyStrings(formattedMeterGateways.map(item => ({ ...item, meterDevices: item.meterDevices?.map(({ note, ...rest }) => rest) }))),
          cfeInvoiceAttributes: nullifyEmptyStrings(cfeInvoiceAttributes),
          deviceAttributes: nullifyEmptyStrings(meterDevicesAttributes.map(({ note, ...rest }) => rest)),
          inverterAttributes: nullifyEmptyStrings(formattedInverters.map(item => ({ ...item, inverters: item.inverters?.map(({ note, ...rest }) => rest) }))),
        }
      })
      .then((res) => {
        const response = isEdit ? res.data.updateClientMutation.client : res.data.createClientMutation.client;
        const id = response.id;
        setErrorResponse('');
        history.push(`${ROUTES[1].routes[1].constructor + id}`, {
          successfulResponse: {
            name: response.name,
            email: response.email,
            isEdit: isEdit,
            isEliminated: false,
          }
        });
      })
      .catch(err => {
        console.log('error: ', err)
        const grapqhlErrors = err?.graphQLErrors || [];
        const errMsg = grapqhlErrors.length ? grapqhlErrors[0].message : '';
        const errorMsg =`Lo sentimos no se pudo ${isEdit ? 'editar' : 'crear'} el cliente. ${errMsg}`;
        setErrorResponse(errorMsg);
        setShowToast(true);
      })
    }

  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorInModal('');
  }

  const setMeterGateway = (index, meterGateway) => {
    const copy = [...meterGateways];
    copy[index] = meterGateway;
    setMeterGateways(copy);
  }
  
  const setInverter = (index, newInverter) => {
    setInverters(prevInverters => {
      const copy = [...prevInverters];
      copy[index] = newInverter;
      return copy;
    });
  };

  const handleConfirmationModal = (id) => {
    deleteClient({ variables: { clientId: id} })
      .then((res) => {
        const response = res.data.deleteClient.client;
        setErrorInModal('');

        const location = {
          pathname: ROUTES[1].path,
          state: {
            name: response.name,
            email: response.email,
            isEdit: isEdit,
            isEliminated: true
          }
        }
        setShowModal(false);
        history.push(location);
      })
      .catch((_err) => {
        setErrorInModal('Lo sentimos, ocurrió un error. No se pudo borrar el cliente');
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  }
  
  const buildSetupURLForProvider = (token) => {
    let url = window.location.origin;
    return url+"/setup/"+token;
  }

  const handleAddMeterGateway = () => {
    if (newMeterGateway.name === '' && newMeterGateway.key === '') {
      setMeterGateways([...meterGateways, newMeterGateway]);
      setNewMeterGateway({ name: '', key: '' });
    }
  };
  return (
    <Container className="text-secondary form-container justify-content-center">
      { loadingClientData && <Loader message="Cargando datos del cliente..." /> }
      { ((!loadingClientData && calledClientData) || !isEdit) &&
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          role="form"
          className="w-100"
        >
          <h1 className='text-center mb-4'>{isEdit ? "Editar" : "Alta de"} Cliente</h1>

          {isEdit && setupToken && (
            <Alert variant="success">
              <Alert.Heading className='text-center'>
                Link para portal de instalación de medidores
              </Alert.Heading>
              <Container className="d-flex w-100 justify-content-center">
                {buildSetupURLForProvider(setupToken)}
                <Button
                  className="btn-sm ml-4"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      buildSetupURLForProvider(setupToken)
                    );
                    setCopySuccess("Copiado");
                  }}
                  variant="outline-success"
                >  
                {copySuccess}
                </Button>
              </Container>
            </Alert>
          )}
          <div >
            <h4 className="text-primary mt-4">Información personal</h4>
            <PersonalInfoSection
              client={clientAttributes}
              setAttributes={setClientAttributes} />
          </div>

          <div >
            <h4 className="text-primary mt-4">Dirección</h4>
            <AddressSection
              street={homeAttributes.street}
              externalNumber={homeAttributes.externalNumber}
              neighborhood={homeAttributes.neighborhood}
              zipcode={homeAttributes.zipcode}
              city={homeAttributes.city}
              stateId={homeAttributes.stateId}
              latitude={homeAttributes.latitude}
              longitude={homeAttributes.longitude}
              states={states}
              setAttributes={setHomeAttributes} />
          </div>

          <div >
            <h4 className="text-primary mt-4">CFE - Información</h4>
            <CfeInfoSection
              cfeName={cfeHomeAttributes.cfeName}
              cfeRpu={cfeHomeAttributes.cfeRpu}
              consumption={cfeInvoiceAttributes.consumption}
              generation={cfeInvoiceAttributes.generation}
              cfeFeeId={cfeHomeAttributes.cfeFeeId}
              cfeFees={cfeFees}
              periodStartDate={cfeInvoiceAttributes.periodStartDate}
              periodEndDate={cfeInvoiceAttributes.periodEndDate}
              setCfeHomeAttr={setCfeHomeAttributes}
              setCfeInvoiceAttr={setCfeInvoiceAttributes} />
          </div>

          <div className="my-4">
            <h4 className="text-primary mt-4">Paneles - Información</h4>
            <PanelsInfoSection
              isEdit={isEdit}
              cfeHomeAttributes={cfeHomeAttributes}
              setCfeHomeAttr={setCfeHomeAttributes}/>
          </div>

          <div className="my-4">
            <h4 className="text-primary mt-4">Gateway - Información</h4>
            {meterGateways.map((g, index) => (
              <GatewaySection
                key={index}
                isEdit={isEdit}
                meterGateway={g}
                setMeterGateway={setMeterGateway}
                requiredFields={[{gatewayName:true, gatewayToken:true}]}
                setDeviceAttributes={setDeviceAttributes}
                setGatewayError={setGatewayError} 
                index={index} />
            ))}

            <Button className="btn-outline btn-sm my-3" onClick={handleAddMeterGateway}>
              Agregar Medidor
            </Button>
          </div>

          <div className="my-4">
            <h4 className="text-primary mt-4">Inversor - Información</h4>
            {inverters.map((inverter, index) => (
              <InversorsSection
                key={index}
                index={index}
                isEdit= {true}
                inverter = {inverter}
                setInverter = {setInverter}
                //requiredFields ={[{id: false, inverterBrandId: false}]} 
              />
            ))}
          </div>

          <Form.Row className="justify-content-end mt-5">
            <Col>
              <ConfirmationToast header="Error" message={errorResponse} isSuccess={false} showToast={showToast} setShowToast={setShowToast}/>
            </Col>
            <Col xs={12} md={3} className="text-align-right">
              <Button
                name="cancelForm"
                className="submit-button btn-outline mt-4 mr-2 inline-block w-100 mb-0 "
                data-testid="cancelForm"
                id="cancelForm"
                onClick={handleCancelForm}
              >
                Cancelar
              </Button>
            </Col>

           {
              isEdit &&
              (<Col xs={12} md={3} className="text-align-right">
                <Button
                  name="deleteClient"
                  className="submit-button btn-outline mt-4 mr-2 inline-block w-100 mb-0"
                  data-testid="deleteClient"
                  id="deleteClient"
                  onClick={handleShowModal}
                >
                  Borrar
                </Button>
                <ConfirmationModal
                 show={showModal}
                 handleClose={handleCloseModal}
                 handleConfirmation={handleConfirmationModal}
                 inputConfirmation={Number(clientId)}
                 modalTitle="Borrar cliente"
                 message="¿Seguro que quieres borrar el cliente?"
                 error={errorInModal}
                /> 
              </Col>)
            }

            <Col xs={12} md={3} className="text-align-right">
              <Button
                variant="primary"
                type="submit"
                name="createClient"
                className="submit-button mt-4 inline-block w-100 mb-0 "
                id="formCreateClientButton"
                disabled={gatewayError}
              >
                {isEdit ? "Guardar" : "Dar de alta"}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      }
    </Container>
  );
}

export default ClientForm;
