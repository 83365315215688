import { gql } from "apollo-boost";

const GATEWAY_VALIDATION = gql`
  mutation meterGatewayValidation(
    $setupToken: String!
    $activationCode: String!
  ) {
    meterGatewayValidation(
      input: {
        setupToken: $setupToken
        activationCode: $activationCode
      }
    ) {
      validated
    }
  }
`;

export default GATEWAY_VALIDATION;
