import ROLES from "./roles";
import LoginPage from "../components/LoginPage/LoginPage";
import ProviderLayout from "../components/Provider/ProviderLayout";
import ProviderDashboard from "../components/Provider/Dashboard";
import Clients from "../components/Provider/Clients/Clients";
import ClientDashboard from "../components/Provider/ClientDashboard/ClientDashboard";
import CreateClient from '../components/Provider/CreateClient/CreateClient';
import EditClient from '../components/Provider/EditClient/EditClient';
import ClientLayout from '../components/Client/ClientLayout';
import MyDashboard from "../components/Client/MyDashboard/MyDashboard";
import ClientInvitation from '../components/ClientInvitation/ClientInvitation';
import ProviderSetup from '../components/ProviderSetup/ProviderSetup';
import CreateClientHubspot from '../components/Provider/CreateClientHubspot/CreateClientHubspot';
import GatewayInfo from "../components/GatewayInfo/GatewayInfo";

const ROUTES = [
  { path: "/", key: "ROOT", exact: true, component: LoginPage, roleLevel: ROLES['user'] },
  {
    path: "/provider",
    key: "PROVIDER_LAYOUT",
    exact: false,
    roleLevel: ROLES['provider'],
    component: ProviderLayout,
    routes: [
      {
        path: "",
        key: "PROVIDER_CLIENTS",
        exact: true,
        roleLevel: ROLES['provider'],
        component: Clients,
      },
      {
        path: "/client_dashboard/:clientId",
        constructor: "/provider/client_dashboard/",
        key: "PROVIDER_CLIENT_DASHBOARD",
        exact: true,
        roleLevel: ROLES['provider'],
        component: ClientDashboard,
      },
      {
        path: "/create",
        key: "PROVIDER_CLIENTS_CREATE",
        exact: false,
        roleLevel: ROLES['provider'],
        component: CreateClient,
      },
      {
        path: "/edit_client/:clientId",
        constructor: "/provider/edit_client/",
        key: "PROVIDER_EDIT_CLIENT",
        exact: false,
        roleLevel: ROLES['provider'],
        component: EditClient,
      },
      {
        path: "/hubspot_client",
        key: "PROVIDER_HUBSPOT_CLIENT",
        exact: false,
        roleLevel: ROLES['provider'],
        component: CreateClientHubspot,
      },
      {
        path: "/dashboard",
        key: "PROVIDER_DASHBOARD",
        exact: true,
        roleLevel: ROLES["provider"],
        component: ProviderDashboard,
      }
    ]
  },
  {
    path: "/client",
    key: "CLIENT_LAYOUT",
    exact: false,
    roleLevel: ROLES['user'],
    component: ClientLayout,
    routes: [
      {
        path: "",
        key: "CLIENT_DASHBOARD",
        exact: false,
        roleLevel: ROLES['user'],
        component: MyDashboard,
      }
    ]
  },
  {
    path: "/accept_invitation/:invitationToken",
    constructor: "/accept_invitation/",
    key: "ACCEPT_INVITATION",
    exact: true,
    private: false,
    roleLevel: ROLES['user'],
    component: ClientInvitation,
  },
  {
    path: "/setup/:setupToken",
    constructor: "/setup/",
    key: "SETUP_TOKEN",
    exact: true,
    private: false,
    roleLevel: ROLES['user'],
    component: ProviderSetup,
  },
  {
    path: "/check_gateway/:gatewayName",
    constructor: "/check_gateway/",
    key: "CHECK_GATEWAY",
    exact: true,
    private: false,
    roleLevel: ROLES['user'],
    component: GatewayInfo,
  }
];

export default ROUTES;
