import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import CLIENT_BY_TOKEN from "../../graphql/queries/client_by_token";
import ProviderSetupForm from "./ProviderSetupForm";

import Loader from "../shared/Loader/Loader";
import { homeLogo, homeLogoAlt } from '../shared/whiteLabel/whiteLabel';

const ProviderSetup = () => {
  const { setupToken } = useParams();
  const { data, loading, error } = useQuery(CLIENT_BY_TOKEN, {
    variables: { setupToken: String(setupToken) },
  });
  const [succesfulResponse, setSuccesfulResponse] = useState({});
  const getResponse = (response, setState) => {
    setState(response);
  };

  const displayContent = () => {
    if (loading) {
      return <Loader message="Validando token" />;
    }

    if (data?.clientByToken === null || error !== undefined) {
      return (
        <Col className="d-flex justify-content-center">
          <p className="text-muted" data-testid="invalid-token">
            Lo sentimos, token inválido
          </p>
        </Col>
      );
    }

    if (Object.keys(succesfulResponse).length > 0 && succesfulResponse.validated) {
      return (
        <Col className="d-flex justify-content-center">
          <h2 className="text-muted" data-testid="confirmation-message">La instalación ha finalizado correctamente</h2>
        </Col>
      )
    }

    return (
      <ProviderSetupForm
        getResponse={getResponse}
        setSuccesfulResponse={setSuccesfulResponse}
        clientData={data?.clientByToken}
        setupToken={setupToken}
      />
    );
  };

  return (
    <Container fluid className="text-secondary">
      <Row className="my-4">
        <Col className="text-center">
          <img
            src={homeLogo()}
            alt={homeLogoAlt()}
            className="home-logo"
          />
        </Col>
      </Row>
      <div className="d-flex flex-column provider-container justify-content-center">
        <Row>
          <Col className="text-center">
            <h3 className="mb-4">Portal de Instalación de medidores</h3>
          </Col>
        </Row>
        {displayContent()}
      </div>
    </Container>
  );
};

export default ProviderSetup;
