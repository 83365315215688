import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GDMTH_DAY_CONSUMPTION from '../../../../graphql/queries/gdmth_day_consumption';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/es';

import { chartMaxValue } from '../../../shared/sharedFunctions/sharedFunctions';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.locale('es');

const reducedDates = (data) => {
  return data.reduce((acc, item) => {
    const value = {base: Number((item.base).toFixed(2)), intermediate: Number((item.intermediate).toFixed(2)), high: Number((item.high).toFixed(2))};
    const date = item.x;
    const dayjsDate = dayjs(date, "DD/MM/YYYY");

        acc[date] = {
         value,
         dayjsDate,
       }

      return acc;
  }, {});
}

export const useGdmthDayConsumption = (clientId, startDate, endDate) => {
  let minValue = Infinity;
  let maxValue = -Infinity;
  const { loading, error, data } = useQuery(GDMTH_DAY_CONSUMPTION, {
    variables: {clientId, startDate, endDate},
  });
  
    const graphData = useMemo(() => {
    const cfeConsumption = data?.gdmthDayConsumption?.cfeConsumption || [];
    const solarConsumption = data?.gdmthDayConsumption?.solarConsumption || [];
    const injectedGeneration = data?.gdmthDayConsumption?.injectedGeneration || [];
    if (
      cfeConsumption.length || solarConsumption.length ||
      injectedGeneration.length
    ) {

      const reducedCfeConsumptionDates = reducedDates(cfeConsumption);
      const reducedSolarConsumptionDates = reducedDates(solarConsumption);
      const reducedInjectedGenerationDates = reducedDates(injectedGeneration);

      const dates = [
        ...Object.keys(reducedCfeConsumptionDates),
        ...Object.keys(reducedSolarConsumptionDates),
        ...Object.keys(reducedInjectedGenerationDates)
      ].sort((a, b) => {
        const dateA = dayjs(a, 'DD/MM/YYYY', 'es');
        const dateB = dayjs(b, 'DD/MM/YYYY', 'es');

        return dateA.isBefore(dateB) ? -1 : 1;
      })

      const uniqueDates = Array.from(new Set(dates));
      
      const days = uniqueDates.map(date => {
         let newFormatDate = dayjs(date, "DD/MM/YYYY").format('DD/MM/YYYY');
         newFormatDate = newFormatDate.charAt(0).toUpperCase() + newFormatDate.slice(1);
         newFormatDate = newFormatDate.replace('.-', ' ');

         return {
           date: newFormatDate,
           cfeConsumption: reducedCfeConsumptionDates[date]?.value || 0,
           solarConsumption: reducedSolarConsumptionDates[date]?.value || 0,
           consumedGeneration: reducedSolarConsumptionDates[date]?.value || 0,
           injectedGeneration: reducedInjectedGenerationDates[date]?.value || 0
         }
      });
      return days;
    }

    return [];
  }, [data]);

  if (graphData.length) {
    graphData.forEach(data => {
       const consumption = data.solarConsumption + data.cfeConsumption;
       const generation = data.consumedGeneration + data.injectedGeneration;

       minValue = Math.min(minValue, consumption, generation);
       maxValue = chartMaxValue(Math.max(maxValue, consumption, generation));
    })
  }

  return {
    loading,
    error,
    graphData,
    minValue: Math.floor(minValue),
    maxValue: Math.ceil(maxValue),
  };
};

export const useTotalGdmthDayConsumption = (clientId, startDate, endDate) => {
  let minValue = Infinity;
  let maxValue = -Infinity;
  const { loading, error, data } = useQuery(GDMTH_DAY_CONSUMPTION, {
    variables: { clientId, startDate, endDate },
  });

  const graphData = useMemo(() => {
    const cfeConsumption = data?.gdmthDayConsumption?.cfeConsumption || [];
    const solarConsumption = data?.gdmthDayConsumption?.solarConsumption || [];
    const injectedGeneration = data?.gdmthDayConsumption?.injectedGeneration || [];

    if (
      cfeConsumption.length || solarConsumption.length ||
      injectedGeneration.length
    ) {
      const reducedCfeConsumptionDates = reducedDates(cfeConsumption);
      const reducedSolarConsumptionDates = reducedDates(solarConsumption);
      const reducedInjectedGenerationDates = reducedDates(injectedGeneration);

      const dates = [
        ...Object.keys(reducedCfeConsumptionDates),
        ...Object.keys(reducedSolarConsumptionDates),
        ...Object.keys(reducedInjectedGenerationDates)
      ].sort((a, b) => {
        const dateA = dayjs(a, 'DD/MM/YYYY', 'es');
        const dateB = dayjs(b, 'DD/MM/YYYY', 'es');

        return dateA.isBefore(dateB) ? -1 : 1;
      })

      const uniqueDates = Array.from(new Set(dates));
      
      const days = uniqueDates.map(date => {
         let newFormatDate = dayjs(date, "DD/MM/YYYY").format('DD/MM/YYYY');
         newFormatDate = newFormatDate.charAt(0).toUpperCase() + newFormatDate.slice(1);
         newFormatDate = newFormatDate.replace('.-', ' ');

         return {
           date: newFormatDate,
           cfeConsumption: reducedCfeConsumptionDates[date]?.value || 0,
           solarConsumption: reducedSolarConsumptionDates[date]?.value || 0,
           consumedGeneration: reducedSolarConsumptionDates[date]?.value || 0,
           injectedGeneration: reducedInjectedGenerationDates[date]?.value || 0,
           baseConsumption: (reducedCfeConsumptionDates[date]?.value['base'] +
                            (reducedSolarConsumptionDates[date]?.value['base'] - reducedInjectedGenerationDates[date]?.value['base'])),
           intermediateConsumption: (reducedCfeConsumptionDates[date]?.value['intermediate'] +
                                    (reducedSolarConsumptionDates[date]?.value['intermediate'] - reducedInjectedGenerationDates[date]?.value['intermediate'])),
           highConsumption: (reducedCfeConsumptionDates[date]?.value['high'] +
                            (reducedSolarConsumptionDates[date]?.value['high'] - reducedInjectedGenerationDates[date]?.value['high'])),
           baseGeneration: reducedSolarConsumptionDates[date]?.value['base'],
           intermediateGeneration: reducedSolarConsumptionDates[date]?.value['intermediate'],
           highGeneration:reducedSolarConsumptionDates[date]?.value['high']
         }
      });
      return days;
    }

    return [];
  }, [data]);

  if (graphData.length) {
    graphData.forEach(data => {
      minValue = Math.min(minValue, data.baseConsumption, data.intermediateConsumption, data.intermediateGeneration);
      maxValue = chartMaxValue(Math.max(maxValue, data.baseConsumption + data.intermediateConsumption + data.highConsumption));
    })
  }

  return {
    loading,
    error,
    graphData,
    minValue: Math.floor(minValue),
    maxValue: Math.ceil(maxValue),
  };
};
