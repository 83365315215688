import React from 'react';
import { Card, Button, CardColumns, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Badge from 'react-bootstrap/Badge';
import ROUTES from '../../../routing/routes';
import Loader from '../../shared/Loader/Loader';
import InfoMessage from '../../shared/InfoMessage/InfoMessage';
import { getDisplayValue } from '../../shared/sharedFunctions/sharedFunctions';
import {I_ICON_MESSAGES, ALERT_ICON_MESSAGES} from '../../shared/InfoMessage/messages';

const ClientInfo = ({ title, children = '', infoMessage = null }) => {
  const getInfoMessage = () => {
    return infoMessage ? infoMessage : <InfoMessage message={children} placement='top' margin='m-2'/>
  }

  return (
    <div className="mt-2 mb-2" data-testid="clientInfo">
      <div className="d-flex align-items-center"
      >
        <h6 className="card-title-client text-muted text-uppercase font-weight-bold m-0 mr-1 text-nowrap">
          {title}
        </h6>
        {getInfoMessage()}
      </div>
      <p className={`${title === 'Gateway' || title === 'Ciudad' ? 'text-truncate': ''} 'card-info-client text-muted-strong font-weight-bold m-0'`}>
        {children}
      </p>
    </div>
  )
};

const CategoryInfoMessage = ({infoHeader = '', infoMessage = '', placement='top'}) => {
  return (
    <InfoMessage header={infoHeader} message={infoMessage} placement={placement} margin="m-2"/>
  )
}

export const ClientCard = ({
  name,
  id,
  currentPeriodAvgKwhConsumption,
  clientYield,
  status,
  installedKw,
  state,
  zipcode,
  kwhGenYesterday,
  lowYieldAlert,
  openLogsModal
}) => {
  const currentConsumptionInfoHeader = 'Consumo por día promedio (en Kwh) del periodo de facturación';
  const currentConsumptionInfoMessage = 'Periodo de facturación: período de tiempo desde que se inician tus facturas cada mes, hasta que finalizan.';

  const history = useHistory();
  const showClientDashboard = () => {
    history.push(ROUTES[1].routes[1].constructor + id);
  };

  const showEditClientForm = () => {
    history.push(ROUTES[1].routes[3].constructor + id);
  }

  return (
    <Card style={{ width: '20rem'}}  data-testid="client-card" className='client-data-wrapper border border-1' data-id={id}>
      <Card.Body>
        <div>
          <Card.Title className="text-secondary">{name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">#{id}</Card.Subtitle>
        </div>
        <Row>
          <Col className="pr-1">
            <ClientInfo title="Estado">{state}</ClientInfo>
          </Col>
          <Col className="pl-1">
            <ClientInfo title="Código postal">{zipcode}</ClientInfo>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1">
            <ClientInfo
            title="Promedio Diario"
            infoMessage={
              <CategoryInfoMessage
              infoHeader={currentConsumptionInfoHeader}
              infoMessage={currentConsumptionInfoMessage}
              />}
            >
              {currentPeriodAvgKwhConsumption}
            </ClientInfo>
          </Col>
          <Col className="pl-1">
            <ClientInfo
            title="Yield"
            infoMessage={
              <CategoryInfoMessage infoHeader={I_ICON_MESSAGES.yield.header} infoMessage={I_ICON_MESSAGES.yield.message}/>
            }
            >
              {clientYield}
              {lowYieldAlert && <InfoMessage icon="exclamation-triangle" header={ALERT_ICON_MESSAGES.yield.header} message={ALERT_ICON_MESSAGES.yield.message} placement="bottom" buttonClassNames={{'margin': 'ml-1 mb-1', 'color': 'text-danger'}}/>}
            </ClientInfo>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1">
            <ClientInfo title="KW instalado">{installedKw}</ClientInfo>
          </Col>
          <Col className="pl-1">
            <ClientInfo
            title="Gen. Total Diaria"
            infoMessage={
              <CategoryInfoMessage infoHeader={I_ICON_MESSAGES.kwhGenYesterday.header} infoMessage={I_ICON_MESSAGES.kwhGenYesterday.message} placement="left"/>
            }
            >
              {kwhGenYesterday}
            </ClientInfo>
          </Col>
        </Row>
        <Row>
          {/* //TODO: If this component is used add gateway status component here and please dont forget to test it */}
          <Col className="pl-1" onClick={openLogsModal}>
            <ClientInfo
            title="SFV"
            infoMessage={
              <CategoryInfoMessage infoHeader={I_ICON_MESSAGES.statusSFV.header} infoMessage={I_ICON_MESSAGES.statusSFV.message} placement="left"/>
            }
            >
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id={`card-tooltip-${id}`}>
                    Click para ver historial de cambios.
                  </Tooltip>
                }
              >
                {status
                  ? <Badge pill variant="success" role='button'>Activo</Badge>
                  : <Badge pill variant="danger" role='button'>Desactivado</Badge>
                }
              </OverlayTrigger>
              
            </ClientInfo>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-primary"
            className="mr-2"
            size="sm"
            onClick={showClientDashboard}
            data-testid="client-card-see"
          >
            Ver
          </Button>
          <Button
           variant="outline-primary"
           size="sm"
           onClick={showEditClientForm}
           data-testid="client-card-edit"
          >
            Editar
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export const ClientsCards = ({ clients, loading, openLogsModal }) => {
  return (
    <>
      {loading ? (
        <Loader />
      ) : clients.length ? (
        <CardColumns className="card-column-group" data-testid="clients-cards">
          {clients.map((client) => {
            return (
              <ClientCard
                name={getDisplayValue(client?.name)}
                id={client.id}
                key={client.id}
                status={client?.meterGateway?.active ?? false}
                currentPeriodAvgKwhConsumption={getDisplayValue(client?.meterGateway?.currentPeriodAvgKwhConsumption)}
                clientYield={getDisplayValue(client?.meterGateway?.yieldGen)}
                installedKw={getDisplayValue(client?.cfeHome?.installedKw)}
                kwhGenYesterday={getDisplayValue(client?.meterGateway?.kwhGenYesterday)}
                zipcode={getDisplayValue(client?.home?.zipcode)}
                state={getDisplayValue(client?.home?.state?.cfeShortName)}
                lowYieldAlert={getDisplayValue(client?.meterGateway?.lowYieldAlert)}
                openLogsModal={openLogsModal}
              />
            );
          })}
        </CardColumns>
      ) : (
        <div className=" w-100 h-100 d-flex align-items-center justify-content-center">
          <p className="m-0 text-muted">No hay clientes disponibles.</p>
        </div>
      )}
    </>
  );
};
