import { gql } from 'apollo-boost';

const MONTHLY_KWH_GENERATION = gql`
  query monthlyKwhGeneration($q: BaseScalar, $start_date: ISO8601Date!, $end_date: ISO8601Date!) {
    monthlyKwhGeneration(startDate: $start_date, endDate: $end_date, q: $q) {
      kwhGeneration
      month
    }
  }
`;

export default MONTHLY_KWH_GENERATION;