import genericLogo from '../../../images/generic-logo.png'

import galtHomeLogo from '../../../images/galt-home-logo.png'
import eniconHomeLogo from '../../../images/enicon-logo.png'
import energonHomeLogo from '../../../images/energon-logo.png'
import enlightHomeLogo from '../../../images/enlight-logo.png'
import greenluxHomeLogo from '../../../images/greenlux-logo.png'
import prodensaHomeLogo from '../../../images/prodensa-logo.png'
import solarFuelHomeLogo from '../../../images/solar-fuel-home-logo.png'
import sunecoHomeLogo from '../../../images/suneco-logo.png'
import madisaHomeLogo from '../../../images/madisa-logo.png'
import esolutionsHomeLogo from '../../../images/360esolutions-logo.png'

const COMPANY_NAMES = [
  "Galt",
  "Energon",
  "Enicon",
  "Enlight",
  "Greenlux",
  "Prodensa",
  "Solar Fuel",
  "Suneco",
  "Madisa",
  "360esolutions",
];
const COMPANY_IMAGES = [
  galtHomeLogo,
  energonHomeLogo,
  eniconHomeLogo,
  enlightHomeLogo,
  greenluxHomeLogo,
  prodensaHomeLogo,
  solarFuelHomeLogo,
  sunecoHomeLogo,
  madisaHomeLogo,
  esolutionsHomeLogo,
];

const companyIndex = () => {
  let returnIndex = -1;

  COMPANY_NAMES.forEach(function (company, index) {
    if (window.location.hostname.toLowerCase().includes(company.toLowerCase().replace(/\s/g, ''))) {
      returnIndex = index;
    }
  })

  return returnIndex;
}


export const homeLogo = () => {
  let logo = genericLogo;

  if (companyIndex() >= 0) {
    logo = COMPANY_IMAGES[companyIndex()];
  }

  return logo;
};

export const homeLogoAlt = () => {
  let homeLogoAlt = "eMeter";

  if (companyIndex() >= 0) {
    homeLogoAlt = COMPANY_NAMES[companyIndex()];
  }

  return homeLogoAlt;
};

export const companyName = () => {
  let companyName = "eMeter";
  
  if (companyIndex() >= 0) {
    companyName = COMPANY_NAMES[companyIndex()];
  }

  return companyName;
};

export const pageTitle = () => {
  let pageTitle = "eMeter | Home";
  
  if (companyIndex() >= 0) {
    pageTitle = COMPANY_NAMES[companyIndex()] + " | eMeter";
  }

  return pageTitle;
};