import  React  from 'react';
import { OverlayTrigger, Button, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoMessage = ({icon= "info-circle", placement = "right", buttonClassNames = {}, header='', message}) => {
  const popover = (props) => (
    <Popover id="popover-basic" data-testid="infoMessage" {...props}>
      <Popover.Content>
        {header.length > 0 ? <h6>{header}</h6> : ''}
        {message}
      </Popover.Content>
    </Popover>
  );

  const {position, margin, sass, color} = buttonClassNames;

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      overlay={popover}
    >
      <Button
        className={`d-inline-flex ${color ? color : 'text-muted'} ${position ? position : '' } ${sass ? sass : ''} bg-transparent border-white p-0 ${margin ? margin : 'm-0'} border-0`}
        data-testid="iIconButton"
      >
        <FontAwesomeIcon icon={icon}/>
      </Button>
    </OverlayTrigger>
  );
};

export default InfoMessage;
