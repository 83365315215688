import faker from 'faker';
import dayjs from 'dayjs';

import LIVE_CONSUMPTION from '../../../graphql/queries/live_consumption';
import DAILY_CONSUMPTION from '../../../graphql/queries/daily_consumption';
import USERNAME_GATEWAY from '../../../graphql/queries/username_gateway';
import GDMTH_DAY_CONSUMPTION from '../../../graphql/queries/gdmth_day_consumption';
import OPERATIONAL_DATA from '../../../graphql/queries/operational_data';

// get current and prev date
let currentDate = dayjs(new Date()).format('YYYY-MM-DD');
let prevDate = new Date();
prevDate.setDate(prevDate.getDate() - 30);
prevDate = dayjs(prevDate).format('YYYY-MM-DD');

//get the monthly date format for monthly chart
export let currentMonthlyDate = dayjs(new Date()).format('YYYY-MM');
export let prevMonthlyDate = new Date();
prevMonthlyDate.setDate(prevMonthlyDate.getDate() - 30);
prevMonthlyDate = dayjs(prevMonthlyDate).format('YYYY-MM');

const [year, month] = currentDate.split('-');
let daysInEndDate = new Date(year, month, 0);
daysInEndDate = dayjs(daysInEndDate).format('DD')

export const formatedStartDate = `${prevMonthlyDate}-01`;
export const formatedEndDate = `${currentMonthlyDate}-${daysInEndDate}`;

const fakerNumber = Number(faker.finance.amount(0, 2, 2));

const LIVE_CONSUMPTION_MOCK = {
  liveConsumption: {
    lastUpdateAt: '2020-06-24T20:23:52.760Z',
    injectedGeneration: [
      { x: '25/12/2020', y: fakerNumber },
      { x: '25/01/2021', y: fakerNumber },
      { x: '25/02/2021', y: fakerNumber }
    ],
    cfeConsumption: [
      { x: '25/12/2020', y: fakerNumber },
      { x: '25/01/2021', y: fakerNumber },
      { x: '25/02/2021', y: fakerNumber }
    ],
    solarConsumption: [
      { x: '25/12/2020', y: fakerNumber },
      { x: '25/01/2021', y: fakerNumber },
      { x: '25/02/2021', y: fakerNumber }
    ],
  },
}

const DAILY_CONSUMPTION_MOCK = {
    dailyConsumption: {
      source: "",
      lastUpdateAt: '2020-06-24T20:23:52.760Z',
      injectedGeneration: [
        { x: '09:00', y: fakerNumber },
        { x: '10:00', y: fakerNumber },
        { x: '08:00', y: fakerNumber }
      ],
      cfeConsumption: [
        { x: '09:00', y: fakerNumber },
        { x: '10:00', y: fakerNumber },
        { x: '08:00', y: fakerNumber }
      ],
      solarConsumption: [
        { x: '09:00', y: fakerNumber },
        { x: '10:00', y: fakerNumber },
        { x: '08:00', y: fakerNumber }
      ],
    },
}
const mockedGdmthData = [
  { x: '05/12/2023', base: 27.35, intermediate: 19.63, high: 0 },
  { x: '06/12/2023', base: 20.14, intermediate: 12.73, high: 12.19 },
  { x: '07/12/2023', base: 16.24, intermediate: 5.61, high: 9.73 },
];

const mockedOperativeData = [
  { n: 0, a: 4.38, v: 129.34, w: 505.7, p: 89 },
  { n: 1, a: 2.75, v: 129.3, w: 338.16, p: 94 },
  { n: 2, a: 7.9, v: 128.88, w: 970.35, p: 95 },
];

export const mocks = [
  {
    request: {
      query: LIVE_CONSUMPTION,
      variables: {
        clientId: 0,
        startDate: prevDate,
        endDate: currentDate,
      },
    },
    result: {
      data: LIVE_CONSUMPTION_MOCK
    },
  },
  {
    request: {
      query: LIVE_CONSUMPTION,
      variables: {
        clientId: 0,
        startDate: formatedStartDate,
        endDate: formatedEndDate,
      },
    },
    result: {
      data: LIVE_CONSUMPTION_MOCK
    },
  },
  {
    request: {
      query: DAILY_CONSUMPTION,
      variables: {
        clientId: 0,
        dateFilter: currentDate,
        intervalMinutes: 5
      },
    },
    result: {
      data: DAILY_CONSUMPTION_MOCK
    },
  },
  {
    request: {
      query: USERNAME_GATEWAY,
      variables: {
        clientId: 0,
      },
    },
    result: {
      data: {
        usernameGateway: {
          client: {
            name: 'Eduardo',
            id: 0,
            cfeHome: {
              cfeFeeType: 'GDMTH'
            }
          },
          meterGateway: {
            id: 1,
            active: true,
          },
        },
      },
    },
  },
  {
    request: {
      query: GDMTH_DAY_CONSUMPTION,
      variables: {
        clientId: 0,
        startDate: '2023-11-19',
        endDate: '2023-12-19',
      },
    },
    result: {
      data: {
        gdmthDayConsumption: {
          lastUpdateAt: '2023-12-07',
          maxDemand: mockedGdmthData,
          injectedGeneration: mockedGdmthData,
          cfeConsumption: mockedGdmthData,
          solarConsumption: mockedGdmthData,
        },
      },
    },
  },
  {
    request: {
      query: OPERATIONAL_DATA,
      variables: {
        clientId: 0,
        dateFilter: currentDate, 
      },
    },
    result: {
      data: {
        operationalData: {
          meterReadingLines: [
            {
              meterDeviceName: 'device1',
              readingLines: [
                { t: '09:00', l: mockedOperativeData },
                { t: '09:05', l: mockedOperativeData },                
                { t: '09:10', l: mockedOperativeData },
              ]
            },
            {
              meterDeviceName: 'device2',
              readingLines: [
                { t: '09:00', l: mockedOperativeData },
                { t: '09:05', l: mockedOperativeData },                
                { t: '09:10', l: mockedOperativeData },
              ]
            },
          ]
        },
      },
    },
  },
];
