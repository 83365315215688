import faker from 'faker';
import { JSONObjectResolver } from 'graphql-scalars'
import {
  findUserByEmail,
  users,
  clients,
  states,
  cities,
  cfeFees,
  createHome,
  createCfeHome,
  createMeterGateway,
  createCfeInvoice,
  createMeterDevice,
  findClientById,
  providerChannels
} from './utilities';

export const resolvers = {
  BaseScalar: JSONObjectResolver,
  JSON: JSONObjectResolver,
  Query: {
    userInfo: (_obj, _args, context) => {
      if (!context.currentUser) {
        return new Error('Not logged in.');
      }

      return {
        name: 'User 1',
        email: context.currentUser.email,
        authenticationToken: context.currentUser.authenticationToken,
        authorityLevel: context.currentUser.authorityLevel,
      };
    },
    liveConsumption: (_obj, _args, _context) => {
      return {
        lastUpdateAt: new Date().toISOString(),
        injectedGeneration: [
          { x: '25/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '26/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '27/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '28/12/2020', y: faker.finance.amount(0, 2, 2) }
        ],
        cfeConsumption: [
          { x: '25/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '26/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '27/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '28/12/2020', y: faker.finance.amount(0, 2, 2) }
        ],
        solarConsumption: [
          { x: '25/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '26/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '27/12/2020', y: faker.finance.amount(0, 2, 2) },
          { x: '28/12/2020', y: faker.finance.amount(0, 2, 2) }
        ]
      };
    },
    dailyConsumption: (_obj, _args, _context) => {
      return {
        lastUpdateAt: new Date().toISOString(),
        cfeConsumption: [
          { x: '08:00', y: faker.finance.amount(0, 2, 2) },
          { x: '09:00', y: faker.finance.amount(0, 2, 2) },
          { x: '10:00', y: faker.finance.amount(0, 2, 2) },
          { x: '11:00', y: faker.finance.amount(0, 2, 2) },
          { x: '12:00', y: faker.finance.amount(0, 2, 2) },
          { x: '13:00', y: faker.finance.amount(0, 2, 2) },
          { x: '14:00', y: faker.finance.amount(0, 2, 2) },
          { x: '15:00', y: faker.finance.amount(0, 2, 2) }
        ],
        solarConsumption: [
          { x: '08:00', y: faker.finance.amount(0, 2, 2) },
          { x: '09:00', y: faker.finance.amount(0, 2, 2) },
          { x: '10:00', y: faker.finance.amount(0, 2, 2) },
          { x: '11:00', y: faker.finance.amount(0, 2, 2) },
          { x: '12:00', y: faker.finance.amount(0, 2, 2) },
          { x: '13:00', y: faker.finance.amount(0, 2, 2) },
          { x: '14:00', y: faker.finance.amount(0, 2, 2) },
          { x: '15:00', y: faker.finance.amount(0, 2, 2) }
        ],
        injectedGeneration: [
          { x: '08:00', y: faker.finance.amount(0, 2, 2) },
          { x: '09:00', y: faker.finance.amount(0, 2, 2) },
          { x: '10:00', y: faker.finance.amount(0, 2, 2) },
          { x: '11:00', y: faker.finance.amount(0, 2, 2) },
          { x: '12:00', y: faker.finance.amount(0, 2, 2) },
          { x: '13:00', y: faker.finance.amount(0, 2, 2) },
          { x: '14:00', y: faker.finance.amount(0, 2, 2) },
          { x: '15:00', y: faker.finance.amount(0, 2, 2) }
        ]
      };
    },
    houseComparisons: (_obj, _args, _context) => {
      return {
        neighborhoodComparison: {
          daily: faker.finance.amount(0, 3, 1),
          average: faker.finance.amount(0, 3, 1),
          percentageChange: faker.finance.amount(0, 1, 2),
        },
        cityComparison: {
          daily: faker.finance.amount(0, 3, 1),
          average: faker.finance.amount(0, 3, 1),
          percentageChange: faker.finance.amount(0, 1, 2),
        },
        stateComparison: {
          daily: faker.finance.amount(0, 3, 1),
          average: faker.finance.amount(0, 3, 1),
          percentageChange: faker.finance.amount(0, 1, 2),
        }
      }
    },
    costsProjections: (_obj, _args, _context) => {
      return {
        previousPeriodsPayments: [
          { x: 'Mar-May 2020', y: faker.random.number({ min: 500, max: 5000})},
          { x: 'May-Jul 2020', y: faker.random.number({ min: 500, max: 5000})},
        ],
        nextPaymentAt: new Date().toISOString(),
        nextPaymentTotalCost: faker.random.number({ min: 500, max: 5000}),
        nextPaymentCostToDate: faker.random.number({ min: 500, max: 5000}),
      }
    },
    providerClients: (_obj, { page = 1, perPage = 10, q = {} }) => {
      const startFrom = (page - 1) * perPage;
      let filteredClients = Object.keys(q).length === 0
        ? clients
        : clients.filter(c => {
          const reg = new RegExp(q.search_clients_cont, 'i')
          return reg.test(c.name)
        });
      if (q.filter_by_city_i_cont.length) {
        filteredClients = filteredClients.filter(c => {
          return c.home.city === q.filter_by_city_i_cont
        })
      }
      const sliceOfClients = filteredClients.slice(startFrom, startFrom + perPage)
      const totalCount = filteredClients.length;
      return {
        hasNextPage: totalCount > page * perPage,
        hasPreviousPage: page > 1,
        pagesCount: Math.ceil(totalCount / perPage),
        totalCount: totalCount,
        nodes: sliceOfClients
      };
    },
    states: (_obj, _args, _context) => {
      return states;
    },
    cities: (_obj, _args, _context) => {
      return cities;
    },
    cfeFees: (_obj, _args, _context) => {
      return cfeFees;
    },
    clientInfo: (_obj, _args, _context) => {
      return {
        client: clients[Math.floor(Math.random() * clients.length)],
        home: createHome(),
        cfeHome: createCfeHome(),
        meterGateway: createMeterGateway(),
        cfeInvoices: [createCfeInvoice()],
        meterDevices: [createMeterDevice(), createMeterDevice()]
      }
    },
    gatewayDevices: (_obj, _args, _context) => {
      return [
        {
          id: 1,
          name: 'device 1',
          measureType: 0,
          positive: false,
          note: 'this is a test note',
        },
        {
          id: 2,
          name: 'device 2',
          measureType: 1,
          positive: true,
          note: 'this is also a test note',
        },
        {
          id: 3,
          name: 'device 3',
          measureType: 2,
          positive: null,
          note: 'note',
        }
      ];
    },
    usernameGateway: (_obj, { clientId }, _context) => {
      let gatewayWithDevices = createMeterGateway();
      gatewayWithDevices.meterDevices = [createMeterDevice()];

      return {
        client: parseInt(clientId) === users[1].id ? users[1] : findClientById(Number(clientId)),
        meterGateway: gatewayWithDevices,
        acceptInvitationUrl: 'http://localhost:3001/accept_invitation/invitationToken'
      }
    },
    userFromInvitationToken: (_obj, { invitationToken }, _context) => {
      if(invitationToken === 'invalid') {
        return null;
      }

      return {
        name: faker.name.firstName(),
        email: faker.internet.email(),
        authenticationToken: '3849',
      }
    },
    similarHousesComparison: (_obj, _args, _context) => {
      return {
        averageConsumption: faker.finance.amount(0, 2, 2),
        similarHousesAverageConsumption: faker.finance.amount(0, 2, 2),
        averageYield: faker.finance.amount(0, 2, 2),
        similarHousesAverageYield: faker.finance.amount(0, 2, 2)
      }
    },
    clientByToken: (_obj, { setupToken }, _context) => {
      if(setupToken === 'invalid') {
        return null;
      }

      return {
        client: clients[Math.floor(Math.random() * clients.length)],
        home: createHome(),
        meterGateway: createMeterGateway(),
        cfeInvoices: [createCfeInvoice()],
        meterDevices: [createMeterDevice(), createMeterDevice()]
      }
    },
    gatewayInfo: (_obj, _args, _context) => {
      return {
        meterGateway: createMeterGateway(),
        clientId: faker.random.number()
      }
    },
    currentPeriodCostsProjections: (_obj, _args, _context) => {
      const costsWithPanelsIntersection = faker.random.number({ min: 300, max: 500});
      const costsWithoutPanelsIntersection = faker.random.number({ min: 600, max: 800});

      return {
        totalCostsWithPanels: faker.random.number({ min: 300, max: 500}),
        totalCostsWithoutPanels: faker.random.number({ min: 500, max: 700}),
        totalProjectedCostsWithPanels: faker.random.number({ min: 700, max: 900}),
        totalProjectedCostsWithoutPanels: faker.random.number({ min: 900, max: 1000}),
        nextPaymentWithPanels: faker.random.number({ min: 500, max: 700 }),
        nextPaymentWithoutPanels: faker.random.number({ min: 700, max: 900 }),
        nextPaymentAt: faker.random.number({ min: 500, max: 900 }),
        savingsPercentage: faker.random.number({ min: 50, max: 100 }),
        costsProjectionsByDay: [
          {
            date: '08/09/20',
            costsWithPanels: faker.random.number({ min: 300, max: 500}),
            costsWithoutPanels: faker.random.number({ min: 600, max: 800}),
            projectedCostsWithPanels: null,
            projectedCostsWithoutPanels: null,
          },
          {
            date: '09/09/20',
            costsWithPanels: faker.random.number({ min: 300, max: 500}),
            costsWithoutPanels: faker.random.number({ min: 600, max: 800}),
            projectedCostsWithPanels: null,
            projectedCostsWithoutPanels: null,
          },
          {
            date: '10/09/20',
            costsWithPanels: faker.random.number({ min: 300, max: 500}),
            costsWithoutPanels: faker.random.number({ min: 600, max: 800}),
            projectedCostsWithPanels: null,
            projectedCostsWithoutPanels: null,
          },
          {
            date: '10/09/20',
            costsWithPanels: costsWithPanelsIntersection,
            costsWithoutPanels: costsWithoutPanelsIntersection,
            projectedCostsWithPanels: costsWithPanelsIntersection,
            projectedCostsWithoutPanels: costsWithoutPanelsIntersection
          },
          {
            date: '11/09/20',
            costsWithPanels: null,
            costsWithoutPanels: null,
            projectedCostsWithPanels: faker.random.number({ min: 300, max: 500}),
            projectedCostsWithoutPanels: faker.random.number({ min: 600, max: 800}),
          },
          {
            date: '12/09/20',
            costsWithPanels: null,
            costsWithoutPanels: null,
            projectedCostsWithPanels: faker.random.number({ min: 300, max: 500}),
            projectedCostsWithoutPanels: faker.random.number({ min: 600, max: 800}),
          },
          {
            date: '13/09/20',
            costsWithPanels: null,
            costsWithoutPanels: null,
            projectedCostsWithPanels: faker.random.number({ min: 300, max: 500}),
            projectedCostsWithoutPanels: faker.random.number({ min: 600, max: 800}),
          },
        ]
      }
    },
    zipcodesAutocomplete: (_obj, { matchZipcode, limitResults }, _context) => {
      let zipcodes = clients.map((c) => c.home.zipcode);
      if (matchZipcode.length) {
        const reg = new RegExp(matchZipcode, 'i');
        zipcodes = zipcodes.filter(zcode => reg.test(zcode));
      }
      zipcodes.sort(() => 0.5 - Math.random());
      return zipcodes.slice(0, limitResults);
    },
    savingsWithPanels: (_obj, _args, _context) => {
      return {
        nextPaymentToDate: faker.random.number({ min: 500, max: 5000}),
        nextPaymentToDateWithNoPanels: faker.random.number({ min: 500, max: 5000}),
        nextPaymentAt: new Date().toISOString(),
        savingsPercentage: faker.finance.amount(0, 2, 2)
      }
    },
    gatewayLogs: (_obj, _args, _context) => {
      const logs = [
        {
          id: 1,
          changesMadeBy: { email: 'test@provider.com' },
          action: "create",
          auditedChanges: {
            active: false,
            status: 1
          },
          createdAt: "2020-11-06T13:01:16-06:00"
        },
        {
          id: 2,
          changesMadeBy: { email: 'test@provider.com' },
          action: "update",
          auditedChanges: {
            active: [false, true]
          },
          createdAt: "2020-11-25T15:52:57-06:00"
        },
        {
          id: 3,
          changesMadeBy: { email: 'test@provider.com' },
          action: "update",
          auditedChanges: {
            active: [true, false],
            status: [1, 2]
          },
          createdAt: "2020-11-25T15:57:37-06:00"
        }
      ]

      return {
        meterGateway: createMeterGateway(),
        logs: logs
      }
    },
    gatewayStatus: (_obj, { meterGateways }, _context) => {
      return meterGateways.map(gateway => ({ gatewayId: gateway, readingsAlert: faker.random.boolean() }));
    },
    solarSystemsPerMonth: (_obj, _args, _context) => {
      return [
        { solarSystems: faker.random.number({ min: 7, max: 17}), month: 'Mayo 2020'},
        { solarSystems: faker.random.number({ min: 10, max: 20}), month: 'Jun 2020'},
        { solarSystems: faker.random.number({ min: 20, max: 30}), month: 'Jul 2020'},
        { solarSystems: faker.random.number({ min: 14, max: 24}), month: 'Ago 2020'},
        { solarSystems: faker.random.number({ min: 1, max: 8}), month: 'Sep 2020'},
        { solarSystems: faker.random.number({ min: 4, max: 10}), month: 'Oct 2020'},
        { solarSystems: faker.random.number({ min: 1, max: 5}), month: 'Nov 2020'},
        { solarSystems: faker.random.number({ min: 1, max: 2}), month: 'Dic 2020'},
        { solarSystems: faker.random.number({ min: 30, max: 40}), month: 'Ene 2021'},
        { solarSystems: faker.random.number({ min: 1, max: 5}), month: 'Feb 2021'},
        { solarSystems: faker.random.number({ min: 8, max: 18}), month: 'Mar 2021'},
        { solarSystems: faker.random.number({ min: 3, max: 10}), month: 'Abr 2021'}
      ]
    },
    monthlyKwhGeneration: (_obj, _args, _context) => {
      return [
        { kwhGeneration: faker.random.number({ min: 7, max: 17}), month: 'Mayo 2020'},
        { kwhGeneration: faker.random.number({ min: 10, max: 20}), month: 'Jun 2020'},
        { kwhGeneration: faker.random.number({ min: 20, max: 30}), month: 'Jul 2020'},
        { kwhGeneration: faker.random.number({ min: 14, max: 24}), month: 'Ago 2020'},
        { kwhGeneration: faker.random.number({ min: 1, max: 8}), month: 'Sep 2020'},
        { kwhGeneration: faker.random.number({ min: 4, max: 10}), month: 'Oct 2020'},
        { kwhGeneration: faker.random.number({ min: 1, max: 5}), month: 'Nov 2020'},
        { kwhGeneration: faker.random.number({ min: 1, max: 2}), month: 'Dic 2020'},
        { kwhGeneration: faker.random.number({ min: 30, max: 40}), month: 'Ene 2021'},
        { kwhGeneration: faker.random.number({ min: 1, max: 5}), month: 'Feb 2021'},
        { kwhGeneration: faker.random.number({ min: 8, max: 18}), month: 'Mar 2021'},
        { kwhGeneration: faker.random.number({ min: 3, max: 10}), month: 'Abr 2021'}
      ]
    },
    providerSummary: (_obj, _args, _context) => {
      return {
        energyGeneration: faker.random.number({ min: 500, max: 50000}),
        energySavings: faker.random.number({ min: 500, max: 50000}),
        solarSystems: faker.random.number({ min: 300, max: 2000}),
        solarSystemsKw: faker.random.number({ min: 1000, max: 50000}),
        tonsCo2Avoided: faker.random.number({ min: 10, max: 100})
      }
    },
    clientsConsumptions: (_obj, { userIds }, _context) => {
      return userIds.map( userId => (
        {
          userId: userId,
          kwhSolarCost: faker.finance.amount(0, 2, 2),
          kwhCfeCost: faker.finance.amount(0, 2, 2),
          savings: faker.finance.amount(0, 2, 2)
        }
      ))
    },
    providerChannels: (_obj, _args, _context) => {
      return providerChannels;
    },
  },
  Mutation: {
    login: (_obj, { input }) => {
      const user = findUserByEmail(input.email);

      if (!user) {
        return new Error('Failed login.');
      }

      return {
        user: {
          id: user.id,
          name: user.name,
          email: input.email,
          authenticationToken: user.authenticationToken,
          authorityLevel: user.authorityLevel,
        },
      };
    },
    createClientMutation: (_obj, { input }) => {
      return {
        client: {
          id: 10001,
          email: input.clientAttributes.email,
          name: input.clientAttributes.name,
        },
      };
    },
    updateClientMutation: (_obj, { input }) => {
      return {
        client: {
          id: 1002,
          email: input.clientAttributes.email,
          name: input.clientAttributes.name,
        },
      };
    },
    activateGatewayMutation: (_obj, { input }) => {
      return {
        gateway: {
          id: input.gatewayId,
          active: input.active
        }
      }
    },
    acceptInvitationMutation: (_obj, _args) => {
      return {
        user: {
          name: faker.name.firstName(),
          email: faker.internet.email()
        }
      }
    },
    installationSetup: (_obj, _args) => {
      return {
        meterGateway: {
          id: 99,
          name: faker.name.firstName()
        }
      }
    },
    createClientFromHubspotMutation: (_obj, _args) => {
      return {
        client: {
          id: 1000,
        }
      }
    },
    meterGatewayValidation: (_obj, _args) => {
      return {
        validated: true
      }
    },
    deleteClient: (_obj, args) => {
      return {
        client: {
          name: faker.name.firstName(),
          email: faker.internet.email(),
        }
      }
    }
  }
};
