import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { DateForm, InputDateFormGroup } from '../forms';

const DatesRangeForm = ({
  title,
  labelNames,
  minInputDate,
  inputValues,
  inputNames,
  setFn,
  testIdValues,
  colResponsiveValue,
  controlIdStart='liveFormStartDate',
  controlIdEnd='liveFormEndDate',
}) => {

  if ( inputValues.startDate !== '' && inputValues.endDate !== '' && inputValues.startDate > inputValues.endDate) {
    setFn((prevState) => {
      return {
        ...prevState,
        endDate: '',
      };
    });
  }

  const getMinEndDate = (startDate) => {
    if (startDate === '') {
      return '';
    }

    let minEndDate = new Date(startDate);
    minEndDate.setDate(minEndDate.getDate() + 1);
    return dayjs(minEndDate).format('YYYY-MM-DD');
  };
  
  const minInputValue = useMemo(() => dayjs(minInputDate).format('YYYY-MM-DD'), []);
  const maxInputValue = useMemo(() => dayjs(new Date()).format('YYYY-MM-DD'), []);
  return (
    <DateForm title={title} colResponsiveValue={colResponsiveValue}>
      <InputDateFormGroup
        labelName={labelNames.startDate}
        controlId={controlIdStart}
        inputName={inputNames.startDate}
        minInputValue={minInputValue}
        maxInputValue={maxInputValue}
        inputValue={inputValues.startDate}
        setFn={setFn}
        testIdValue={testIdValues.startDate}
      />
      <InputDateFormGroup
        labelName={labelNames.endDate}
        controlId={controlIdEnd}
        inputName={inputNames.endDate}
        minInputValue={getMinEndDate(inputValues.startDate)}
        maxInputValue={maxInputValue}
        inputValue={inputValues.endDate}
        setFn={setFn}
        testIdValue={testIdValues.endDate}
      />
    </DateForm>
  );
};

export default DatesRangeForm;