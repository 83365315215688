import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import ACCEPT_INVITATION from '../../../../graphql/mutations/acceptInvitation';
import INVITE_CLIENT from '../../../../graphql/mutations/inviteClient';
import USERNAME_GATEWAY from '../../../../graphql/queries/username_gateway';
import ROUTES from '../../../../routing/routes';
import Loader from '../../../shared/Loader/Loader';

function AcceptInvitationModal({ clientId, show, handleClose, invitationUrl = '', resetInvitation = () => {} }) {
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const MUTATION_OPTIONS = {
    errorPolicy: 'all',
    refetchQueries: [
      { query: USERNAME_GATEWAY, variables: { clientId: clientId } }
    ]
  }
  const [acceptInvitation, { loading: loadingAcceptMutation }] = useMutation(ACCEPT_INVITATION, MUTATION_OPTIONS);
  const [reinvite, { loading: loadingReinviteMutation }] = useMutation(INVITE_CLIENT, MUTATION_OPTIONS);

  const tokenFromUrl = (url) => {
    return url.split(ROUTES[3].constructor)[1];
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const password = form.elements.password.value;
      if (invitationUrl.length) {
        const token = tokenFromUrl(invitationUrl);
        triggerAccept(token, password);
      } else {
        triggerReinvitationForAccept(password);
      }
    }
  }

  const triggerReinvitationForAccept = (password) => {
    reinvite({ variables: { clientId: clientId, skipInvitationDelivery: true } })
      .then((res) => {
        const token = tokenFromUrl(res.data.inviteClientMutation.invitationUrl);
        triggerAccept(token, password);
      })
      .catch((_err) => {
        setErrorMsg('Ocurrió un error, intenta de nuevo más tarde.');
      })
  }

  const triggerAccept = (invitationToken, password) => {
    acceptInvitation({ variables: { invitationToken: invitationToken, password: password } })
      .then((res) => {
        const email = res.data.acceptInvitationMutation.user.email;
        setSuccessMsg(`Contraseña creada para ${email}.`);
        setErrorMsg('');
      })
      .catch((_err) => {
        setErrorMsg('Ocurrió un error, intenta de nuevo más tarde.');
      })
  }
  
  if (loadingReinviteMutation || loadingAcceptMutation) {
    return (
      <Modal show={show} onHide={handleClose} backdrop={false}>
        <Modal.Header closeButton>
          <Modal.Title>Aceptar Invitación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop={false}>
      <Modal.Header closeButton>
        <Modal.Title>Aceptar Invitación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Opciones de crear cuenta: </h5>
        <p className="mt-3">1. Enviar un nuevo correo electrónico con la invitación:</p>
        <Button
            variant="primary"
            className="w-100"
            disabled={loadingReinviteMutation || loadingAcceptMutation}
            onClick={resetInvitation}>
          Enviar Invitación
        </Button>
        {invitationUrl.length > 0 &&
          <div>
            <p className="mt-3">2. Copiar el link, para compartirlo con el cliente final vía SMS, WhatsApp, etc.</p>
            <p className="text-break"> <a href={invitationUrl} target="_blank" rel="noopener noreferrer">{invitationUrl}</a> </p>
          </div>
        }
        <p className="mt-3">{invitationUrl.length > 0 ? '3.' : '2.'} Seleccionar una contraseña, ingresarla en el portal de eMeter y terminar su registro por el cliente final.</p>
        {errorMsg.length > 0 && <Alert variant="danger">{errorMsg}</Alert>}
        {successMsg.length > 0 && <Alert variant="success">{successMsg}</Alert>}
        <Form
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          role="form" >
          <Form.Group controlId="client-password">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              required
              pattern="^(?=.).{6,168}$"
              type="password"
              placeholder="Contraseña"
              name="password"
              data-testid="password-form-input"
            />
            <Form.Control.Feedback type='invalid'>
              Ingrese mínimo 6 caracteres.
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="w-100"
            disabled={loadingReinviteMutation || loadingAcceptMutation}
          >
            Configurar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AcceptInvitationModal
