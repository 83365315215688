import React from 'react';
import { Card } from 'react-bootstrap';

import Loader from '../../shared/Loader/Loader';

function SummaryCard({loading, title, subtitle, footer}) {
  return (
    <Card
      className="d-flex flex-column justify-content-center mt-4 dark-gradient-bg card-summary text-center"
      aria-label="summary-card"
    >
      {loading && <Loader message="Cargando..." />}
      {!loading && (
        <Card.Body>
          <Card.Title aria-label="card-title">{title}</Card.Title>
          {subtitle === "" ? null : 
            <Card.Subtitle aria-label="card-subtitle" className="mt-3">
              {subtitle}&nbsp;
            </Card.Subtitle >
          }
          <Card.Text aria-label="card-footer">{footer}</Card.Text>
        </Card.Body>
      )}
    </Card>
  )
}

export default SummaryCard;
