
import React from 'react';
import { Table, Modal, ListGroup } from 'react-bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Loader from '../../shared/Loader/Loader';

dayjs.extend(relativeTime);
dayjs.locale('es');

const TRANSFORMABLE_ATTRIBUTES = {
  'active': {
    'true': 'Si',
    'false': 'No'
  },
  'status': {
    '1': 'Inválido',
    '2': 'Válido'
  }
}

const ATTRIBUTES_NAMES_TRANSLATIONS = {
  'status': 'Estatus',
  'active': 'Activo',
  'name': 'Nombre',
  'key': 'Llave',
  'user_id': 'ID Usuario'
}

function GatewayLogsModal({ show, handleClose, loading, logs, meterGateway }) {
  const transformValue = (attributeName, attributeValue) => {
    if (attributeValue === null) {
      return 'Ninguno';
    }

    if(attributeName in TRANSFORMABLE_ATTRIBUTES) {
      return TRANSFORMABLE_ATTRIBUTES[attributeName][attributeValue.toString()];
    }

    return attributeValue;
  }

  const parseLogChanges = (attribute, changes, isBefore) => {
    let displayedValue = transformValue(attribute, changes[isBefore ? 0 : 1]);
    return `${ATTRIBUTES_NAMES_TRANSLATIONS[attribute]} - ${displayedValue}`;
  }

  if (loading) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Historial de cambios del gateway ...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal show={show} onHide={handleClose} size='xl'>
      <Modal.Header closeButton>
      <Modal.Title>Historial de cambios del gateway {meterGateway?.name || ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table hover responsive>
          <thead>
            <tr>
              <th>Antes del cambio</th>
              <th>Después del cambio</th>
              <th>Hecho por</th>
              <th>Fecha del cambio</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, _index) => {
              if (log.action === 'create') {
                return (
                  <tr key={log.id}>
                    <td>Ninguno</td>
                    <td>Se creó el gateway</td>
                    <td>{log.changesMadeBy?.email}</td>
                    <td>{dayjs(log.createdAt, "YYYY-MM-DDTHH:mm:ss-Z").format('YYYY-MM-DD HH:mm:ss')}</td>
                  </tr>
                )
              }
              const beforeChanges = Object.entries(log.auditedChanges).map((attributeWithChanges, index) => {
                return <ListGroup.Item key={index} className="text-nowrap">{parseLogChanges(attributeWithChanges[0], attributeWithChanges[1], true)}</ListGroup.Item>
              })
              const afterChanges = Object.entries(log.auditedChanges).map((attributeWithChanges, index) => {
                return <ListGroup.Item key={index} className="text-nowrap">{parseLogChanges(attributeWithChanges[0], attributeWithChanges[1], false)}</ListGroup.Item>
              })

              return (
                <tr key={log.id}>
                  <td><ListGroup>{beforeChanges}</ListGroup></td>
                  <td><ListGroup>{afterChanges}</ListGroup></td>
                  <td>{log.changesMadeBy?.email}</td>
                  <td>{dayjs(log.createdAt, "YYYY-MM-DDTHH:mm:ss-Z").format('YYYY-MM-DD HH:mm:ss')}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default GatewayLogsModal;
