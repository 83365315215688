import { useQuery } from '@apollo/react-hooks';

import SOLAR_SYSTEMS_PER_MONTH from '../../../../graphql/queries/solar_systems_operating_per_month';

const useSolarSystemsPerMonth = (prevDate, currentDate, filters) => {
  const { data, loading } = useQuery(SOLAR_SYSTEMS_PER_MONTH, {
    variables: {
      q: { ...filters },
      start_date: prevDate,
      end_date: currentDate
    }
  });
  return {
    loading,
    data: data?.solarSystemsPerMonth,
    length: data?.solarSystemsPerMonth?.length,
    graphData: {
      x: {
        dataKey: 'month',
        name: 'Fecha'
      },
      y: {
        dataKey: 'solarSystems',
        name: 'Sistemas solares'
      }
    }
  }
}

export default useSolarSystemsPerMonth;
