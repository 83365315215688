import React from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';

import { handleGeneralInputChange } from '../../../../services/inputs_service/inputs_service';

export const DateForm = ({ title, colResponsiveValue, children, extraRowClasses='' }) => {
  const displayChild = (child, index, title, colResponsiveValue) => {
    return (
      <Col md={colResponsiveValue} key={`${title}${index}`}>
        {child}
      </Col>
    );
  };

  const displayContent = (children) => {
    if (children.length > 0) {
      return (
        children.map((child, index) => {
          return (
            displayChild(child, index, title, colResponsiveValue)
          );
        })
      )
    } else {
      return displayChild(children, 0, title, colResponsiveValue)
    }
  }

  return (
    <Container fluid className="pt-3">
      <Form data-testid="today-form">
        <h5 className="text-secondary">{title}</h5>
        <Row className={extraRowClasses}>
          {displayContent(children)}
        </Row>
      </Form>
    </Container>
  );
};

export const InputDateFormGroup = ({
  labelName,
  controlId,
  inputName,
  minInputValue,
  maxInputValue,
  inputValue,
  setFn,
  testIdValue,
  inputType = 'date',
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="text-secondary">
        {labelName}
      </Form.Label>
      <Form.Control
        type={inputType}
        lang="es"
        name={inputName}
        min={minInputValue}
        max={maxInputValue}
        value={inputValue}
        onChange={(e) => {
          handleGeneralInputChange(e, setFn, 'string');
        }}
        data-testid={testIdValue}
        className="cyan-form"
      />
    </Form.Group>
  );
};
