import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import ROUTES from './routes'
import UserContext from '../contexts/UserContext/UserContext'

// This might change when graphql gets integrated. It also depends on the authorization method used on backend.
const PrivateRoute = ({ component: Component, roleLevel, ...rest }) => {
  const userContext = useContext(UserContext);
  const user = userContext.user;

  return (
    <Route {...rest} render={ props => {
        // there is no user logged in
        if (!user) {
          return <Redirect to={{ pathname: ROUTES[0].path, state: { from: props.location } }} />
        }

        // the user does not have permission to see this page
        if (roleLevel > user.authorityLevel) {
          return <Redirect to={{ pathname: ROUTES[0].path, state: { from: props.location } }} />
        }

        // authenticated and authorized
        return <Component {...props}/>;
      }}
    />
  )
}

export default PrivateRoute;
