import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es'

dayjs.extend(utc);
dayjs.locale('es');

const ConsumptionConfigTooltip = ({ payload, active, label, chartConfigs={} }) => {
  const dataInfo = payload ? (payload[0] !== undefined ? payload[0] : null) : null;
  const unit = dataInfo?.unit ?? null;
  let formattedDate;
  let total = 0;
  if (active && payload?.length && Object.keys(chartConfigs).length > 0) {
    if (label.match(/\d{2}\/\d{2}\/\d{4}/)) {
      
      formattedDate = dayjs(label, 'DD/MM/YYYY').format('D [de] MMMM, YYYY');
    } else if (label.match(/[A-Za-z]{3,9} \d{4}/)) {
      formattedDate = dayjs(label, 'MMMM YYYY').format('MMMM YYYY');
    } else {
      formattedDate = label;
    }
    return (
      <div className="custom-tooltip p-2" data-testid="consumptionConfigTooltip">
        <p className="label text-secondary">{`${formattedDate}`}</p>
        {payload.map((data, index) => {
          total += data.value;
          return (
              <p className={`${chartConfigs[data.dataKey].tooltipClass} label`} key={index}>
                {data.name}: {data.value.toLocaleString()} {unit}
              </p>
          )
        })}
        <p className={`text-secondary label`}>
                Total: {total.toLocaleString()} {unit}
              </p>
      </div>
    );
  }

  return null;
};

export default ConsumptionConfigTooltip;
