import { gql } from 'apollo-boost';

const CREATE_CLIENT_FROM_HUBSPOT = gql`
  mutation createClientFromHubspotMutation($hubspotDealId: String!) {
    createClientFromHubspotMutation(input: { hubspotDealId: $hubspotDealId }) {
      client {
        id
      }
    }
  }
`;

export default CREATE_CLIENT_FROM_HUBSPOT;