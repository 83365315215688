export const I_ICON_MESSAGES = {
  'dailyConsumption': {
    'header': 'Consumo diario',
    'message': `
    Consumo neto* promedio** del día de ayer (00:01-24:00hrs.) [kWh]%
    *Neto = kWh CFE consumidos - kWh solar inyectados a CFE.%
    **Promedio de los días transcurridos entre el día de inicio del periodo de facturación CFE en curso y el día de ayer.
    `
  },
  'yield': {
     'header': 'Yield',
     'message': 'Generación diaria / kWp'
  },
  'kwhGenYesterday': {
    'header': 'Generación diaria',
    'message': 'kWh solares generados durante el día de ayer (00:01-24:00hrs.)'
  },
  'statusGreenMeter': {
    'header': 'Greenmeter',
    'message': 'Estatus del Greenmeter'
  },
  'statusSFV': {
    'header': 'SFV',
    'message': 'Estatus de SFV'
  },
  'costsBreakdown': {
    'header': 'Consumo vs Costos',
    'message': 'Comparación de tu consumos en las diferentes tarifas horarias, contra el costo en las diferentes tarifas horarias.'
  },
  'costs': {
    'header': 'Tu pago CFE',
    'message': 'Costos estimados para el periodo de facturación CFE en curso.'
  },
  'savings': {
    'header': 'Tu ahorro solar',
    'message': 'Ahorros estimados a reflejarse en tu próximo recibo CFE.'
  },
  'totalConsumption': {
     'header': '',
     'message': 'Incluye consumo de la red de CFE y consumo solar.'
  },
  'consumptionBySource': {
    'header': 'Consumo por Fuente de ayer',
    'message': 'Cuanto consumo viene de CFE y cuanto viene de tu sistema solar.'
  },
  'demandChart': {
    'header': '',
    'message': 'Esta demanda es la que mide la CFE actualmente, la demanda operativa suma la demanda del sistema fotovoltáico si es que hay uno conectado.'
  },
  'operativeChart': {
    'amps': 'Amperes',
    'volts': 'Voltios',
    'watts': 'Watts',
    'pf': 'Factor de Potencia'
  }
};

export const ALERT_ICON_MESSAGES = {
  'yield': {
    'header': 'Alerta',
    'message': 'Este yield está abajo por 30% o más del promedio de hogares en la misma ciudad.'
  }
}
