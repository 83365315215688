import { gql } from 'apollo-boost';

const CURRENT_PERIOD_COSTS_PROJECTIONS = gql`
  query currentPeriodCostsProjections($clientId: ID!, $dateFormat: String, $period: Int) {
    currentPeriodCostsProjections(
      clientId: $clientId
      dateFormat: $dateFormat
      period: $period
    ) {
      totalCostsWithPanels
      totalCostsWithoutPanels
      totalProjectedCostsWithPanels
      totalProjectedCostsWithoutPanels
      nextPaymentWithPanels
      nextPaymentWithoutPanels
      nextPaymentAt
      savingsPercentage
      costsProjectionsByDay {
        date
        costsWithPanels
        costsWithoutPanels
        projectedCostsWithPanels
        projectedCostsWithoutPanels
      }
    }
  }
`;

export default CURRENT_PERIOD_COSTS_PROJECTIONS;