import React from 'react';
import { Toast } from 'react-bootstrap';

//TODO: replace Toast with ConfirmationToast in other components.
const ConfirmationToast = ({
  header,
  message,
  isSuccess,
  showToast,
  setShowToast,
  onClose = () => null,
}) => {
  return (
    <Toast
      show={showToast}
      onClose={() => {
        setShowToast(false);
        onClose();
      }}
      variant={isSuccess ? 'success' : 'danger'}
      delay={8000}
      autohide
      className="create-toast position-fixed"
      data-testid="confirmation-toast"
    >
      <Toast.Header className={isSuccess ? 'bg-success' : 'bg-danger'}>
        <img src="holder.js/20x20?text=%20" className="mr-2" alt="" />
        <strong className="mr-auto">{header}</strong>
      </Toast.Header>
      <Toast.Body className="p-3 text-wrap" data-testid="confirmation-message">
        <div>{message}</div>
      </Toast.Body>
    </Toast>
  );
};

export default ConfirmationToast;
