import React, { useState, useEffect } from 'react';
import { Table, Button, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import ClientSort from '../Clients/ClientSort';
import ROUTES from '../../../routing/routes';
import Loader from '../../shared/Loader/Loader';
import { getDisplayValue } from '../../shared/sharedFunctions/sharedFunctions';
import InfoMessage from '../../shared/InfoMessage/InfoMessage';
import { I_ICON_MESSAGES, ALERT_ICON_MESSAGES } from '../../shared/InfoMessage/messages';
import GATEWAY_STATUS from '../../../graphql/queries/gateway_status';
import CLIENTS_CONSUMPTIONS from '../../../graphql/queries/clients_consumptions';

import GatewayStatus from './GatewayStatus/GatewayStatus';

function ClientsTable({
  clients,
  loading,
  sortingColumns=[],
  sortConfig,
  handleReorder,
}) {
  const history = useHistory();

  //Botón de cliente
  const showClientDashboard = (event) => {
    event.persist();
    event.stopPropagation();
    const clientId = event.target.closest('tr').dataset.id;
    history.push(ROUTES[1].routes[1].constructor + clientId);
  };

  // Editar cliente al picar tres puntos
  const showEditClient = (event) => {
    event.persist();
    event.stopPropagation();
    const clientId = event.target.closest('tr').dataset.id;
    history.push(ROUTES[1].routes[3].constructor + clientId);
  };

  //mensaje
  const dailyConsumptionInfoMessage = (
    <div>
      {I_ICON_MESSAGES.dailyConsumption.message.split('%').map((msg, index) => {
        return <p key={index}>{msg}</p>
      })}
    </div>
  );

  //Gatewaystatus
  const { data: gatewayStatusesData, loading: loadingGatewayStatuses } = useQuery(GATEWAY_STATUS, {
    variables: { meterGateways: clients.map(client => Number(client?.meterGateway?.id)) },
  });
  const [gatewayStatuses, setGatewayStatuses] = useState([]);

  useEffect(() => {
    if (gatewayStatusesData) {
      setGatewayStatuses(gatewayStatusesData.gatewayStatus)
    }
  }, [gatewayStatusesData])

  const { data: clientsConsumptionData } = useQuery(CLIENTS_CONSUMPTIONS, {
    variables: { userIds: clients.map(client => Number(client?.id)) }
  })
  const [clientsConsumptions, setClientsConsumptions] = useState([])

  useEffect(() => {
    if (clientsConsumptionData) {
      setClientsConsumptions(clientsConsumptionData.clientsConsumptions)
    }
  }, [clientsConsumptionData]);

  return (
    <Table hover responsive data-testid="table" className='client-table pl'>
      <thead>
        <tr>
          <ColumnHeader
            title="Nombre"
            sortingColumnIndex={0}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
          />

          {/* <ColumnHeader
            title="Estado"
            sortingColumnIndex={1}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
          /> */}

         {/* <ColumnHeader
            title="C.P."
            sortingColumnIndex={2}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
          /> */}

          <ColumnHeader
            title="Consumo"
            sortingColumnIndex={1}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
            tooltipHeader={I_ICON_MESSAGES.dailyConsumption.header}
            tooltipMessage={dailyConsumptionInfoMessage}
            data-testid="columnWithTooltip"
          />

          <ColumnHeader
            title="Generación"
            sortingColumnIndex={2}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
            tooltipHeader={I_ICON_MESSAGES.kwhGenYesterday.header}
            tooltipMessage={I_ICON_MESSAGES.kwhGenYesterday.message}
            data-testid="columnWithTooltip"
          />

          <ColumnHeader
            title="Yield"
            sortingColumnIndex={3}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
            tooltipHeader={I_ICON_MESSAGES.yield.header}
            tooltipMessage={I_ICON_MESSAGES.yield.message}
            data-testid="columnWithTooltip"
          />

          <ColumnHeader
            title="kWp"
            sortingColumnIndex={4}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
          />

          <ColumnHeader
            title="Interconectado"
            sortingColumnIndex={5}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
          />

 {/*          
          <ColumnHeader
            title="Estatus Greenmeter"
            sortingColumnIndex={6}
            sortingColumns={sortingColumns}
            handleReorder={handleReorder}
            sortConfig={sortConfig}
            isStatusColumn={true}
          />
          

          <th className=" px-1">
            <p className="text-nowrap p-0 m-0 mr-3">Estatus Greenmeter</p>
          </th>

          <th className="0 px-1">
            <p className="text-nowrap p-0 m-0 mr-3">Precio/kWh CFE</p>
          </th>

          <th className=" px-1">
            <p className="text-nowrap p-0 m-0 mr-3">Precio/kWh Galt</p>
          </th>

          <th className=" px-1">
            <p className="text-nowrap p-0 m-0 mr-3">Ahorros</p>
          </th> */}

          <th className="px-1"></th>
          <th className="px-1"></th>
        </tr>
      </thead>
      <tbody className="text-secondary">
        {loading ? (
          <tr className="text-center">
            <td colSpan={11}>
              <Loader />
            </td>
          </tr>
        ) : clients.length ? (
          clients.map((client) => {
            return (
              <tr 
                data-testid="tr-click-row" 
                data-id={client.id} 
                data-name={client.name} 
                key={client.id} 
                className="client-data-wrapper" 
                role="button" 
                onClick={showClientDashboard}>

                <td className="text-center align-middle">
                   {getDisplayValue(client?.name)}
                </td>
               
                {/* <td className="text-nowrap">
                  {getDisplayValue(client?.home?.state?.cfeShortName)}
                </td> */}
                {/* <td className="text-nowrap">
                  {getDisplayValue(client?.home?.zipcode)}
                </td> */}
                <td className="text-center align-middle">
                  {getDisplayValue(client?.meterGateway?.currentPeriodAvgKwhConsumption)}
                </td>
                <td className="text-center  align-middle">
                  {getDisplayValue(client?.meterGateway?.kwhGenYesterday)}
                </td>
                <td className="text-center  align-middle">
                  {getDisplayValue(client?.meterGateway?.yieldGen)}
                  {client?.meterGateway?.lowYieldAlert &&
                    <InfoMessage
                      icon="exclamation-triangle"
                      header={ALERT_ICON_MESSAGES.yield.header}
                      message={ALERT_ICON_MESSAGES.yield.message}
                      placement="left"
                      buttonClassNames={{'margin': 'ml-1 mb-1', 'color': 'text-red'}}/>
                  }
                </td>
                <td className="text-center  align-middle">
                  {getDisplayValue(client?.cfeHome?.installedKw)}
                </td>
                <td className="text-center align-middle">
                  <OverlayTrigger
                    placement='top'
                    overlay={client?.cfeHome?.cfeInterconnectionAt
                      ? <Tooltip><b> Conectado</b> </Tooltip>
                      : <Tooltip><b> Desconectado</b> </Tooltip>
                    }
                  >
                    {client?.cfeHome?.cfeInterconnectionAt
                      ? <Badge pill variant="green">  </Badge>
                      : <Badge pill variant="red">  </Badge>
                    }
                  </OverlayTrigger>
                </td>
                {/* 
                <td onClick={openLogsModal} className="text-center">
                  <OverlayTrigger
                    placement='top'
                    overlay={client?.meterGateway.active
                      ? <Tooltip><b>Conectado</b></Tooltip>
                      : <Tooltip><b>Desconectado</b></Tooltip>
                    }
                  >
                  
                    {client?.meterGateway?.active
                      ? <Badge pill variant="blue" role='button'>  </Badge>
                      : <Badge pill variant="red" role='button'>  </Badge>
                    }
                  </OverlayTrigger>
                </td>
               
                <td className="text-center align-middle">
                  {loadingGatewayStatuses
                    ? <Loader />
                    : <GatewayStatus active={gatewayStatuses.find(element => element.gatewayId === client?.meterGateway?.id)?.readingsAlert} />
                  }
                </td>
                <td className="text-center">
                  {loadingClientsConsumptions
                    ? <Loader />
                    : getDisplayValue(clientsConsumptions.find(element => element.userId === client?.id)?.kwhCfeCost)
                  }
                </td>
                <td className="text-center">
                  {loadingClientsConsumptions
                    ? <Loader />
                    : getDisplayValue(clientsConsumptions.find(element => element.userId === client?.id)?.kwhSolarCost)
                  }
                </td>
                <td className="text-center">
                  {loadingClientsConsumptions
                    ? <Loader />
                    : getDisplayValue(clientsConsumptions.find(element => element.userId === client?.id)?.savings)
                  }
                </td> */}
                
                <td className="text-center align-middle mt-3 pr-4">
                  <Button
                    variant="font-weigth-normal"
                    size="sm"
                    onClick={showEditClient}
                    data-testid="client-list-edit"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <p className='text-secondary more-info'>...</p>
                  </Button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className="text-center text-muted">
            <td colSpan={11} data-testid="noAvailableClientsMessage" className="text-center">No hay clientes disponibles.</td>
          </tr>
        )}
      </tbody>
    </Table>
)}


const ColumnHeader = ({
  title,
  sortingColumnIndex,
  tooltipHeader = '',
  tooltipMessage='',
  sortingColumns,
  sortConfig,
  handleReorder,
  isStatusColumn = false,
  ...props
}) => {
  const hasTooltip = tooltipHeader.length > 0;

  return (
    <th className={`${isStatusColumn ? 'px-1' : 'px-2'} `} {...props}>
      <div className="d-flex justify-content-center px-3">
        <p className="text-nowrap p-0 m-0 mr-3 text-center text-secondary font-weight-light ">
          {title} {hasTooltip && <InfoMessage header={tooltipHeader} message={tooltipMessage}/>}
        </p>
        <ClientSort
          sortingByColumn={sortingColumns.length ? sortingColumns[sortingColumnIndex]: {}}
          sortConfig={sortConfig}
          handleReorder={handleReorder}
        />
      </div>
    </th>
  )
}

export default ClientsTable
