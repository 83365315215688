import React, { useContext } from 'react';
import UserContext from '../../../contexts/UserContext/UserContext';
import Dashboard from '../../Dashboard/Dashboard';

const MyDashboard = () => {
  const userContext = useContext(UserContext);
  const clientId = userContext.user.id;

  return <Dashboard clientId={Number(clientId)} canEdit={true} />;
}

export default MyDashboard;
