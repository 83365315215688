import ROLES from '../../routing/roles';
import ROUTES from '../../routing/routes'

export function manageAuth(user, history) {
  if (user.authorityLevel === ROLES['user']) {
    // client
    history.push(ROUTES[2].path);
  } else {
    // provider or galt admin
    history.push(ROUTES[1].path);
  }
}

export function saveAuthToken(token) {
  localStorage.setItem('token', token);
}
