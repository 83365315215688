import { gql } from "apollo-boost";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(
      input: {
        email: $email,
        password: $password
      }
    ) {
      user {
        id
        name
        email
        authenticationToken
        authorityLevel
      }
    }
  }
`;

export default LOGIN;
