import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ServiceStatusDot = ({ status }) => {
  const getStatusText = () => {
    return status ? 'Active' : 'Inactive';
  };

  const renderTooltip = (status) => (
    <Tooltip id="dot-tooltip">
      {status ? "Status FV Activo" : "Status FV Inactivo"}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(status)}
    >
        <div className={`status-dot ${status ? 'active' : 'inactive'}`} title={`Sistema FV ${getStatusText()}`}>
        </div>
    </OverlayTrigger>
  );
};

export default ServiceStatusDot;