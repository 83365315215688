import { gql } from "apollo-boost";

const GATEWAY_ACTIVATION = gql`
  mutation activateGatewayMutation($gatewayId: ID!, $active: Boolean!) {
    activateGatewayMutation(
      input: {
        gatewayId: $gatewayId,
        active: $active
      }
    ) {
      gateway {
        id
        active
      }
    }
  }
`;

export default GATEWAY_ACTIVATION ;