import { gql } from 'apollo-boost';

const CLIENT_INFO = gql`
  query clientInfo($clientId: ID!) {
    clientInfo(clientId: $clientId) {
      client {
        id
        name
        email
        setupToken
        channel
        customId
      }
      home {
        street
        externalNumber
        neighborhood
        zipcode
        city
        stateId
        latitude
        longitude
      }
      cfeHome {
        cfeRpu
        cfeName
        cfeFeeId
        installedKw
        cfeInterconnectionAt
      }
      meterGateways {
        name
        key
      }
      cfeInvoices {
        id
        periodStartDate
        periodEndDate
        consumption
        generation
      }
      meterDevices {
        id
        name
        measureType
        positive
        note
      }
      inverters {
        id
        inverterBrandId
        sn
      }
    }
  }
`;

export default CLIENT_INFO;
