import React, { useState, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useHistory } from "react-router-dom";
import TagManager from 'react-gtm-module'

import ROUTES from './routing/routes';
import faLibrary from './_setup/faLibrary';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './routing/PrivateRoute';
import LoginPage from './components/LoginPage/LoginPage';
import ProviderLayout from './components/Provider/ProviderLayout';
import ClientLayout from './components/Client/ClientLayout';
import UserContext from './contexts/UserContext/UserContext';
import Loader from './components/shared/Loader/Loader';
import USER_INFO from './graphql/queries/user_info';
import ClientInvitation from './components/ClientInvitation/ClientInvitation';
import GatewayInfo from './components/GatewayInfo/GatewayInfo';
import ProviderSetup from './components/ProviderSetup/ProviderSetup';
import { pageTitle } from './components/shared/whiteLabel/whiteLabel';

faLibrary();

function App() {
  const client = useApolloClient();
  const history = useHistory();
  
  const updateUser = useCallback(
    (newUser) => {
      localStorage.setItem('token', newUser.authenticationToken);
      setUserSession((prevState) => ({ ...prevState, user: newUser }));
    },
    [],
  );

  const clearUser = useCallback(
    () => {
      localStorage.removeItem('token');
      setUserSession((prevState) => ({ ...prevState, user: null }));
      history.push('/');
    },
    [history],
  );

  const [fetchingUser, setFetchingUser] = useState(false);

  const [userSession, setUserSession] = useState({
    user: null,
    clearUser: clearUser,
    updateUser: updateUser
  })

  useEffect(() => {
      document.title = pageTitle();
  }, []);

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GMT_ID
    })
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (userSession.user === null) {
        setFetchingUser(true)
        client.query({
          query: USER_INFO
        })
        .then( res => {
          const user = res.data.userInfo;
          setUserSession((prevState) => ({ ...prevState, user: user }));
          setFetchingUser(false);
          if (history?.location?.state?.from?.pathname) {
            history.replace(history.location.state.from.pathname);
          }
        })
        .catch( _err => {
          clearUser();
          setFetchingUser(false);
        })
      }
    }
  }, [client, history, clearUser, userSession.user])

  return (
    <div className="App gradient-background ">
      <UserContext.Provider value={userSession}>
        { fetchingUser && <Loader message="Cargando tus datos..." /> }
        { !fetchingUser &&
          <Switch>
            <Route
              path={ROUTES[0].path}
              component={LoginPage}
              routes={ROUTES[0].routes}
              exact={ROUTES[0].exact} />
            <Route
              path={ROUTES[3].path}
              component={ClientInvitation}
              exact={ROUTES[3].exact}
            />
            <Route
              path={ROUTES[4].path}
              component={ProviderSetup}
              exact={ROUTES[4].exact}
            />
            <Route
              path={ROUTES[5].path}
              component={GatewayInfo}
              exact={ROUTES[5].exact}
            />
            <PrivateRoute
              path={ROUTES[1].path}
              component={props => <ProviderLayout {...props} routes={ROUTES[1].routes} />}
              routes={ROUTES[1].routes}
              roleLevel={ROUTES[1].roleLevel}
              exact={ROUTES[1].exact} />
            <PrivateRoute
              path={ROUTES[2].path}
              component={props => <ClientLayout {...props} routes={ROUTES[2].routes} />}
              routes={ROUTES[2].routes}
              roleLevel={ROUTES[2].roleLevel}
              exact={ROUTES[2].exact} />
            <Route component={() => <h1>Not Found!</h1>} />
          </Switch>
        }
      </UserContext.Provider>
    </div>
  );
}

export default App;
