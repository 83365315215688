import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';

import SummaryCardsContainer from './SummaryCardsContainer';
import ChartCard from './ChartCard/ChartCard';
import useSolarSystemsPerMonth from './useSolarSystemsPerMonth/useSolarSystemsPerMonth';
import useEnergyGeneratedPerMonth from './useEnergyGeneratedPerMonth/useEnergyGeneratedPerMonth';
import useClientsSummary from './useClientsSummary';

function Dashboard() {
  const {
    currentData,
    loading,
    dashboardFilters
  } = useClientsSummary();

  let currentDate = dayjs(new Date()).format('YYYY-MM-DD');
  let prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - (30 * 12));
  prevDate = dayjs(prevDate).format('YYYY-MM-DD');

  if ( dashboardFilters.measured_at_gteq ) { prevDate = dashboardFilters.measured_at_gteq; }
  if ( dashboardFilters.measured_at_lteq ) { currentDate = dashboardFilters.measured_at_lteq; }

  const solarSystemData = useSolarSystemsPerMonth(prevDate, currentDate, dashboardFilters);
  const energyData = useEnergyGeneratedPerMonth(prevDate, currentDate, dashboardFilters);

  return (
    <Container fluid >
      <Row>
        <Col>
          <SummaryCardsContainer data={currentData} loading={loading} />
          <Row className='chart-bg mt-3 px-3'>
            <Col lg >
              <ChartCard
                loading={solarSystemData.loading}
                data={solarSystemData.data}
                length={solarSystemData.length}
                title="Sistemas Solares Operando"
                chartData={solarSystemData.graphData}
                unit=""
              />
            </Col>
            <Col lg>
              <ChartCard
                loading={energyData.loading}
                data={energyData.data}
                length={energyData.length}
                title="Energía generada"
                chartData={energyData.graphData}
                unit=" kWh"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
