import { gql } from "apollo-boost";

const INSTALLATION_SETUP = gql`
  mutation installationSetup(
    $setupToken: String!
    $meterGateways: [GatewayInput!]!
    $meterDevices: [DeviceInput!]
    $installedKw: Float
  ) {
    installationSetup(
      input: {
        setupToken: $setupToken
        meterGateways: $meterGateways
        meterDevices: $meterDevices
        installedKw: $installedKw
      }
    ) {
      meterGateways {
        id
        name
      }
    }
  }
`;

export default INSTALLATION_SETUP;
