import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Nav } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import USERNAME_GATEWAY from '../../graphql/queries/username_gateway';
import Loader from '../shared/Loader/Loader';
import InvitationStatus from '../Dashboard/InvitationStatus/InvitationStatus';
import InfoMessage from '../shared/InfoMessage/InfoMessage';
import ServiceStatusDot from '../Dashboard/ServiceStatus/ServiceStatusDot';
import ServiceStatus from '../Dashboard/ServiceStatus/ServiceStatus';
import { useActiveKey } from '../../contexts/ProviderContext/ActiveKeyContext';

function ClientSidebar({ canEdit, handleTabSelect, clientId, clientDashboard }) {
  const history = useHistory();
  
  
  const { data: usernameGatewayData, loading: usernameGatewayLoading } = useQuery(USERNAME_GATEWAY,  {
    variables: { clientId: clientId }
  });
  const { activeKey, setActiveKey } = useActiveKey();

  const clientName = usernameGatewayData?.usernameGateway?.client?.name || '';
  const clientEmail = usernameGatewayData?.usernameGateway?.client?.email || '';
  //const address = usernameGatewayData?.usernameGateway?.client?.home?.address || '';
  const hasBeenInvited = usernameGatewayData?.usernameGateway?.client?.invitedToSignUp;
  const hasAcceptedInvitation = usernameGatewayData?.usernameGateway?.client?.invitationAccepted;
  const activationStatus = usernameGatewayData?.usernameGateway?.meterGateways[0]?.status;
  const activationCode = usernameGatewayData?.usernameGateway?.meterGateways[0]?.activationCode;
  const acceptInvitationUrl = usernameGatewayData?.usernameGateway?.acceptInvitationUrl || '';
  const [showToast, setShowToast] = useState(false);
  const invalidIdInfoMessage = 'Este código de validación se le debe compartir al instalador para finalizar la instalación de este cliente.';

  const handleSelect = (selectedKey) => {
    setActiveKey(selectedKey);
    handleTabSelect(selectedKey); 
  };

useEffect(() => {
    if (history.location.state) {
      setShowToast(true);
    }
  },[history.location.state]);

  const displayInvalidInstalationId = () => {
    return (
      <Alert className="w-100 text-center m-0 mt-4 warning-alert" data-testid="invalid-installation">
        <FontAwesomeIcon icon="exclamation-triangle" className="warning mr-2"/>
        La instalación de este cliente no ha sido validada, por lo que puede tener información incorrecta/incompleta.
        {activationCode &&
          <div> El código de validación es: <span className="text-muted">{activationCode ?? 'n/d'}</span>
            <InfoMessage placement="left" message={invalidIdInfoMessage}/>
          </div>
        }
      </Alert>
    )
  }

  const missingDevicesAlert = () => {
    return (
      <Alert className="w-100 text-center m-0 mt-4 warning-alert" data-testid="gateway-alert">
        <FontAwesomeIcon icon="exclamation-triangle" className="warning mr-2"/>
        Por el momento este cliente no tiene ningún medidor dado de alta, por lo que no tenemos lecturas de consumo ni generación.
      </Alert>
    )
  }

  const missingConnectionAlert = () => {
    return (
      <Alert className="w-100 text-center m-0 mt-4 warning-alert" data-testid="interconnection-alert">
        <FontAwesomeIcon icon="exclamation-triangle" className="warning mr-2"/>
        Por el momento este cliente no tiene fecha de interconexión registrada, por lo que no tenemos lecturas de generación.
      </Alert>
    )
  }
  return (
    <div>
      { usernameGatewayLoading && <Loader message="Cargando..." /> }
      { !usernameGatewayLoading &&
        <React.Fragment>
          <Row style={{fontWeight: '300'}}>
            <Col className='bg-darks mb-5 text-center'>
              {clientDashboard && 
                <>
                  <h4 className="text-primary mt-3">{canEdit && 'Proyecto'}</h4>
                  <div className="align-middle d-flex justify-content-center">
                    <p className="text-secondary mr-2">{usernameGatewayLoading ? 'Cargando...' : clientName }</p>
                    <ServiceStatusDot status={usernameGatewayData?.usernameGateway?.meterGateways[0]?.active}/>
                  </div>
                  <div>
                    {canEdit &&
                      <InvitationStatus
                        clientId={clientId}
                        clientEmail={clientEmail}
                        hasBeenInvited={hasBeenInvited}
                        hasAcceptedInvitation={hasAcceptedInvitation}
                        acceptInvitationUrl={acceptInvitationUrl}
                      />
                    }
                  </div>
                  {(canEdit && activationStatus !== 'validated') &&
                  <Row className="m-0 d-flex justify-content-center justify-content-md-end">
                    {displayInvalidInstalationId()}
                  </Row>
                  }
                  {(usernameGatewayData?.usernameGateway?.meterGateways[0] === null) &&
                  <Row className="m-0 d-flex justify-content-center align-items-center">
                    {missingDevicesAlert()}
                  </Row>
                  }
                  {(usernameGatewayData?.usernameGateway?.client?.cfeHome?.cfeInterconnectionAt === null) &&
                  <Row className="m-0 d-flex justify-content-center align-items-center">
                    {missingConnectionAlert()}
                  </Row>
                  } 
                </>
              }
              <Nav className="flex-column text-left border-top border-1 mt-5 pt-5 px-3 w-100" style={{fontWeight: '300'}} activeKey={activeKey} onSelect={handleSelect}>
                <Nav.Item>
                  <Nav.Link eventKey="consumptionAndGeneration" className={activeKey === 'consumptionAndGeneration' ? 'text-primary' : 'text-secondary'}>Tiempo Real</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mt-4">
                  <Nav.Link eventKey="gdmthDaily" className={activeKey === 'gdmthDaily' ? 'text-primary' : 'text-secondary'}>Diario y Mensual</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mt-4">
                  <Nav.Link eventKey="operativeData" className={activeKey === 'operativeData' ? 'text-primary' : 'text-secondary'}>Operativas</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mt-4">
                  <Nav.Link eventKey="emScore" className={activeKey === 'emScore' ? 'text-primary' : 'text-secondary'}>EM Score</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className='my-5'></div>
              {canEdit &&
                <div className='border-top border-1'>
                  <ServiceStatus
                    className="text-left ml-3"
                    initialStatus={usernameGatewayData?.usernameGateway?.meterGateways[0]?.active}
                    gatewayId={usernameGatewayData?.usernameGateway?.meterGateways[0]?.id}
                    canEdit={canEdit}
                    clientId={clientId}
                    hasMeterGateway={usernameGatewayData?.usernameGateway?.meterGateways[0] === null}
                  />
                </div>
              }
          </Col>
        </Row>
      </React.Fragment>
      }
    </div>
  );
}

export default ClientSidebar;
