import React, { memo } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

export const Map = memo(({ lat, lng }) => {
  lat = lat === 0 ? 30.5121308 : lat;
  lng = lng === 0 ? -38.523 : lng;

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat, lng }}
        zoom={15}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </LoadScript>
  );
});
