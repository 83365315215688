import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Col, Row, Form } from 'react-bootstrap';
import Autocomplete from 'react-autocomplete';
import dayjs from 'dayjs';

import Loader from '../../../shared/Loader/Loader';
import CITIES from '../../../../graphql/queries/cities';
import STATES from '../../../../graphql/queries/states';
import CHANNELS from '../../../../graphql/queries/providers/channels';

function DashboardFilters({ filters, updateFilters, stateFilter }) {
  const { data: dataCities, loading: loadingCities } = useQuery(CITIES);
  const { data: dataStates, loading: loadingStates } = useQuery(STATES);
  const { data: dataChannels, loading: loadingChannels } = useQuery(CHANNELS);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('');

  const handleChange = (e) => {
    const fieldName = e.target.name;
    let fieldValue = e.target.value;

    if(fieldName === 'measured_at_gteq') {
      fieldValue = dayjs(fieldValue).format('YYYY-MM-DD');
    }

    if(fieldName === 'measured_at_lteq') {
      let selectedDate = new Date(dayjs(fieldValue));
      let dateValue = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
      fieldValue = dayjs(dateValue).format('YYYY-MM-DD');
    }

    updateFilters((prevState) => {
      return { ...prevState, [fieldName]: fieldValue }
    })
  }

  const onChangeChannelInput = (e) => {
    const channelInput = e.target.value
    setSelectedChannel(channelInput)
    setFilteredChannels(
      dataChannels.providerChannels.filter(obj => obj.toLowerCase().indexOf(channelInput.toLowerCase()) >= 0 )
    );
  }

  const onSelectChannel = (channelVal) => {
    setSelectedChannel(channelVal)
    updateFilters((prevState) => {
      return { ...prevState, channel_eq: channelVal}
    })
  }

  const alternativeOptionSelect = (e) => {
    // this is used to cover case when the user clears the input and presses enter
    if (e.which === 13) { // Enter key
      const inputValue = e.target.value;
      // clear channel filter
      if (!inputValue.length) {
        onSelectChannel(inputValue);
      }
    }
  }

  useEffect(() => {
    if(dataChannels) {
      setFilteredChannels(dataChannels.providerChannels.sort())
    }
  }, [dataChannels])

  let currentMonthlyDate = dayjs(new Date()).format('YYYY-MM');

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-secondary my-4">Filtrar por:</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          {loadingChannels ? (
            <Loader message="Cargando channels..." />
          ) : (
            <Form.Group className="dropdown" controlId="filterChannel">
              <Form.Label className="text-secondary mt-2">Channel</Form.Label>
              <Autocomplete
                items={filteredChannels}
                getItemValue={(item) => item}
                wrapperStyle={{width: '100%'}}
                renderInput={(props) => 
                
                  <Form.Control {...props} 
                    onKeyPress={alternativeOptionSelect} 
                    autoComplete="chrome-off" 
                    size="sm"
                    className="white-form" /> }
                    renderMenu={(items, _value) => {
                  return <div style={{ zIndex: 99 }} children={items} className="dropdown-menu d-block"/>
                }}
                renderItem={(item, isHighlighted) =>
                  <div key={item} className={`${isHighlighted ? 'bg-secondary text-white' : 'bg-white text-primary'} dropdown-item ml-3`}>
                    {item}
                  </div>
                }
                value={selectedChannel}
                onChange={onChangeChannelInput}
                onSelect={onSelectChannel}
              />
            </Form.Group>
          )}
        </Col>
      </Row>
      {/* Region Filter */}
      <Row>
        <Col>
          {loadingStates
            ? <Loader message="Cargando estados..." />
            : <Form.Group controlId="filterState">
                <Form.Label className="text-secondary">Estado</Form.Label>
                <Form.Control 
                  as="select" 
                  name="filter_by_state_eq"
                  onChange={handleChange} 
                  aria-label="select-state-filter" 
                  className="select white-form" >
                  <option value={''}>Sin selección</option>
                  {dataStates?.states?.map((state, index) => <option key={index} value={state.id}>{state.name}</option>)}
                </Form.Control>
              </Form.Group>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {loadingCities
            ? <Loader message="Cargando ciudades..." />
            : <Form.Group  controlId="filterCity">
              <Form.Label className="text-secondary">Ciudad</Form.Label>
              <Form.Control 
                as="select" 
                name="filter_by_city_i_cont" 
                onChange={handleChange} 
                className="select white-form" 
                aria-label="select-city-filter" >
                <option value={''}>Sin selección</option>
                {stateFilter
                  ? dataCities?.cities?.filter(city => city.state.id === stateFilter)[0]?.cities?.map((city, index) => <option key={index} value={city}>{city}</option>)
                  : dataCities?.cities?.map((city, index) => (
                    <optgroup key={index} label={city.state.name}>
                      {city.cities?.map((city, index) => <option key={index} value={city}>{city}</option>)}
                    </optgroup>
                  ))
                }
              </Form.Control>
            </Form.Group>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="text-secondary">
              Desde
            </Form.Label>
            <Form.Control
              type="month"
              lang="es"
              name='measured_at_gteq'
              className="white-form"
              max={currentMonthlyDate}
              value={filters.measured_at_gteq.substring(0, 7)}
              onChange={(e) => {
                handleChange(e);
              }}
              aria-label='start-month-input'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="text-secondary">
              Hasta
            </Form.Label>
            <Form.Control
              type="month"
              lang="es"
              name='measured_at_lteq'
              className="white-form"
              min={filters.measured_at_gteq.substring(0, 7)}
              max={currentMonthlyDate}
              value={filters.measured_at_lteq.substring(0, 7)}
              onChange={(e) => {
                handleChange(e);
              }}
              aria-label='end-month-input'
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default DashboardFilters;
