import { gql } from "apollo-boost";

const CREATE_CLIENT = gql`
  mutation createClientMutation(
    $clientAttributes: UserInput!,
    $homeAttributes: AddressInput!,
    $cfeHomeAttributes: CfeHomeInput!,
    $meterGatewaysAttributes: [GatewayInput!],
    $cfeInvoiceAttributes: CfeInvoiceInput!,
    $deviceAttributes: [DeviceInput!],
    $inverterAttributes: [InverterInput!],
  ) {
    createClientMutation(
      input: {
        clientAttributes: $clientAttributes,
        homeAttributes: $homeAttributes,
        cfeHomeAttributes: $cfeHomeAttributes,
        meterGatewaysAttributes: $meterGatewaysAttributes,
        cfeInvoiceAttributes: $cfeInvoiceAttributes,
        deviceAttributes: $deviceAttributes,
        inverterAttributes: $inverterAttributes,
      }
    ) {
      client {
        id
        email
        name
      }
    }
  }
`;

export default CREATE_CLIENT;