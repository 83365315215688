import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { createUploadLink } from 'apollo-upload-client';
import { SchemaLink } from '@apollo/link-schema';
import { executableSchema } from './mocked_schema/mocked_schema';

const cache = new InMemoryCache();
const httpLink = createUploadLink({ uri: process.env.REACT_APP_SERVER_URI });
const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const mockedSchemaLink = new SchemaLink({ schema: executableSchema });
const normalLink = authLink.concat(httpLink); // or ApolloLink.from([authLink, httpLink])
const link = process.env.REACT_APP_MOCK_API === 'true' ? mockedSchemaLink : normalLink;

const client = new ApolloClient({
  link: link, // use normalLink instead
  cache: cache
});

export const stripTypenames = (value) => {
  if (Array.isArray(value)) {
      return value.map(stripTypenames)
  } else if (value !== null && typeof(value) === "object") {
    const newObject = {}
    for (const property in value) {
        if (property !== '__typename') {
          newObject[property] = stripTypenames(value[property])
        }
    }
    return newObject
  } else {
    return value
  }
}

export default client;
