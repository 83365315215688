export const CHARTS_COLORS = {

  yellow: '#F6C25B',
  cyan: '#4FD7CF',
  blue: '#4FA4D7',

  dark_yellow: '#AD8A49',
  dark_cyan:'#38999A',
  dark_blue: '#38759F',

  bg: '#04071E',
  bg_highlight: '#13303f', 

  gray40: '#EDEDED'
}

