import React from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import Loader from '../../../shared/Loader/Loader';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
} from 'recharts';
import { CHARTS_COLORS } from '../../../shared/Charts/ChartsColors';
import InfoMessage from '../../../shared/InfoMessage/InfoMessage';
import CustomPieChartLabel from '../../../shared/CustomPieChartLabel/CustomPieChartLabel';
import { I_ICON_MESSAGES } from '../../../shared/InfoMessage/messages';

const CurrentPeriodCostsBreakdown = ({ data, loading, setPeriod }) => {
  const graphData = data?.costBreakdown || [];
  
  const consumptions_data = [
    {
      "name": "Base",
      "value": Math.round(graphData.baseConsumption),
      "fill": CHARTS_COLORS.dark_blue,
      "stroke": CHARTS_COLORS.dark_blue,
    },
    {
      "name": "Intermedia",
      "value": Math.round(graphData.intermediateConsumption),
      "fill": CHARTS_COLORS.dark_cyan,
      "stroke": CHARTS_COLORS.dark_cyan,
    },
    {
      "name": "Punta",
      "value": Math.round(graphData.highConsumption),
      "fill": CHARTS_COLORS.dark_yellow,
      "stroke": CHARTS_COLORS.dark_yellow,
    },
  ];
  const costs_data = [
    {
      "name": "Base",
      "value": Math.round(graphData.fixedCost
                          + graphData.baseConsumptionCost
                          + graphData.baseDistributionCost
                          + graphData.baseCapacityCost),
      "fill": CHARTS_COLORS.dark_blue,
      "stroke": CHARTS_COLORS.dark_blue,
    },
    {
      "name": "Intermedia",
      "value": Math.round(graphData.intermediateConsumptionCost
                          + graphData.intermediateDistributionCost
                          + graphData.intermediateCapacityCost),
      "fill": CHARTS_COLORS.dark_cyan,
      "stroke": CHARTS_COLORS.dark_cyan,
    },
    {
      "name": "Punta",
      "value": Math.round(graphData.highConsumptionCost
                          + graphData.highDistributionCost
                          + graphData.highCapacityCost),
      "fill": CHARTS_COLORS.dark_yellow,
      "stroke": CHARTS_COLORS.dark_yellow,
    },
  ];

  const handleSelect = (e)=>{
    const val = parseInt(e.target.value)
    setPeriod(val)
  }

  return (
    <Card
      className="accumulated-costs h-100 chart-bg mt-2 mb-4"
      data-testid="current-period-costs-projections"
    >
      <Card.Body>
        <Card.Title className="text-secondary">
          {I_ICON_MESSAGES.costsBreakdown.header} <InfoMessage placement="right" message={I_ICON_MESSAGES.costsBreakdown.message} />
        </Card.Title>
        <Row className="mb-3 ml-1">
          <Form.Group className="pr-6">
            <Form.Control onChange={handleSelect} as="select" name="cfe_payment_period" className="select cyan-form text-secondary">
              <option value='0' style={{ color:  'black' }}>Periodo Actual</option>
              <option value='1' style={{ color:  'black' }}>Periodo pasado</option>
              <option value='2' style={{ color:  'black' }}>Dos periodos antes</option>
            </Form.Control>
            </Form.Group>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <Loader />
            ) : graphData.fixedCost ? (
              <>
                <Row>
                  <Col>              
                    <div className="d-flex justify-content-center">
                      <h6 className="text-secondary">
                        Consumos
                      </h6>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                            data={consumptions_data}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#82ca9d"
                            centerText="name"
                            isAnimationActive={false}
                            label={<CustomPieChartLabel centerText="name" />}
                          >
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col>                    
                    <div className="d-flex justify-content-center">
                      <h6 className="text-secondary">
                        Costos
                      </h6>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie 
                          data={costs_data}
                          dataKey="value"
                          nameKey="name"
                          outerRadius={100}
                          fill="#000000"
                          centerText="name"
                          isAnimationActive={false}
                          label={<CustomPieChartLabel centerText="costs" />}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <p
                  className="px-5 py-5 text-muted font-weight-normal"
                  data-testid="messageInCurrentPeriodCosts"
                >
                  No tenemos datos de tu recibo actual.
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CurrentPeriodCostsBreakdown;
