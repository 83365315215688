
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

  
export const downloadToCSV = (devicesData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let wb = XLSX.utils.book_new();
  Object.keys(devicesData).forEach(deviceName => {
    const ws = XLSX.utils.json_to_sheet(devicesData[deviceName], { nullError: true });
    XLSX.utils.book_append_sheet(wb, ws, deviceName);
  })
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
