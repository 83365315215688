export const typeDefs = `
  scalar BaseScalar
  scalar ISO8601Date
  scalar JSON

  type Query {
    userInfo: User!
    states: [State]!
    cities: [CitiesPayload]!
    cfeFees: [CfeFee]!
    liveConsumption(clientId: ID!, startDate: ISO8601Date , endDate: ISO8601Date): LiveConsumptionPayload!
    dailyConsumption(clientId: ID!, dateFilter: ISO8601Date, intervalMinutes: Int): DailyConsumptionPayload!
    operationalData(clientId: ID!, dateFilter: ISO8601Date, intervalMinutes: Int): OperationalDataPayload!
    houseComparisons(clientId: ID!): HouseComparisonsPayload!
    costsProjections(clientId: ID!): CostsProjectionsPayload!
    providerClients(page: Int, perPage: Int, q: BaseScalar): UserPage!
    clientInfo(clientId: ID!): ClientInfoPayload!
    usernameGateway(clientId: ID!): ClientInfoPayload!
    gatewayDevices(key: String!, name: String!): [MeterDevice]!
    userFromInvitationToken(invitationToken: String!): User
    similarHousesComparison(clientId: ID!): SimilarHousesComparisonPayload!
    clientByToken(setupToken: String!): ClientInfoPayload!
    gatewayInfo(meterGatewayName: String!): GatewayInfoPayload!
    currentPeriodCostsProjections(clientId: ID!, dateFormat: String, period: Int!): CurrentPeriodCostsProjectionsPayload
    zipcodesAutocomplete(matchZipcode: String, limitResults: Int): [String]!
    savingsWithPanels(clientId: ID!): SavingsWithPanelsPayload!
    gatewayLogs(clientId: ID): GatewayLogsPayload!
    gatewayStatus(meterGateways: [ID!]!): [GatewayReadingsAlertPayload]!
    solarSystemsPerMonth(q: BaseScalar, startDate: ISO8601Date!, endDate: ISO8601Date!): [SolarSystemsPerMonthPayload]!
    monthlyKwhGeneration(q: BaseScalar, startDate: ISO8601Date!, endDate: ISO8601Date!): [MonthlyKwhGenerationPayload]!
    providerSummary(q: BaseScalar): ProviderSummary!
    clientsConsumptions(userIds: [ID!]!): [ClientsConsumptionsPayload]!
    providerChannels: [String]!
  }

  type Mutation {
    login(input: LoginInput): LoginPayload
    createClientMutation(input: CreateClientInfoInput): ClientConfirmationPayload
    updateClientMutation(input: UpdateClientInfoInput): ClientConfirmationPayload
    activateGatewayMutation(input: GatewayActivationInput): MeterGatewayActivationPayload
    acceptInvitationMutation(input: AcceptInvitationInput): AcceptInvitationPayload
    inviteClientMutation(input: ClientInvitationInput): ClientInvitationPayload
    installationSetup(input: SetupIntallationInput): InstallationPayload
    createClientFromHubspotMutation(input: createClientFromHubspotInput):ClientConfirmationPayload
    meterGatewayValidation(input: GatewayValidationInput): GatewayValidationPayload
    deleteClient(clientId: ID!): ClientConfirmationPayload
  }

  type User {
    id: ID!
    name: String
    email: String
    password: String
    authorityLevel: Int
    authenticationToken: String
    meterGateway: MeterGateway
    invitedToSignUp: Boolean
    invitationAccepted: Boolean
    setupToken: String
    cfeHome: CfeHome
    home: Home
    channel: String
    customId: String
    headerImageUrl: String
  }

  type Home {
    id: ID!
    street: String
    externalNumber: String
    neighborhood: String
    zipcode: String
    city: String
    stateId: ID
    latitude: Float
    longitude: Float
    state: State
    address: String
  }

  type CfeHome {
    id: ID!
    cfeRpu: String
    cfeName: String
    cfeFeeId: ID
    installedKw: Float
    tiltedPanels: Boolean
    cfeSummerStartMonth: Int
    monthlyPeriod: Int
    lastReading: Float
    currentPeriodCfeKwhPrice: Float
  }

  type MeterDevice {
    id: ID
    name: String
    measureType: Int
    positive: Boolean
    note: String
    humanName: String
  }

  type Inverters {
    id: ID
    inverterBrandId: String!
    sn: String! 
  }

  type MeterGateway {
    id: ID!
    name: String
    key: String
    active: Boolean
    currentPeriodAvgKwhConsumption: Float
    yieldGen: Float
    status: String
    activationCode: String
    kwhGenYesterday: Float
    invalidGatewayAlert: Boolean
    lowYieldAlert: Boolean
    cfeInterconnectionAt: String
    meterDevices: [MeterDevice]
  }

  type State {
    id: ID!
    code: String
    name: String
    cfeShortName: String
  }

  type MeterReading {
    id: ID!
    kwhTot: Float!
    wattsTot: Float!
    ampTot: Float!
    voltTot: Float!
  }

  type CfeInvoice {
    id: ID!
    periodStartDate: String
    periodEndDate: String
    consumption: Int
    generation: Int
  }

  type Audit {
    id: ID
    changesMadeBy: User
    action: String
    auditedChanges: JSON
    createdAt: String
  }

  input LoginInput {
    email: String!
    password: String!
  }

  type LoginPayload {
    user: User!
  }

  type Lines {
    n: Int!
    a: Float
    v: Float
    w: Float
    p: Float
  }

  type ReadingLines {
    t: String!
    l: [Lines]
  }

  type MeterReadingLines {
    readingLines: [ReadingLines]
  }

  type CartesianPoint {
    x: String
    y: Float
  }

  type CartesianPointDailyType {
    x: String
    y: MeterReading
  }

  type HouseComparison {
    daily: Float
    average: Float
    percentageChange: Float
  }

  type LiveConsumptionPayload {
    lastUpdateAt: String
    injectedGeneration: [CartesianPoint]
    cfeConsumption: [CartesianPoint]
    solarConsumption: [CartesianPoint]
  }

  type GatewayReadingsAlertPayload {
    gatewayId: ID
    readingsAlert: Boolean
  }

  type OperationalDataPayload { 
    meterReadingLines: [MeterReadingLines]
  }

  type DailyConsumptionPayload {
    lastUpdateAt: String
    cfeConsumption: [CartesianPoint]
    solarConsumption: [CartesianPoint]
    injectedGeneration: [CartesianPoint]
  }

  type HouseComparisonsPayload {
    neighborhoodComparison: HouseComparison
    cityComparison: HouseComparison
    stateComparison: HouseComparison
  }

  type ClientInfoPayload {
    client: User
    home: Home
    cfeHome: CfeHome
    cfeInvoices: [CfeInvoice]
    meterGateways: [MeterGateway]
    meterDevices: [MeterDevice]
    acceptInvitationUrl: String
    inverters: [Inverters]
  }

  type ClientInvitationPayload {
    invitationUrl: String
  }

  type CostsProjectionsPayload {
    previousPeriodsPayments: [CartesianPoint]
    nextPaymentAt: String
    nextPaymentTotalCost: Float
    nextPaymentCostToDate: Float
  }

  type SavingsWithPanelsPayload {
    nextPaymentToDate: Float
    nextPaymentToDateWithNoPanels: Float
    nextPaymentAt: String
    savingsPercentage: Float
  }

  type GatewayInfoPayload {
    meterGateway: MeterGateway!
    clientId: ID!
  }

  type GatewayLogsPayload {
    meterGateway: MeterGateway
    logs: [Audit]
  }

  type CitiesPayload {
    state: State
    cities: [String]
  }

  type UserConnection {
    edges: [UserEdge]
    pageInfo: PageInfo
  }

  type PageInfo {
    endCursor: ID!,
    hasNextPage: Boolean!,
  }

  type UserEdge {
    cursor: ID!,
    node: User!,
  }

  type UserPage {
    hasNextPage: Boolean
    hasPreviousPage: Boolean
    pagesCount: Int
    totalCount: Int
    nodes: [User]
  }

  type ClientAttributes {
    email: String!
    name: String!
  }

  input UserInput {
    id: ID
    email: String!
    name: String!
    channel: String
    customId: String
  }

  type CurrentPeriodCostsProjectionsPayload {
    totalCostsWithPanels: Float
    totalCostsWithoutPanels: Float
    totalProjectedCostsWithPanels: Float
    totalProjectedCostsWithoutPanels: Float
    costsProjectionsByDay: [PeriodDaysCostsProjectionsPayload]!
    nextPaymentWithPanels: Float
    nextPaymentWithoutPanels: Float
    nextPaymentAt: Float
    savingsPercentage: Float
  }

  type PeriodDaysCostsProjectionsPayload {
    date: String!
    costsWithPanels: Float
    costsWithoutPanels: Float
    projectedCostsWithPanels: Float
    projectedCostsWithoutPanels: Float
  }

  input AddressInput {
    street: String!
    externalNumber: String!
    neighborhood: String!
    zipcode: String!
    city: String!
    stateId: ID!
    latitude: Float!
    longitude: Float!
  }

  input CfeHomeInput {
    cfeRpu: String
    cfeName: String
    cfeFeeId: ID!
    installedKw: Float!
    cfeInterconnectionAt: ISO8601Date
  }

  input GatewayInput {
    id: String
    name: String!
    key: String!
    meterDevices: [DeviceInput]
  }

  input CfeInvoiceInput {
    id: ID
    periodStartDate: String!
    periodEndDate: String!
    consumption: Int
    generation: Int
  }

  input DeviceInput {
    id: String
    name: String
    measureType: Int!
    positive: Boolean
  }

  input InverterInput {
    id: ID
    inverterBrandId: ID!
    sn: String!
  }

  input CreateClientInfoInput {
    clientAttributes: UserInput!
    homeAttributes: AddressInput!
    cfeHomeAttributes: CfeHomeInput!
    meterGatewaysAttributes: [GatewayInput!]!
    cfeInvoiceAttributes: CfeInvoiceInput!
    deviceAttributes: [DeviceInput!]
    inverterAttributes: [InverterInput]
  }

  input GatewayActivationInput {
    gatewayId: ID!,
    active: Boolean!
  }

  input UpdateClientInfoInput {
    clientAttributes: UserInput!
    homeAttributes: AddressInput
    cfeHomeAttributes: CfeHomeInput
    meterGatewaysAttributes: [GatewayInput!]!
    cfeInvoiceAttributes: CfeInvoiceInput
    deviceAttributes: [DeviceInput!]
    inverterAttributes: [InverterInput]
  }

  input SetupIntallationInput {
    setupToken: String!
    meterGateways: [GatewayInput]
    meterDevices: [DeviceInput]
    installedKw: Float
  }

  input GatewayValidationInput {
    setupToken: String!
    activationCode: String!
  }

  type ClientConfirmationPayload {
    client: User!
  }

  type AcceptInvitationPayload {
    user: User!
  }

  type MeterGatewayActivationPayload {
    gateway: MeterGateway
  }

  type InstallationPayload {
    meterGateways: [MeterGateway]
  }

  type GatewayValidationPayload {
    validated: Boolean
  }

  type SimilarHousesComparisonPayload {
    averageConsumption: Float!
    similarHousesAverageConsumption: Float!
    averageYield: Float!
    similarHousesAverageYield: Float!
  }

  type CfeFee {
    feeType: String!
    id: ID!
  }

  type SolarSystemsPerMonthPayload {
    solarSystems: Int!
    month: String!
  }

  type MonthlyKwhGenerationPayload {
    kwhGeneration: Int!
    month: String!
  }

  type ProviderSummary {
    energyGeneration: Float!
    energySavings: Float!
    solarSystems: Float!
    solarSystemsKw: Float!
    tonsCo2Avoided: Float!
  }

  input AcceptInvitationInput {
    invitationToken: String!
    password: String!
  }

  type ClientsConsumptionsPayload {
    userId: ID!
    kwhSolarCost: Float!
    kwhCfeCost: Float!
    savings: Float!
  }

  input createClientFromHubspotInput {
    hubspotDealId: String!
  }

  input ClientInvitationInput {
    clientId: ID!
    skipInvitationDelivery: Boolean
  }
`;
