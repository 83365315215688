// https://www.npmjs.com/package/@fortawesome/react-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faHubspot } from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faUnlockAlt,
  faCommentDots,
  faSearch,
  faInfoCircle,
  faCalendarMinus,
  faTh,
  faBars,
  faUserPlus,
  faArrowDown,
  faArrowUp,
  faCaretUp,
  faCaretDown,
  faChevronUp,
  faChevronDown,
  faCog,
  faIdCard,
  faExclamationTriangle,
  faKeyboard,
  faDollarSign,
  faTimesCircle,
  faPiggyBank,
  faSun,
  faIndustry,
  faHome,
  faLeaf,
  faBell,
  
} from '@fortawesome/free-solid-svg-icons';

const faLibrary = () => {
  library.add(
    fab,
    faEnvelope,
    faUnlockAlt,
    faCommentDots,
    faSearch,
    faInfoCircle,
    faCalendarMinus,
    faTh,
    faBars,
    faUserPlus,
    faArrowDown,
    faArrowUp,
    faChevronUp,
    faChevronDown,
    faCaretUp,
    faCaretDown,
    faCog,
    faHubspot,
    faIdCard,
    faExclamationTriangle,
    faKeyboard,
    faDollarSign,
    faTimesCircle,
    faPiggyBank,
    faSun,
    faIndustry,
    faHome,
    faLeaf,
    faBell,
  );
};

export default faLibrary;
