import { gql } from 'apollo-boost';

const CLIENTS_CONSUMPTIONS = gql`
  query clientsConsumptions($userIds: [ID!]!) {
    clientsConsumptions(userIds: $userIds) {
      userId
      kwhSolarCost
      kwhCfeCost
      savings
    }
  }
`;

export default CLIENTS_CONSUMPTIONS;
