import React, { createContext, useState, useContext } from 'react';

const ActiveKeyContext = createContext();

export const useActiveKey = () => useContext(ActiveKeyContext);

export const ActiveKeyProvider = ({ children }) => {
  const [activeKey, setActiveKey] = useState('consumptionAndGeneration');

  return (
    <ActiveKeyContext.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </ActiveKeyContext.Provider>
  );
};