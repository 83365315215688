import React from 'react';
import { Form, Col, Row, Alert } from 'react-bootstrap';

import Loader from '../../shared/Loader/Loader';

export const GatewayDevices = ({
  devices,
  devicesData,
  loading,
  error,
  updateDevicesData,
  hideFields
}) => {
  const currentDevices = devices || [];
  const hidePositiveSigns = hideFields?.positiveSign ?? false;
  if (error) {
    return (
      <Col>
        <Alert variant="danger">
          {error}
        </Alert>
      </Col>
    );
  }
  return (
    <Col>
      {loading ? (
        <Loader />
      ) : (
        <>
          {currentDevices.length ? (
            <p className="mt-4 mb-3">
              Seleccione el tipo de medida para cada medidor
            </p>
          ) : (
            ''
          )}
          {currentDevices.map((device, key) => {
            return (
              <Row className="mb-2 col-12" key={device.name}>
                <Col className="col-3 col-md-2 p-0 ml-3 d-flex align-items-center">
                  <span>{device.name}</span>
                  <span className="mr-2">:</span>
                </Col>
                <Col className="col-4 col-md-3 p-0 ml-3 d-flex align-items-center">
                  <MeasureTypeFormGroup
                    identifier={device.id || device.name}
                    updateDevicesData={updateDevicesData}
                    measureType={device.measureType}
                  />
                </Col>
                {!hidePositiveSigns &&
                  <Col className="col-6 p-0 d-flex align-items-center">
                    <PositiveSignFormGroup
                      device={device}
                      identifier={device.id || device.name}
                      updateDevicesData={updateDevicesData}
                      positive={device.positive}
                    />
                  </Col>
                }
              </Row>

            );
          })}
        </>
      )}
    </Col>
  );
};

const MeasureTypeFormGroup = ({ identifier, measureType, updateDevicesData }) => {
  const deviceName = 'meter-' + identifier + '-measure-type';
  const measureTypes = [
    {
      name: 'Generación',
      value: 0,
    },
    {
      name: 'Consumo',
      value: 1,
    },
    {
      name: 'Bidireccional',
      value: 2,
    },
  ];

  return (
    <Form.Group
      className="p-0 m-0 col-10"
      controlId="formGridDeviceReadingType"
    >
      <Form.Control
        required
        as="select"
        className="mute-form select"
        data-attribute-name="measureType"
        name={deviceName}
        value={measureType || ''}
        onChange={(e) => updateDevicesData(identifier, e)}
        data-testid="measureType"
      >
        {measureTypes.map((type) => {
          return (
            <option key={type.value} value={type.value} style={{ color: type === measureType ? 'white' : 'black' }}>
              {type.name}
            </option>
          );
        })}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        Porfavor seleccione el tipo de medida.
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const PositiveSignFormGroup = ({ device, identifier, positive, updateDevicesData}) => {
  const deviceName = 'meter-' + identifier + '-positive-sign';
  return (
    <Form.Group
      className="p-0 m-0"
      controlId="formGridDevicePositiveSign"
    >
      <Form.Check
        label="Medidas positivas?"
        className="d-inline-block"
        style={{ maxWidth: '100%' }} 
        data-attribute-name="positive"
        name={deviceName}
        type="checkbox"
        checked={positive || false}
        onChange={(e) => updateDevicesData(identifier, e)}
        data-testid="positiveSign"
      />
      {device.note !== null &&   
        <div>
          <small className="text-red">{device.note}</small>
        </div>
      }
    </Form.Group>
  );
};
