import React, { useState } from 'react';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { handleGeneralInputChange } from '../../../services/inputs_service/inputs_service';
import CREATE_CLIENT_FROM_HUBSPOT from '../../../graphql/mutations/createClientFromHubspot';
import ROUTES from '../../../routing/routes';
import Loader from '../../shared/Loader/Loader';

const CreateClientHubspot = () => {
  const [createClientHubspot, {loading: mutationLoading}] = useMutation(CREATE_CLIENT_FROM_HUBSPOT, {errorPolicy: 'all'});
  const [errorResponse , setErrorResponse] = useState('');
  const [formData, setFormData] = useState({
    dealId: ''
  });

  const handleInputChange = (e) => {
    setErrorResponse('');
    handleGeneralInputChange(e, setFormData);
  };

  const displayContent = () => {
    if (mutationLoading) {
      return <Loader message="Validando Deal Id" />;
    }

    return <HubspotClientForm createClientHubspot={createClientHubspot} error={errorResponse} setError={setErrorResponse} loading={mutationLoading} dealId={formData.dealId} handleChange={handleInputChange}/>
  };

  return (
    <Container fluid className="d-flex flex-column align-items-center">
      <Row className="my-2 h-25">
            <Col className="text-center">
              <div>
                <h4 className="font-weight-bold text-primary">
                  Crear Sistema con Hubspot
                </h4>
              </div>
            </Col>
      </Row>
      <Row className="h-50 d-flex flex-column">
        <Col>{displayContent()}</Col>
      </Row>
    </Container>
  );
};

const HubspotClientForm = ({createClientHubspot, loading, error, setError, dealId, handleChange}) => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const displayError = () => {
    return <p className="text-danger mb-0" data-testid="error-message-hubspot">{error}</p>
  }

  const handleCancelForm = () => {
    history.push(ROUTES[1].path)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      createClientHubspot({
        variables: {
          hubspotDealId: dealId
        }
      })
      .then(res => {
        const id = res.data.createClientFromHubspotMutation.client.id;
        history.push(ROUTES[1].routes[3].constructor + id);
      })
      .catch((err) => {
        const error = err.message.replace('GraphQL error: ', '');

        setError(() => {
          if (error === 'Deal does not exist') {
            return 'Lo sentimos, el Deal Id no existe.'
          } else {
            return error;
          }
        });
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      noValidate
      validated={validated}
      role="form"
    >
      <Form.Group controlId="hubspot" className="mb-0">
        <Form.Label>Ingrese el Deal Id (Campo de nombre de usario)</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FontAwesomeIcon icon="id-card" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required
            pattern="^[0-9]{7,11}$"
            type="text"
            placeholder="Deal Id (Ingrese mínimo 7 dígitos)"
            name="dealId"
            value={dealId}
            onChange={handleChange}
            data-testid="hubspot-input-form"
          />
          <Form.Control.Feedback type="invalid">
            Ingrese entre 7 y 11 dígitos
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      {error.length > 0 && displayError()}
      <Button
        variant="primary"
        name="submit-password"
        type="submit"
        className="w-100 mt-4"
        data-testid="enter-deal-id"
        disabled={loading}
      >
        Ingresar
      </Button>
      <Button
        variant="danger"
        id="cancelHubspotForm"
        name="cancelHubspotForm"
        type="button"
        className="w-100 mt-2"
        data-testid="cancel-hubspot-form"
        onClick={handleCancelForm}
      >
        Cancelar
      </Button>
    </Form>
  );
};

export default CreateClientHubspot;
