import { gql } from 'apollo-boost';

const GATEWAY_DEVICES = gql`
  query gatewayDevices($key: String!, $name: String!) {
    gatewayDevices(key: $key, name: $name) {
      name
      measureType
      positive
      note
    }
  }
`;

export default GATEWAY_DEVICES;