import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import useClientsList from '../Provider/Clients/useClientsList';
import ClientsFilters from '../Provider/Clients/ClientsFilters/ClientsFilters';
import useClientsSummary from '../Provider/Dashboard/useClientsSummary';
import DashboardFilters from '../Provider/Dashboard/DashboardFilters/DashboardFilters';
import { homeLogo, homeLogoAlt } from '../shared/whiteLabel/whiteLabel';
import ClientSidebar from './ClientSidebar';

const Sidebar = ({ currentPage, clientId,  canEdit }) => {

  const PAGE_DEFAULTS = {
    page: 1,
    perPage: 25
  }

  const SORTING_COLUMNS = [
    { key: 'name', text: 'Nombre', column: 'name' },
    { key: 'current_period_avg_kwh_consumption', text: 'Consumo', column:'current_period_avg_kwh_consumption'},
    { key: 'gen_kwh', text: 'Generación', column: 'gen_kwh'},
    { key: 'yield', text: 'Yield', column: 'yield'},
    { key: 'installed_kw', text: 'kWp', column: 'installed_kw'},
    { key: 'is_cfe_interconnected', text: 'Interconectado', column: 'is_cfe_interconnected'},
    { key: 'sfv', text: 'Estatus SFV', column: 'sfv'},
  ]

  let sidebarContent;
  const {
    setFilters,
  } = useClientsList(SORTING_COLUMNS, PAGE_DEFAULTS);

  const {
    setDashboardFilters,
    dashboardFilters
  } = useClientsSummary();

  const [activeKey, setActiveKey] = useState('consumptionAndGeneration');

  const handleTabSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };
  if (currentPage === '/provider') {
    sidebarContent = (
      <div>
        <ClientsFilters updateFilters={setFilters}/>
      </div>
    );
  } else if (currentPage === '/provider/dashboard') {
    sidebarContent = (
      <div>
        <DashboardFilters filters={dashboardFilters} updateFilters={setDashboardFilters} stateFilter={dashboardFilters.filter_by_state_eq} />
      </div>
    );
  } else if (currentPage.startsWith('/provider/client_dashboard/' )) {
    sidebarContent = (
      <div>
        <ClientSidebar 
          clientId={clientId || ""} 
          clientDashboard={true}
          canEdit={true} // Revisar canEdit
          handleTabSelect={handleTabSelect} 
          activeKey={activeKey}/> 
      </div>
    );
  } else if (currentPage === '/client' || currentPage.startsWith('/provider/client_dashboard/' )) {
    sidebarContent = (
      <div>
        <ClientSidebar 
          clientId={clientId || ""} 
          clientDashboard={false}
          canEdit={true} // Revisar canEdit
          handleTabSelect={handleTabSelect} 
          activeKey={activeKey}/> 
      </div>
    );
  } 
  return (
    <>
      {sidebarContent !== undefined ? (
        <Container className="d-flex sidebar-column flex-column">
          <div className="my-5">
            <div className='text-center'>
              <img src={homeLogo()} alt={homeLogoAlt()} className="home-logo pb-3 border-bottom text-center" />
            </div>
            <div className='my-4'></div>
            {sidebarContent}
          </div>
        </Container>
      ) : (
        <div className='no-sidebar'></div>
      )}
    </>
  );
}

export default Sidebar;