import { useQuery } from '@apollo/react-hooks';

import MONTHLY_KWH_GENERATION from '../../../../graphql/queries/monthly_kwh_generation';

const useEnergyGeneratedPerMonth = (prevDate, currentDate, filters) => {
  const { data, loading } = useQuery(MONTHLY_KWH_GENERATION, {
    variables: {
      q: { ...filters },
      start_date: prevDate,
      end_date: currentDate
    }
  });

  return {
    loading,
    data: data?.monthlyKwhGeneration?.map(obj => {
      obj.kwhGeneration = Number(obj.kwhGeneration.toFixed());
      return obj;
    }),
    length: data?.monthlyKwhGeneration?.length,
    graphData: {
      x: {
        dataKey: 'month',
        name: 'Fecha'
      },
      y: {
        dataKey: 'kwhGeneration',
        name: 'Energía generada'
      }
    }
  }
}

export default useEnergyGeneratedPerMonth;
