import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal, Form, Alert } from 'react-bootstrap';

import Loader from '../../shared/Loader/Loader';
import IMPORT_EXCEL_FILE from '../../../graphql/mutations/importExcelFile';

function ImportClients() {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [importFile, { loading: importFileLoading }] = useMutation(IMPORT_EXCEL_FILE, { errorPolicy: 'all' });
  const [uploadError, setUploadError] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState('');
  const TEMPLATE_NAME = 'template-clientes.xlsx';
  const SERVER_URL = process.env.REACT_APP_SERVER_URI.replace('graphql', '');
  const TEMPLATE_DOWNLOAD_LINK = SERVER_URL + TEMPLATE_NAME;

  const handleClose = () => { setShowModal(false) }
  const handleOpen = () => { setShowModal(true) }
  const selectFile = (e) => {
    setUploadSuccess('');
    setUploadError('');
    setSelectedFile(e.target.files[0]);
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    importFile({ variables: { excelFile: selectedFile } })
      .then((_res) => {
        setUploadSuccess('Archivo subido exitosamente. Los sistemas se verán reflejados más tarde.');
        setUploadError('');
      })
      .catch((uploadParamsError) => {
        const firstError = uploadParamsError.graphQLErrors[0].message;
        setUploadError('Error: ' + firstError);
        setUploadSuccess('');
      })
  }

  return (
    <>
      <Button className="btn btn-primary btn-sm mr-2 my-2 w-100 p-2" size='sm' onClick={handleOpen}>Importar Sistemas</Button>
      <Modal show={showModal} onHide={handleClose} backdrop={false} >
        <Modal.Header closeButton>
          <Modal.Title>Importar Sistemas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Puedes crear sistemas, importándolos a traves de un archivo excel (.xlsx).</p>
            <p>
              Usa esta <a href={TEMPLATE_DOWNLOAD_LINK} target="_blank" rel="noopener noreferrer">plantilla</a>
              , llenando todos los campos para dar de alta sistemas de este modo. La plantilla incluye un ejemplo para los datos.
            </p>
            <p>Es importante:</p>
            <ul>
              <li>No cambiar los nombres de los títulos de las columnas.</li>
              <li>Los valores de estados y tarifa deben coincidir con algún valor de los que viene en la hoja de estados y tarifas (la plantilla ya trae las opciones incluidas).</li>
              <li>Las fechas deben tener el formato MM/DD/YYYY, escrito con números.</li>
            </ul>
            { importFileLoading && <Loader message="Subiendo archivo" /> }
            { uploadSuccess.length > 0 && <Alert variant="success">{uploadSuccess}</Alert> }
            { uploadError.length > 0 && <Alert variant="danger">{uploadError}</Alert> }
            { !importFileLoading &&
                <Form onSubmit={handleSubmit}>
                  <Form.File name="import_clients" onChange={selectFile} />
                  <Button variant="primary" className="mt-2" type="submit" disabled={selectedFile === null || importFileLoading} >
                    Subir Archivo
                  </Button>
                </Form>
            }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ImportClients;
