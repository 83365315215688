import { makeExecutableSchema } from '@ardatan/graphql-tools';
import { typeDefs } from './type_defs';
import { resolvers } from './resolvers';
import { findUserByToken } from './utilities';

export const executableSchema = makeExecutableSchema({
  typeDefs,
  resolvers,
  context: (integrationContext) => ({
    currentUser: findUserByToken(integrationContext.req.headers.authorization.split()[1])
  }),
});
