import React from 'react';
import Pagination from "react-js-pagination";

function Paginator({ currentPage, itemsPerPage, totalItems, pageRange, handleOnPageChangePaginator }) {
  return (
    <Pagination
      className='paginator'
      itemClass="page-item"
      linkClass="page-link"
      activePage={currentPage}
      itemsCountPerPage={itemsPerPage}
      totalItemsCount={totalItems}
      pageRangeDisplayed={pageRange}
      onChange={handleOnPageChangePaginator}
    />
  )
}

export default Paginator;
