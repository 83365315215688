import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es'

dayjs.extend(utc);
dayjs.locale('es');

const TooltipBody = ({ base, intermediate, high, unit, dataTestId, label }) => {
  return (
    <div className="custom-tooltip p-2" data-testid={dataTestId}>
      <p className="label text-secondary">{label}</p>
      {high.value !== null && (
        <p className="text-yellow my-1" style={{fontSize: "15px"}}>
          {high.name}: {high.value} {unit}
        </p>
      )}
      {intermediate.value !== null && (
        <p className="text-cyan my-1" style={{fontSize: "15px"}}>
          {intermediate.name}: {intermediate.value} {unit}
        </p>
      )}
      {base.value !== null && (
        <p className="text-blue my-1" style={{fontSize: "15px"}}>
          {base.name}: {base.value} {unit}
        </p>
      )}
      
    </div>
  );
}

const GdmthDayTooltip = ({ payload, active, label }) => {
  if (active) {
    const dataInfo = payload ? (payload[0] !== undefined ? payload[0] : null) : null;
    const chartInfo = dataInfo?.payload ?? null;
    const dataKey = dataInfo?.dataKey ?? null;
    const unit = dataInfo?.unit ?? null;
    const date = dayjs(label, 'DD/MM/YYYY').format('D [de] MMMM, YYYY')
    if (
      dataKey === 'baseGeneration' ||
      dataKey === 'intermediateGeneration' ||
      dataKey === 'highGeneration'
    ) {
      return (
          <TooltipBody
            base={{
              name: 'Generacion en Base',
              value: chartInfo?.baseGeneration.toLocaleString()
            }}
            intermediate={{
              name: 'Generacion en Intermedia',
              value: chartInfo?.intermediateGeneration.toLocaleString()
            }}
            high={{
              name: 'Generacion en Punta',
              value: chartInfo?.highGeneration.toLocaleString()
            }}
            unit={unit}
            dataTestId={'generation'}
            label={date}
          />
      );
    }

    if (
      dataKey === 'baseConsumption' ||
      dataKey === 'intermediateConsumption' ||
      dataKey === 'highConsumption'
    ) {
      return (
          <TooltipBody
          high={{
            name: 'Consumo en Punta',
            value: chartInfo?.highConsumption.toLocaleString()
          }}
          intermediate={{
            name: 'Consumo en Intermedia',
            value: chartInfo?.intermediateConsumption.toLocaleString()
          }}
          base={{
              name: 'Consumo en Base',
              value: chartInfo?.baseConsumption.toLocaleString()
          }}
            unit={unit}
            dataTestId={'consumption'}
            label={date}
          />
      );
    }
  }

  return null;
};

export default GdmthDayTooltip;
