import React from 'react'
import { Card } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { CHARTS_COLORS } from '../../../shared/Charts/ChartsColors';
import ChartWrapper from '../../../Dashboard/ChartsContainer/ChartWrapper/ChartWrapper';

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill={CHARTS_COLORS.gray40}
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  ); 
};

const Chart = ({ 
  graphData, 
  chartData, 
  unit = '' }) => {

    const payload = {
      dataKey: chartData.y.dataKey,
      value: chartData.y.name,
      type: 'rect',
      color: CHARTS_COLORS.yellow
   }

    return (
      <ResponsiveContainer height={465} debounce={5} >
        <BarChart
          data={graphData}
          margin={{ top: 2, right: 0, left: 0, bottom: 40 }}
        >
          <CartesianGrid
            vertical={false}
          />
          <XAxis
            dataKey={chartData.x.dataKey}
            name={chartData.x.name}
            interval={0} tick={<CustomizedAxisTick />}
          />
          <YAxis
            dataKey={chartData.y.dataKey}
            name={chartData.y.name}
            tickFormatter={DataFormater}
          />
          <Tooltip 
            contentStyle={{
              backgroundColor: "#0d122e",
              color: "white",
              borderColor: "#454a61",
              borderWidth: "1px",
              borderRadius: "15px",
              boxShadow: "0 0 50px rgba(0, 0, 0, .25)",
            }}
            cursor={{ 
              fill: CHARTS_COLORS.bg_highlight, 
              strokeWidth: 2, 
              fillOpacity:0.6 
            }}/>
          <Bar
            dataKey={chartData.y.dataKey}
            name={chartData.y.name}
            fill={CHARTS_COLORS.yellow}
            radius={[7,7, 0, 0]}
            unit={unit}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }

const ChartCard = ({ loading, data, length, title,  chartData, unit = '' }) => {
  return (
    <Card className="text-secondary bg-transparent">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <ChartWrapper
          loading={loading}
          graphDataLength={length}
        >
          <Chart
            className="pt-6"
            graphData={data}
            chartData={chartData}
            unit={unit}
          />
        </ChartWrapper>
      </Card.Body>
    </Card>
  )
}

const DataFormater = (number) => {
  if(number > 1000000000){
    return (number/1000000000).toString() + ' TWh';
  }else if(number > 1000000){
    return (number/1000000).toString() + ' GWh';
  }else if(number > 1000){
    return (number/1000).toString() + ' MWh';
  }else{
    return number.toString();
  }
}

export default ChartCard;