import { gql } from 'apollo-boost';

const IMPORT_EXCEL_FILE = gql`
  mutation importExcelFile(
    $excelFile: Upload!
  ) {
    importExcelFile(
      input: { excelFile: $excelFile }
    ) {
      url
    }
  }
`;

export default IMPORT_EXCEL_FILE;
