import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';
import Autocomplete from 'react-autocomplete';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Loader from '../../../shared/Loader/Loader';
import CITIES from '../../../../graphql/queries/cities';
import ZIPCODES_AUTOCOMPLETE from '../../../../graphql/queries/zipcodes_autocomplete';
import STATES from '../../../../graphql/queries/states';
import RangeSlider from './RangeSlider/RangeSlider';
import CFE_FEES from '../../../../graphql/queries/cfeFees';
import GATEWAY_INFO from '../../../../graphql/queries/gateway_info';
import ImportClients from '../../ImportClients/ImportClients';
import TotalClients from '../TotalClients/TotalClients';
import ROLES from '../../../../routing/roles';
import ROUTES from '../../../../routing/routes';
import useClientsList from '../useClientsList';
import UserContext from '../../../../contexts/UserContext/UserContext';


const VIEW_TYPE = {
  list: 'list',
  card: 'card',
};

const PAGE_DEFAULTS = {
  page: 1,
  perPage: 25
}

const SORTING_COLUMNS = [
  { key: 'name', text: 'Nombre', column: 'name' },
  { key: 'current_period_avg_kwh_consumption', text: 'Consumo', column:'current_period_avg_kwh_consumption'},
  { key: 'gen_kwh', text: 'Generación', column: 'gen_kwh'},
  { key: 'yield', text: 'Yield', column: 'yield'},
  { key: 'installed_kw', text: 'kWp', column: 'installed_kw'},
  { key: 'is_cfe_interconnected', text: 'Interconectado', column: 'is_cfe_interconnected'},
  { key: 'sfv', text: 'Estatus SFV', column: 'sfv'},
]

function ClientsFilters({ updateFilters }) {
  const { data: usernameGatewayData, loading: usernameGatewayLoading } = useQuery(GATEWAY_INFO);
  const {
    loading,
    pagesInfo,
    stateFilter,
    searchText,
    handleSearchChange,
    minYield,
    maxYield
  } = useClientsList(SORTING_COLUMNS, PAGE_DEFAULTS);
  const [viewType, setViewType] = useState(VIEW_TYPE.list);
  const { data: dataCities, loading: loadingCities } = useQuery(CITIES);
  const { data: dataFee, loading: loadingFee } = useQuery(CFE_FEES);  
  const [getZipcodes, { data: dataZipcodes }] = useLazyQuery(ZIPCODES_AUTOCOMPLETE);
  const [autocompleteItems, setAutocompleteItems] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const { data: dataStates, loading: loadingStates } = useQuery(STATES);
  const MIN_YIELD_VALUE = Math.floor(minYield / 10) * 10;
  const MAX_YIELD_VALUE = Math.ceil(maxYield / 10) * 10;
  const MID_YIELD_VALUE = Math.floor((MIN_YIELD_VALUE + MAX_YIELD_VALUE) / 2);

  const [yieldFilters, setYieldFilters] = useState([0, 100]);

  useEffect(() => {
    const newMinYieldValue = isNaN(minYield) ? 0 : Math.floor(minYield / 10) * 10;
    const newMaxYieldValue = isNaN(maxYield) ? 100 : Math.ceil(maxYield / 10) * 10;
    
    setYieldFilters([newMinYieldValue, newMaxYieldValue]);

    updateFilters((prevState) => ({
      ...prevState,
      filter_by_yield_gteq: newMinYieldValue,
      filter_by_yield_lteq: newMaxYieldValue,
    }));
  }, [minYield, maxYield, updateFilters]);
  
  const handleChange = (e) => {
    const fieldName = e.target.name;
    let fieldValue = e.target.value;
    //this is used because the select option changes boolean values to strings
    if  (fieldValue === 'true' || fieldValue === 'f') {
      fieldValue = true;
    } else if (fieldValue === 'false' || fieldValue === 'f') {
      fieldValue = false;
    }

    updateFilters((prevState) => {
      return { ...prevState, [fieldName]: fieldValue }
    })
  }

  const handleRangeValues = (e, newValues) => {
    const [minValue, maxValue] = newValues;

    if (minValue < maxValue) {
      setYieldFilters([minValue, maxValue]);
    } else {
      setYieldFilters([maxValue, minValue]);
    }
    // Check if min and max value have the default values again to display all clients.
    updateFilters((prevState) => {
        return {...prevState,
          filter_by_yield_gteq: yieldFilters[0],
          filter_by_yield_lteq: yieldFilters[1]
        }
      });
    
  };

  const changeAutocompleteInput = (_e, value) => {
    setSelectedSuggestion(value);
  }

  const updateZipcodeFilter = (selectedZipcode) => {
    setSelectedSuggestion(selectedZipcode);
    updateFilters((prevState) => {
      return { ...prevState, filter_by_zipcode_cont: selectedZipcode }
    })
  }

  const alternativeOptionSelect = (e) => {
    // this is used to cover case when the user clears the input and presses enter
    if (e.which === 13) { // Enter key
      const inputValue = e.target.value;
      // clear zipcode filter
      if (!inputValue.length) {
        updateZipcodeFilter(inputValue);
      }
    }
  }

  useEffect(() => {
    getZipcodes({ variables: { matchZipcode: selectedSuggestion, limitResults: 5 } });
  }, [selectedSuggestion, getZipcodes])

  useEffect(() => {
    if(dataZipcodes) {
      if(dataZipcodes.zipcodesAutocomplete.length) {
        let dataWithTypedZipcode = dataZipcodes.zipcodesAutocomplete;
        if (selectedSuggestion.length && !dataWithTypedZipcode.includes(selectedSuggestion)) {
          dataWithTypedZipcode = [selectedSuggestion].concat(dataWithTypedZipcode);
        }
        setAutocompleteItems(dataWithTypedZipcode);
      } else {
        setAutocompleteItems([selectedSuggestion]);
      }
    }
  }, [dataZipcodes, selectedSuggestion])
  const userContext = useContext(UserContext);

  // const handleViewTypeButton = (type) => {
  //   setViewType(type);
  // };

  const createClientPath = `${ROUTES[1].path}${ROUTES[1].routes[2].path}`
  const hubspotClientPath = `${ROUTES[1].path}${ROUTES[1].routes[4].path}`
  return (
    <>
        <Col className='side-menu bg-darks'>
          <TotalClients totalCount={pagesInfo?.totalCount ?? null} loading={loading}/>
          <ImportClients />
          <Link
            className="btn btn-primary btn-sm mr-2 my-2 w-100 p-2"
            type="button"
            data-testid="client-button"
            to={createClientPath}>Crear Sistema
          </Link>
          {userContext.user.authorityLevel === ROLES['galt'] && <HubspotButton hubspotClientPath={hubspotClientPath} />}
          <SearchBox searchText={searchText} handleSearchChange={handleSearchChange} />
        </Col>
      <Row className='mt-3'>
        <Col>
          <h6 className="text-secondary my-4">Filtrar por:</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          {loadingStates
            ? <Loader message="Cargando estados..." />
            : <Form.Group controlId="filterState" >
                <Form.Control 
                  as="select" 
                  name="filter_by_state_eq" 
                  onChange={handleChange} 
                  data-testid="select-state-filter" 
                  className="select white-form my-2"
                >
                  <option value={''}>Estado</option>
                  {dataStates?.states?.map((state, index) => (
                    <option
                      key={index}
                      value={state.id}
                      style={{ color: state.selected ? 'white' : 'black' }}
                    >
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {loadingCities
            ? <Loader message="Cargando ciudades..." />
            : <Form.Group controlId="filterCity" >
                <Form.Control 
                  as="select" 
                  name="filter_by_city_i_cont" 
                  onChange={handleChange} 
                  data-testid="select-city-filter" 
                  className="select white-form my-2">
                  <option value={'Ciudad'} >Ciudad</option>
                  {stateFilter
                    ? dataCities?.cities?.filter(city => city.state.id === stateFilter)[0]?.cities?.map((city, index) => <option key={index} value={city}>{city}</option>)
                    : dataCities?.cities?.map((city, index) => (
                        <optgroup key={index} label={city.state.name} >
                          {city.cities?.map((city, index) => <option key={index} value={city} data-testid="city-option">{city}</option>)}
                        </optgroup>
                      ))
                  }
                </Form.Control>
              </Form.Group>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="dropdown" >
            <Autocomplete
              wrapperStyle={{ width: '100%' }}
              renderInput={(props) => 
              <Form.Control {...props} 
                className="select white-form my-2"
                placeholder="Código Postal"
                onKeyPress={alternativeOptionSelect} 
                autoComplete="chrome-off" 
                size="sm" 
                data-testid="autocomplete-form"/> }
              renderMenu={(items, _value, style) => {
                return <div data-testid="autocomplete-options" style={{ zIndex: 99 }} children={items} className="dropdown-menu d-block"/>
              }}
              getItemValue={(item) => item}
              items={autocompleteItems}
              renderItem={(item, isHighlighted) =>
                <div key={item} className='text-white dropdown-item' data-testid='dropdown-item'>
                  {item}
                </div>
              }
              value={selectedSuggestion}
              onChange={changeAutocompleteInput}
              onSelect={updateZipcodeFilter}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {loadingFee
            ? <Loader message="Cargando tipo de tarifa..." />
            : <Form.Group controlId="filterFee" >
                <Form.Control 
                  as="select" 
                  name="filter_by_cfe_fee_eq" 
                  onChange={handleChange} 
                  data-testid="select-fee-filter" 
                  className="select white-form my-2">
                  {dataFee.cfeFees.map((cfeFee, index) => <option key={index} value={cfeFee.id}>{cfeFee.feeType === 'No se' ? 'Tarifa de CFE' : cfeFee.feeType}</option>)}
                </Form.Control>
              </Form.Group>
          }
        </Col>
      </Row>
      <Row>
        <Col>
        {usernameGatewayLoading
            ? <Loader message="Cargando estatus..." />
            : <Form.Group controlId="filterStatus" >
            <Form.Control 
              className= "select white-form my-2"
              as="select" 
              name='filter_by_sfv_eq'
              onChange={handleChange} 
              data-testid="select-status-sfv">
                <option value={''} style={{ color:  'black' }}>Estatus SFV</option>
                <option value={true} style={{ color:  'black' }}>Activo</option>
                <option value={false} style={{ color:  'black' }}>Inactivo</option>
            </Form.Control>
          </Form.Group>
        }
        </Col>
      </Row>
      <Row>
        <Col>
          {loading
            ? <Loader message="Cargando Yield..." />
            :
            <Form.Group className="px-2 my-3 text-secondary">
              <Form.Label className="text-center text-secondary">Yield</Form.Label>
              <RangeSlider
                values={yieldFilters}
                min={0}
                name='filter_by_yield_gteq'
                testid="select-yield-range"
                max={MAX_YIELD_VALUE}
                step={1}
                marks={[
                  { value: 0, label: '0' },
                  { value: MID_YIELD_VALUE, label: MID_YIELD_VALUE.toString() },
                  { value: MAX_YIELD_VALUE, label: MAX_YIELD_VALUE.toString() },
                ]}
                handleChange={(e, newValues) => {
                  handleRangeValues(e, newValues);
                }}
              />
            </Form.Group>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="text-align-center text-secondary">Generación</Form.Label>
            <Form.Group as={Row}>
              <Form.Label column sm={2} className="text-secondary mr-3">Min:</Form.Label>
              <Col sm={9}>
                <Form.Control 
                  name="filter_by_gen_kwh_gteq" 
                  data-testid="genkwh-min-filter" 
                  onChange={handleChange} 
                  size="sm"
                  className="white-form"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2} className="text-secondary mr-3 ">Máx:</Form.Label>
              <Col sm={9}>
                <Form.Control 
                  name="filter_by_gen_kwh_lteq" 
                  data-testid="genkwh-max-filter" 
                  onChange={handleChange} 
                  size="sm"
                  className="white-form"/>
              </Col>
            </Form.Group>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

const SearchBox = ({searchText, handleSearchChange}) => {
  return (
    <div className="py-2 d-flex align-items-center">
      <InputGroup className='input white-form'>
        <InputGroup.Prepend >
          <InputGroup.Text className="input-bg">
            <FontAwesomeIcon icon="search" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          placeholder="Buscar"
          value={searchText} 
          onChange={(e) => handleSearchChange(e.target.value)}
          data-testid="search-clients-input"
          size="sm"
          className="input-search-field"
        />
      </InputGroup>
    </div>
  );
  }

const HubspotButton = ({hubspotClientPath}) => {
  return (
    <Link
      className="btn btn-primary btn-sm mr-2 btn-block font-weight-bold mb-4"
      type="button"
      to={hubspotClientPath}>Crear Sistema hubspot
    </Link>
  );
}



export default ClientsFilters;

