import { gql } from 'apollo-boost';

const GATEWAY_STATUS = gql`
  query gatewayStatus($meterGateways: [ID!]!) {
    gatewayStatus(meterGateways: $meterGateways) {
      gatewayId
      readingsAlert
    }
  }
`;

export default GATEWAY_STATUS;
