import { gql } from 'apollo-boost';

const DELETE_CLIENT = gql`
  mutation deleteClient($clientId: ID!) {
    deleteClient(input: { clientId: $clientId }) {
      client {
        name
        email
      }
    }
  }
`;

export default DELETE_CLIENT;
