import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../shared/Loader/Loader';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';

import 'dayjs/locale/es';

import { I_ICON_MESSAGES } from '../../../shared/InfoMessage/messages';
import InfoMessage from '../../../shared/InfoMessage/InfoMessage';

dayjs.extend(relativeTime);
dayjs.locale('es');

const ConsumptionSourceGraph = ({ data, loading, feeType }) => {

  const cfeConsumption = data?.liveConsumption?.cfeConsumption?.at(-2)?.y || 0;
  const solarConsumption = data?.liveConsumption?.solarConsumption?.at(-2)?.y || 0;
  const injectedGeneration = data?.liveConsumption?.injectedGeneration?.at(-2)?.y || 0;

  const cfeNetConsumption = cfeConsumption - injectedGeneration;
  const solarNetConsumption = solarConsumption + injectedGeneration;
  const totalConsumption = cfeNetConsumption + solarNetConsumption;

  const industrialFees = ['PDBT', 'GDBT', 'GDMTH', 'DGMTO', '02', 'DB1', 'DB2'];
  //const domesticFees = [ '01', '1A', '1B', '1C', '1D', '1E', '1F', 'DAC']

  return (
    <Card className="h-100 dark-gradient-bg justify-content-end" width='100%' data-testid="consumptions-by-source" >
      <Card.Body className="d-flex flex-column">
        <Row className='mx-2 mb-5'>
          <Card.Title className="text-secondary mt-1">
            {I_ICON_MESSAGES.consumptionBySource.header} <InfoMessage placement="right" message={I_ICON_MESSAGES.consumptionBySource.message} />
          </Card.Title>
        </Row>
        {loading && <Loader />}
        {!loading && (
          <Row className="mx-0 d-flex flex-grow-1 mt-5">
            <Col className="mx-0 my-1 d-flex flex-column align-items-end h-100">
              <SourceData
                title="CFE"
                value={cfeNetConsumption}
                percentage={cfeNetConsumption / totalConsumption}
                icon="industry"
              />
            </Col>
            <Col className="mx-0 my-1 d-flex flex-column align-items-end h-100">
                <SourceData
                    title="Carga"
                    value={totalConsumption}
                    percentage={totalConsumption / totalConsumption}
                    icon={industrialFees.includes(feeType) ? faBuilding : "home"}
                  />
            </Col>
            <Col className="mx-0 my-1 d-flex flex-column align-items-end h-100">
              <SourceData
                title="Sistema solar"
                value={solarNetConsumption}
                percentage={solarNetConsumption / totalConsumption}
                icon='sun'
              />
            </Col>
          </Row>
          )}
      </Card.Body>
    </Card>
  );
};

export default ConsumptionSourceGraph;

const SourceData = ({ title, value, percentage, icon}) => {
  if (value === null) {
    return (
      <Col className="d-flex flex-column text-center align-items-center">
        <FontAwesomeIcon icon={icon} size="3x" className="mb-3 text-primary" />
        <div className="text-secondary">{title}</div>
        <div className="text-yellow" data-testid="no-available-data">
              n/d
        </div>
      </Col>
    );
  }

  return (
    <Col className="d-flex flex-column text-center align-items-center">
      <FontAwesomeIcon icon={icon} size="3x" className="mb-3 text-primary" />
      <div className='text-secondary'>{title} ({Number((percentage || 0 )*100).toLocaleString()}%)</div>
      <div className='text-yellow'> {Number(value).toLocaleString()} kWh </div>
    </Col>
  );
};
