import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export function FilterProvider({ children }) {
  const [dashboardFilters, setDashboardFilters] = useState({
    channel_eq: '',
    filter_by_city_i_cont: '',
    filter_by_state_eq: '',
    measured_at_gteq: '',
    measured_at_lteq: ''
  })

  return (
    <FilterContext.Provider value={{ dashboardFilters, setDashboardFilters }}>
      {children}
    </FilterContext.Provider>
  );
}

export function useDashboardFilters() {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useDashboardFilters must be used within a FilterProvider');
  }
  return context;
}
