import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

import OPERATIONAL_DATA from '../../../../graphql/queries/operational_data';

dayjs.extend(relativeTime);
dayjs.locale('es');

const formatTime = (value) => {
    if (parseInt(value) === 24) {
      return value;
    }
  
    const hourAndMinutes = `${value} ${ parseInt(value) <= 11 ? 'am' : 'pm'}`
    const [hour, minutes] = hourAndMinutes.split(':');
    const parsedHour = parseInt(hour);
    
    if (hour === '00') {
      return `12:${minutes}`
    }
  
    return parsedHour > 12 ? `${hour - 12}:${minutes}` : `${parsedHour}:${minutes}`;
  }

  
   export const useOperativeReadingsChart = (clientId,  dateFilter, intervalMinutes) => {

    let minAmps = Infinity;
    let maxAmps = -Infinity;
    let minVolts = Infinity;
    let maxVolts = -Infinity;
    let minWatts = Infinity;
    let maxWatts = -Infinity;
    let minPF = Infinity;
    let maxPF = -Infinity;

      const { data, loading, error } = useQuery(OPERATIONAL_DATA, {
        variables: {
        clientId,
        dateFilter,
        intervalMinutes,
        },
      });

      const graphData = useMemo(() => {
        if (!data) {
          return [];
        }
      
        const meterReadingLines = data?.operationalData?.meterReadingLines || [];
      
        const ampMeterDevices = []
        const voltMeterDevices = []
        const wattMeterDevices = []
        const pfMeterDevices = []

        const combinedData = {
          ampData: { meterDevices: ampMeterDevices },
          voltData: { meterDevices: voltMeterDevices },
          wattData: { meterDevices: wattMeterDevices },
          pfData: { meterDevices: pfMeterDevices },
        };
      
        meterReadingLines.map((meterReading, index) => {
          const readingLines = meterReading.readingLines;
          const meterDeviceName = meterReading.meterDeviceName;
      
          const ampsLineData = [];
          const voltsLineData = [];
          const wattsLineData = [];
          const pfLineData = [];

          if (!combinedData.ampData.meterDevices) {
            combinedData.ampData.meterDevices = [];
          }
          combinedData.ampData.meterDevices.push({ name: meterDeviceName, lines: ampsLineData });
          
          if (!combinedData.voltData.meterDevices) {
            combinedData.voltData[meterReading] = [];
          }
          combinedData.voltData.meterDevices.push({ name: meterDeviceName, lines: voltsLineData });

          if (!combinedData.wattData.meterDevices) {
            combinedData.wattData.meterDevices = [];
          }
          combinedData.wattData.meterDevices.push({ name: meterDeviceName, lines: wattsLineData });

          if (!combinedData.pfData.meterDevices) {
            combinedData.pfData.meterDevices = [];
          }
          combinedData.pfData.meterDevices.push({ name: meterDeviceName, lines: pfLineData });

          if (Array.isArray(readingLines)) {
            readingLines.map((readingLine) => {
              readingLine.l.map((line) => {
                const n = line.n;
                const a = line.a;
                const v = line.v;
                const w = line.w;
                const p = line.p;
                const t = readingLine.t;
      
                if (a !== null) {
                  if (!ampsLineData[n]) {
                    ampsLineData[n] = [];
                  }
                  ampsLineData[n].push({ hour: formatTime(t), amps: a });
                  minAmps = Math.min(minAmps, a);
                  maxAmps = Math.max(maxAmps, a);
                }
      
                if (v !== null) {
                  if (!voltsLineData[n]) {
                    voltsLineData[n] = [];
                  }
                  voltsLineData[n].push({ hour: formatTime(t), volts: v });
                  minVolts = Math.min(minVolts, v);
                  maxVolts = Math.max(maxVolts, v);
                }
      
                if (w !== null) {
                  if (!wattsLineData[n]) {
                    wattsLineData[n] = [];
                  }
                  wattsLineData[n].push({ hour: formatTime(t), watts: w });
                  minWatts = Math.min(minWatts, w);
                  maxWatts = Math.max(maxWatts, w);
                }
      
                if (p !== null) {
                  if (!pfLineData[n]) {
                    pfLineData[n] = [];
                  }
                  pfLineData[n].push({ hour: formatTime(t), pf: p });
                  minPF = Math.min(minPF, p);
                maxPF = Math.max(maxPF, p);
                }
              });
            });
          }
        });
    return combinedData;
  }, [data]);
  
  const minValue = [{amps: minAmps}, {volts: minVolts}, {watts: minWatts}, {pf: minPF}];
  const maxValue = [{amps: maxAmps}, {volts: maxVolts}, {watts: maxWatts}, {pf: maxPF}];



  return {
    loading,
    error,
    graphData,
    minValue: minValue,
    maxValue: maxValue
  };
};
