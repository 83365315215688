import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SummaryCard from './SummaryCard';

function SummaryCardsContainer({
    data,
    loading
}) {

  function formatNumber(value) {
    return Math.round(value).toLocaleString()
  }

  return (
    <Row>
      <Col md={3} 
          className="mb-4">
        <SummaryCard
          footer="Solar Systems Operating"
          title={ formatNumber(data?.providerSummary.solarSystems) }
          subtitle={ formatNumber(data?.providerSummary.solarSystemsKw) + " kW"}
          loading={loading}
        />
      </Col>
      <Col md={3} 
          className="mb-4">
        <SummaryCard
          footer="Energy Generation"
          title={ formatNumber(data?.providerSummary.energyGeneration) + " kWh" }
          loading={loading}
        />
      </Col>
      <Col md={3} 
          className="mb-4" >
        <SummaryCard
          footer="Energy Savings"
          title={ "$" + formatNumber(data?.providerSummary.energySavings) + " MXN"}
          loading={loading}
        />
      </Col>
      <Col md={3} 
          className="mb-4">
        <SummaryCard
          footer="CO2 Avoided"
          title={ formatNumber(data?.providerSummary.tonsCo2Avoided) + " tons"}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

export default SummaryCardsContainer;
