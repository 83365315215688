import { gql } from 'apollo-boost';

const SUMMARY = gql`
  query($q: BaseScalar) {
    providerSummary(q: $q) {
      energyGeneration
      energySavings
      solarSystems
      solarSystemsKw
      tonsCo2Avoided
    }
  }
`

export default SUMMARY;
