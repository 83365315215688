import React from 'react';

import Loader from '../../../shared/Loader/Loader';

const TotalClients = ({ totalCount, loading }) => {
  const totalClients = totalCount !== null ? totalCount : '';
  return (
    <div
      className="text-center text-secondary d-flex flex-column justify-content-center align-items-center mb-3"
      data-testid="total-clients"
    >
      {loading && (        
        <div className="py-3">
          <Loader message="Cargando" />
        </div>)}
      {!loading && (
        <>
          <h6>
            Proyectos Totales {totalClients === '' ? 'No Disponibles' : ''}
          </h6>
          <div className='d-flex my-3 total-projects justify-content-center align-items-center'>
            <h2>{totalCount}</h2>
          </div>
        </>
      )}
    </div>
  );
};

export default TotalClients;
