import React, { useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import ACCEPT_INVITATION from '../../graphql/mutations/acceptInvitation';

import {
  handleGeneralInputChange,
} from '../../services/inputs_service/inputs_service';

const ClientInvitationForm = ({getResponse, setSuccesfulResponse, setErrorResponse}) => {
  const { invitationToken } = useParams();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    password: ''
  });

  const handleInputChange = (e) => {
    handleGeneralInputChange(e, setFormData);
  };

  const [createPassword, {loading: mutationLoading}] = useMutation(ACCEPT_INVITATION, {errorPolicy: 'all'});
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      createPassword({
        variables: {
          password: formData.password,
          invitationToken: String(invitationToken)
        }
      })
      .then(res => {
        const succesfulResponseData = res?.data?.acceptInvitationMutation;
        getResponse(succesfulResponseData, setSuccesfulResponse);
      })
      .catch(_err => {
        const errorMsg = 'No se pudo crear la contraseña, intente más tarde';
        getResponse(errorMsg, setErrorResponse);
      })
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      noValidate
      validated={validated}
      role="form"
      data-testid="invitation-form"
    >
      <Form.Group controlId="client-password">
        <Form.Label>Contraseña</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FontAwesomeIcon icon="unlock-alt" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required
            pattern="^(?=.).{6,168}$"
            type="password"
            placeholder="Contraseña"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            data-testid="password-form-input"
          />
          <Form.Control.Feedback type='invalid'>
            Ingrese mínimo 6 caracteres.
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Button
        variant="primary"
        name="submit-password"
        type="submit"
        className="w-100"
        data-testid="set-password-button"
        disabled={mutationLoading}
      >
        Configurar
      </Button>
    </Form>
  )
};

export default ClientInvitationForm;
