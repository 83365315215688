import axios from 'axios';

export const fetchReadings = (meterName, start, end) => {

  // API CALL EXAMPLE
  // https://tools.greenapsis.com/api/readings/v2/meter/GLT54C?fmt=json&start=1679810400000&end=1679896800000
  return axios.get('https://tools.greenapsis.com/api/readings/v2/meter/' + meterName,
                   {
                    params: { fmt: 'json', start: start, end: end },
                    headers: { 'auth-api': process.env.REACT_APP_GREENAPSIS_KEY}
                   })
}
