import React, { useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ROUTES from '../../../routing/routes';
import Button from 'react-bootstrap/Button';
import UserContext from '../../../contexts/UserContext/UserContext';

function ProviderNavbarHeader() {
  const userContext = useContext(UserContext);
  const userName = userContext?.user?.name || 'Guest';
  
  return (
    <Navbar className="align-items-center d-flex " variant='dark'>
      <Navbar.Toggle aria-controls="provider-navbar" />
      <Nav className="flex-grow-1">
        <Nav.Link
          as={Link}
          className="d-flex align-items-center text-secondary text-lg"
          to={ROUTES[1].path + ROUTES[1].routes[0].path}
          data-testid="client-link"
        >
          Clientes
        </Nav.Link>
        <Nav.Link className="d-flex align-items-center text-secondary disabled"> | </Nav.Link>
        <Nav.Link
          as={Link}
          className="d-flex align-items-center text-secondary text-lg"
          to={ROUTES[1].path + ROUTES[1].routes[5].path}
          aria-label="provider-dashboard-link"
        >
          Dashboard
        </Nav.Link>
      </Nav>
      <Nav.Link className='mr-6'>
          <Button variant="link" type="button" className='text-secondary'>
            <FontAwesomeIcon icon="search" />
          </Button>
        </Nav.Link>
        <Nav.Link>
          <Button variant="link" type="button" className='text-secondary'>
          <FontAwesomeIcon icon="bell" />
          </Button>
        </Nav.Link >
      <Nav className="justify-content-end">
      <NavDropdown
        id="user-dropdown"
        title={userName}
        className="navbar-dropdown"
        data-testid="navbar-dropdown"
      >
        <NavDropdown.Item 
          className="d-flex align-items-center"
          onClick={userContext.clearUser}
          data-testid="logout-button">
          Salir
        </NavDropdown.Item>
      </NavDropdown>
      </Nav>
    </Navbar>
  );
}

export default ProviderNavbarHeader;
