import { gql } from 'apollo-boost';

const CITIES = gql`
  query cities {
    cities {
      state {
        name
        id
      }
      cities
    }
  }
`;

export default CITIES;
