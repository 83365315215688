import { gql } from 'apollo-boost';

const STATES = gql`
  query states {
    states {
      name
      id
    }
  }
`;

export default STATES;
