import { gql } from 'apollo-boost';

const GDMTH_DAY_CONSUMPTION = gql`
  query gdmthDayConsumption($clientId: ID!, $startDate: ISO8601Date , $endDate: ISO8601Date ) {
    gdmthDayConsumption(clientId: $clientId, startDate: $startDate, endDate: $endDate) {
      lastUpdateAt
      maxDemand {
        x
        base
        intermediate
        high
      }      
      injectedGeneration {
        x
        base
        intermediate
        high
      }
      cfeConsumption {
        x
        base
        intermediate
        high
      }
      solarConsumption {
        x
        base
        intermediate
        high
      }
    }
  }
`;

export default GDMTH_DAY_CONSUMPTION;
