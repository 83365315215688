import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import PROVIDER_CLIENTS from '../../../graphql/queries/provider_clients';
import { useFilters } from '../../../contexts/ProviderContext/ClientFiltersContext';

const useClientsList = (sortingColumns, pageDefaults) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { filters, setFilters, searchText, setSearchText } = useFilters();

  const [pagesInfo, setPagesInfo] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    pagesCount: 0
  })
  // resets pagination to page 1 after filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [filters])
  const [orderAttributes, setOrderAttributes] = useState({
    column: 'id',
    direction: 'asc'
  });

  const [orderConfig, setOrderConfig] = useState({
    key: '',
    direction: ''
  });

  const { data, loading, fetchMore, error } = useQuery(PROVIDER_CLIENTS, {
    variables: {
      page: pageDefaults.page,
      perPage: pageDefaults.perPage,
      q: {
        ...filters,
        search_clients_cont: searchText,
        s: `${orderAttributes.column} ${orderAttributes.direction}`,
        filter_by_yield_gteq: '',
        filter_by_yield_lteq: ''
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
 
  
  const yieldValues = data?.providerClients?.nodes
    .map((client) => client?.meterGateway?.yieldGen)
    .filter((value) => value !== undefined);

  let minYield;
  let maxYield;

  if (yieldValues) {
    minYield = Math.min(...yieldValues);
    maxYield = Math.max(...yieldValues);
  } 

  const [currentClients, setCurrentClients] = useState([]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchMore({
      variables: {
        page: pageNumber,
        perPage: pageDefaults.perPage,
        q: {
          ...filters,
          search_clients_cont: searchText,
          s: `${orderAttributes.column} ${orderAttributes.direction}`

        }
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) { return prevResult }

        return fetchMoreResult;
      }
    })
  }

  const handleSearchChange = (event) => {
    setCurrentPage(pageDefaults.page);
    setSearchText(event);
  }

  const handleClientsReorder = (event, isColumn, direction = "") => {
    const orderKey = event.currentTarget.dataset.order;
    const category = sortingColumns.find(element => element.key === orderKey).column;

    let directionAttr = 'asc';
    let directionConfig = 'ascending';
    if (isColumn && orderConfig.direction === 'ascending') {
      directionAttr = 'desc';
      directionConfig = 'descending';
    } else if (isColumn === false && direction === 'descending') {
      directionAttr = 'desc';
      directionConfig = 'descending';
    } else if (isColumn === false && direction === 'ascending') {
      directionAttr= 'asc';
      directionConfig = 'ascending';
    }

    fetchMore({
      variables: {
        page: currentPage,
        perPage: pageDefaults.perPage,
        q: {
          ...filters,
          search_clients_cont: searchText,
          s: `${category} ${directionAttr}`
        }
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) { return prevResult }

        return fetchMoreResult;
      }
    })
    setOrderConfig({ key: orderKey, direction: directionConfig });
    setOrderAttributes({ column: category, direction: directionAttr });
  }

  useEffect(() => {
    if (data) {
      const filterByYield = (client) => {
        const clientYield = client.meterGateway?.yieldGen;

        if (
          (clientYield >= filters.filter_by_yield_gteq || filters.filter_by_yield_gteq === '' || clientYield === undefined) &&
          (clientYield <= filters.filter_by_yield_lteq || filters.filter_by_yield_lteq === '' || clientYield === undefined)
        ) {
          return true;
        } else {
          return false;
        }
      };
  
      const filterBySFVEq = (client) => {
        return filters.filter_by_sfv_eq === '' || client.meterGateway?.active === filters.filter_by_sfv_eq;
      };
  
      const filteredClients = data.providerClients.nodes.filter((client) => {
        return filterByYield(client) && filterBySFVEq(client);
      });
      setCurrentClients(filteredClients);
      setPagesInfo({
        hasNextPage: data.providerClients.hasNextPage,
        hasPreviousPage: data.providerClients.hasPreviousPage,
        totalCount: data.providerClients.totalCount,
        pagesCount: data.providerClients.pagesCount
      })
    }
  }, [data, filters, orderAttributes, pageDefaults.perPage, searchText]);
  return {
    currentClients,
    loading,
    currentPage,
    pagesInfo,
    searchText,
    orderConfig,
    handlePageChange,
    handleSearchChange,
    handleClientsReorder,
    setFilters,
    stateFilter: filters.filter_by_state_eq,
    minYield,
    maxYield
  };
}

export default useClientsList;
