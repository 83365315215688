import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { DateForm, InputDateFormGroup } from '../forms';

const OperativeReadingsChartForm = ({
  title,
  inputName,
  labelName,
  inputValue,
  minInputDate,
  testIdValue,
  setFn,
  colResponsiveValue,
}) => {
  const minInputValue = useMemo(() =>dayjs({ minInputDate }).format('YYYY-MM-DD'));
  const maxInputValue = useMemo(() => dayjs(new Date()).format('YYYY-MM-DD'));

  return (
    <DateForm title={title} colResponsiveValue={colResponsiveValue} extraRowClasses="align-items-center">
      <InputDateFormGroup
        labelName={labelName}
        controlId='dailyFormDateFilter'
        inputName={inputName}
        minInputValue={minInputValue}
        maxInputValue={maxInputValue}
        inputValue={inputValue}
        setFn={setFn}
        colResponsiveValue={colResponsiveValue}
        testIdValue={testIdValue}
      />
    </DateForm>
  );
};

export default OperativeReadingsChartForm;