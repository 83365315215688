import React, { useState } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  ReferenceLine,
  BarChart,
} from 'recharts';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { CHARTS_COLORS } from '../../../../shared/Charts/ChartsColors';
import { getConfigPairs, getLegendPayload } from '../../../../shared/sharedFunctions/sharedFunctions';
import ChartLegend from '../../ChartLegend/ChartLegend';
import { REFERENCE_LINES_CONFIGS } from '../../../../shared/Charts/chartsConfig';
import DemandTooltip from '../../ChartTooltips/DemandTooltip/DemandTooltip';
import { Col, Row } from 'react-bootstrap';

dayjs.extend(relativeTime);
dayjs.locale('es');

function GdmthDemand({
  graphData,
  maxValue,
  gdmthDemandConfig,
  hasTotalDevices,  
}) {
  const [disabledKeys, setDisabledKeys] = useState([]);
  const isTotalDemand = gdmthDemandConfig.baseDemand !== undefined;
  const gdmthDemandPairs = getConfigPairs(gdmthDemandConfig);
  const legendPayload = getLegendPayload(gdmthDemandPairs);
  const maxBaseDemand = graphData.maxDemandBase;
  const maxIntDemand = graphData.maxDemandInt;
  const maxHighDemand = graphData.maxDemandHigh;



  const handleDisplay = (dataKey) => {
    if (disabledKeys.includes(dataKey)) {
      setDisabledKeys(disabledKeys.filter(obj => obj !== dataKey));
    } else {
      setDisabledKeys(disabledKeys.concat([dataKey]));
    }
  }

  const dayOfMonth = (date) => {
    return dayjs(date, 'DD/MM/YYYY').format('D');
  };

  const getMonthAndYear = (graphData) => {
    if (graphData.days){
      let value = graphData.days[0].data;
      if (graphData.days.length > 3) {
        value = graphData.days[3].date;
      }
      return dayjs(value, 'DD/MM/YYYY').format('MMMM YYYY');
    }
  }
  return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={graphData.days}
          margin={{ bottom: 20}} 
          syncId="gdmthDemandChartId"
          barGap="0%"
        >
          <XAxis 
            dataKey="date" 
            name="fecha" 
            xAxisId={0} 
            tickFormatter={(value) => dayOfMonth(value)}
            tick={{ fill: '#EDEDED', fontSize: "12px"}}
            label={{ value: `${getMonthAndYear(graphData)}`, position: 'bottom', style: { fill: '#EDEDED', marginBottom: "10px" }}}
          />
          <YAxis
            type="number"
            domain={[0, maxValue]}
            tick={{ fill: '#EDEDED', fontSize: "12px"}}
            tickFormatter={(value) => {
              if (value === 0) {
                return '';
              }
              return value.toLocaleString();
            }}
            label={{ value: 'Kilowatts', angle: -90, position: 'insideLeft', style: { fill: '#EDEDED' } }}
            className='h-100'
          />
          <CartesianGrid stroke="rgba(245,245,245, 0.3)" horizontal={true} vertical={false}/>
            <Legend
              content={
                <ChartLegend
                  symbolType="circle"
                  hasTotalDevices={hasTotalDevices}                
                  isTotalDemand={isTotalDemand}
                  handleDisplay={handleDisplay}
                  disabledKeys={disabledKeys}
                  className='chart-legend '
                />
              }
              payload={legendPayload}
              verticalAlign="top" 
              align="right"
            />
          {hasTotalDevices ? (
            <Tooltip
              cursor={{ fill: CHARTS_COLORS.bg_highlight, strokeWidth: 2 }}
              content={
              <DemandTooltip data-testId="total-tooltip" />}
            />
          ) : (
            <Tooltip
              cursor={{ fill: CHARTS_COLORS.bg_highlight, strokeWidth: 2, fillOpacity:0.6 }}
              content={
                <DemandTooltip
                  chartConfigs={gdmthDemandConfig}
                  data-testId="demand-tooltip"
                />
              }
            />
          )}

          {gdmthDemandPairs
          .filter((pair) => !disabledKeys.includes(pair[0]))
          .map((config, i) => {
            return (
              <Bar
                dataKey={config[0]}
                name={config[1].legendName}
                unit=" kW"
                fill={config[1].color}
                strokeWidth={config[1].strokeWidth}
                stroke={config[1].color}
                key={config[0] + i}
                barSize={graphData?.days?.length < 3 ? config[1].barSize : undefined}
              />
            );
            
          })}
              <ReferenceLine 
                y={maxBaseDemand} 
                label={REFERENCE_LINES_CONFIGS.maxBaseDemand} 
                stroke= {REFERENCE_LINES_CONFIGS.maxBaseDemand.fill}
              />
              <ReferenceLine 
                y={maxIntDemand} 
                label={REFERENCE_LINES_CONFIGS.maxIntDemand} 
                stroke= {REFERENCE_LINES_CONFIGS.maxIntDemand.fill}
              />
              <ReferenceLine 
                y={maxHighDemand} 
                label={REFERENCE_LINES_CONFIGS.maxHighDemand} 
                stroke= {REFERENCE_LINES_CONFIGS.maxHighDemand.fill}
              />
            
        </BarChart>
      </ResponsiveContainer>
  );
}

export default GdmthDemand;