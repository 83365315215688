import React, { useEffect, useState } from 'react';
import Slider from '@material-ui/core/Slider';

const RangeSlider = ({ values, marks = [], min, max, step, handleChange, testid}) => {
  const [ internalValues, setInternalValues ] = useState([min, max]);

  const handleDrag = (e, value) => {
    if (value[0] < value[1]) {
      setInternalValues([value[0], value[1]]);
      handleChange(e, [value[0], value[1]]);

    } else if (value[0] > value[1]) {
      setInternalValues([value[1], value[0]]);
      handleChange(e, [value[1],value[0]]);
    }
    
  };

  useEffect(() => {
    setInternalValues(values);
  }, [values])
  
  return (
    <Slider
      value={internalValues}
      data-testid={testid}
      onChangeCommitted={handleChange}
      onChange={handleDrag}
      valueLabelDisplay="auto"
      aria-labelledby="range-slider"
      step={step}
      min={!isNaN(min) ? min : 0}
      max={!isNaN(max) ? max : 100}
      marks={marks}
      className="text-secondary"
    />
  );
};

export default RangeSlider;
