import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LOGIN from '../../graphql/mutations/login';
import { handleGeneralInputChange, validateInput, EMAIL_REGEX, PASSWORD_REGEX } from '../../services/inputs_service/inputs_service';
import { manageAuth, saveAuthToken } from '../../services/auth_service/auth_service';
import Loader from '../shared/Loader/Loader';

function LoginForm({ history, userContext }) {
  const [login, { loading: loadingMutationResult }] = useMutation(
    LOGIN,
    {
      onError: (err) => { setMutationError(err) },
      onCompleted: (data) => {
        setMutationError(null);
        saveAuthToken(data.login.user.authenticationToken);
        userContext.updateUser(data.login.user);
        manageAuth(data.login.user, history);
      }
    }
  );
  const [mutationError, setMutationError] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [formData, setFormData] = useState({
    "email": "",
    "password": ""
  })

  const handleInputChange = (e) => {
    handleGeneralInputChange(e, setFormData);
  }

  const submitCredentials = (e) => {
    e.preventDefault();
    let validEmail = validateInput(formData["email"], EMAIL_REGEX);
    let validPassword = validateInput(formData["password"], PASSWORD_REGEX);
    let errors = [];
    if (!validEmail) {
      errors.push('Formato de correo invalido.')
    }
    if (!validPassword) {
      errors.push('Formato de contraseña invalido.')
    }
    setValidationErrors(errors);
    if (errors.length) { return }

    login({ variables: { email: formData["email"], password: formData["password"] } })
  }

  if (loadingMutationResult) { return <Loader message="Autenticando" /> }

  return (
    <React.Fragment>
      { mutationError && <Alert variant="danger">{mutationError.message}</Alert> }
      { validationErrors.length > 0 &&
        <Alert variant="danger">
          <ul className="mb-0" >
            {validationErrors.map((err, i) => (<li key={i}>{err}</li>))}
          </ul>
        </Alert>
      }
      <Form onSubmit={submitCredentials} className="d-flex flex-column justify-content-center align-items-center bg-darks" >
        <Form.Group controlId="user-email input">
          <Form.Label className="form-label mb-1">Email</Form.Label>
          <InputGroup className="mb-2 input white-form login-fields">
            <InputGroup.Prepend>
              <InputGroup.Text className="input-bg">
                <FontAwesomeIcon icon="envelope" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              value={formData["email"]}
              onChange={handleInputChange}
              />
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="user-password" className="my-form-group">
          <Form.Label className="form-label mb-1">Contraseña</Form.Label>
          <InputGroup className='input white-form login-fields'>
        <InputGroup.Prepend >
          <InputGroup.Text className="input-bg">
            <FontAwesomeIcon icon="unlock-alt" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="password"
          placeholder="Contraseña"
          name="password"
          value={formData["password"]}
          onChange={handleInputChange}
          data-testid="password-input"
        />
      </InputGroup>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-5">
          Iniciar Sesión
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default LoginForm;