import React, {useState} from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ClientSort = ({ sortingBy = [], sortingByColumn = {}, sortConfig, handleReorder }) => {
  return sortingBy.length !== 0 ? (
    <ClientSortBy
      sortingBy={sortingBy}
      sortConfig={sortConfig}
      handleReorder={handleReorder}
    />
  ) : (
    <ClientSortByColumn
      sortingByColumn={sortingByColumn}
      sortConfig={sortConfig}
      handleReorder={handleReorder}
    />
  );
};

export default ClientSort;

const ClientSortByColumn = ({ sortingByColumn, sortConfig, handleReorder }) => {

  const [isAscending, setIsAscending] = useState(true);

  const handleSortArrow = (e, isColumn, order) => {
    let sortOrder = getSortStatus(order)
    handleReorder(e, isColumn, sortOrder);
  }

  const getSortStatus = (defaultSortValue) => {
    if (sortingByColumn.key === sortConfig.key) {
      let currentAcendingStatus = !isAscending;
      setIsAscending(!isAscending);

      if(currentAcendingStatus) {
        return 'ascending'
      }
      return 'descending'
    }

    setIsAscending(() => {
      return defaultSortValue === 'ascending';
    })
    return defaultSortValue;
  }

  return (
    Object.keys(sortingByColumn).length &&
    <div className="d-flex flex-column align-items-center justify-content-center position-relative" data-testid={sortingByColumn.key}>
      <Button
        variant="link"
        className={`${isAscending && sortingByColumn.key === sortConfig.key ? 'text-secondary' : 'text-secondary'} d-flex p-0 mb-2 position-absolute btn-sm `}
        onClick={(e) => handleSortArrow(e, false, 'ascending')}
        data-order={sortingByColumn.key}
        data-testid='ChevronUp'
      >
        <FontAwesomeIcon icon="chevron-up"/>
      </Button>
      <Button
        variant="link"
        className={`${isAscending || sortingByColumn.key !== sortConfig.key ? 'text-secondary' : 'text-secondary'} d-flex p-0 mt-3 position-absolute  btn-sm`}
        onClick={(e) => handleSortArrow(e, false, 'descending')}
        data-order={sortingByColumn.key}
        data-testid='faChevronDown'
      >
        <FontAwesomeIcon icon="chevron-down"/>
      </Button>
    </div>
  );
};

const ClientSortBy = ({ sortingBy, sortConfig, handleReorder }) => {
  const arrowIcon = sortConfig.direction === 'ascending' ? 'arrow-up' : 'arrow-down';

  return (
    <Row>
      <Col>
        <span className="text-muted font-weight-bold mr-2">Ordernar por:</span>
        {sortingBy.map((category, key) => {
          if (sortConfig.key === category.key) {
            return (
              <Button
                  variant="link"
                  className="py-0"
                  onClick={(e) => handleReorder(e, true)}
                  data-order={category.key}
                  data-testid={category.key}
                  key={category.key}
                >
                  <FontAwesomeIcon icon={arrowIcon}/>
                  <span className="ml-2">{category.text}</span>
                </Button>
            );
          }
          return (
            <Button
                variant="link"
                className="py-0 text-muted font-weight-bold"
                onClick={(e) => handleReorder(e, true)}
                data-order={category.key}
                data-testid={category.key}
                key={category.key}
              >
                {category.text}
              </Button>
          );
        })}
      </Col>
    </Row>
  );
};
