import React from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar
} from 'recharts';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

import { CHARTS_COLORS } from '../../../shared/Charts/ChartsColors';
import TotalConfigTooltip from '../ChartTooltips/TotalConfigTooltip/TotalConfigTooltip';
import ConsumptionConfigTooltip from '../ChartTooltips/ConsumptionConfigTooltip/ConsumptionConfigTooltip';
import { getConfigPairs, getLegendPayload } from '../../../shared/sharedFunctions/sharedFunctions';
import ChartLegend from '../ChartLegend/ChartLegend';

dayjs.extend(relativeTime);
dayjs.locale('es');

function LiveConsumption({
  graphData,
  minValue,
  maxValue,
  liveConsumptionConfig,
  hasTotalDevices
}) {
  const isTotalConsumption = liveConsumptionConfig.cfeConsumption !== undefined;
  const liveConsumptionPairs = getConfigPairs(liveConsumptionConfig);
  const legendPayload = getLegendPayload(liveConsumptionPairs);

  const dayOfMonth = (date) => {
    const [day, month] = date.split('/');
    const dayNumber = parseInt(day, 10);
    return dayNumber
  };

  const getMonthAndYear = (graphData) => {
    if (graphData){
      const [startDay, startMonth, startYear] = graphData[0].x ? graphData[0]?.x.split("/") : graphData[0]?.date.split("/");
      const [endDay, endMonth, endYear] = graphData[0].x ? graphData[graphData.length - 1].x.split("/") : graphData[graphData.length - 1]?.date.split("/");
      const monthOne = dayjs(startMonth, 'MM').format('MMMM');
      const monthLast= dayjs(endMonth, 'MM').format('MMMM')
      
      if (graphData[0]?.date){
        if (startYear === endYear) {
          if (startMonth === endMonth) {
            return monthOne + " " + startYear;
          } else {
            return monthOne + " - " + monthLast + " " + endYear;
          }
        } else {
          return monthOne + " " + startYear + " - " + monthLast + " " + endYear;
        }
      }
    }
  }

  return (
        <ResponsiveContainer width="100%" height={430}>
        <BarChart
          data={graphData}
          margin={{ bottom: 20 }} 
          syncId="liveChartId"
        >
          <XAxis 
            dataKey="date" 
            name="fecha" 
            xAxisId={0} 
            tickFormatter={(value) => dayOfMonth(value)}
            tick={{ fill: '#EDEDED', fontSize: "12px"}}
            label={{ value: `${getMonthAndYear(graphData)}`, position: 'bottom', style: { fill: '#EDEDED', marginBottom: "10px" }}}
          />
          <YAxis
            type="number"
            domain={[minValue, maxValue]}
            tickFormatter={(value) => {
              if (value === 0) {
                return '';
              }
              return value.toLocaleString();
            }}
            tick={{ fill: '#EDEDED', fontSize: "12px"}}
            label={{ value: 'Kilowatt hora', angle: -90, position: 'insideLeft', style: { fill: '#EDEDED' } }}
          />
          <CartesianGrid stroke="rgba(245,245,245, 0.3)" horizontal={true} vertical={false}/>\
            <Legend
              content={
                <ChartLegend
                  symbolType="circle"
                  hasTotalDevices={hasTotalDevices}
                  isTotalConsumption={isTotalConsumption}
                  className='pt-6 chart-legend mb-4'
                  />
              }
              payload={legendPayload}
              verticalAlign="top" 
              align="right"
            /> 
            <Tooltip
              cursor={{ fill: CHARTS_COLORS.bg_highlight, strokeWidth: 2, fillOpacity:0.6 }}
              content={<ConsumptionConfigTooltip
                chartConfigs={liveConsumptionConfig}
                data-testId="cosumption-tooltip"
              />}
            />
          {liveConsumptionPairs.map((config, i) => {
            return (
              <Bar
                dataKey={config[0]}
                name={config[1].legendName}
                unit=" kWh"
                fill={config[1].color}
                strokeWidth={config[1].strokeWidth}
                stroke={config[1].color}
                key={config[0] + i}
                barSize={graphData.length < 3 ? config[1].barSize : undefined}
                stackId="a"
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
  );
}

export default LiveConsumption;