import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import ProviderNavbarHeader from '../shared/ProviderNavbarHeader/ProviderNavbarHeader';
import Footer from '../shared/Footer/Footer';
import PrivateRoute from '../../routing/PrivateRoute';
import Container from 'react-bootstrap/Container';
import Sidebar from '../SideBar/SideBar';
import { Col, Row} from 'react-bootstrap';
import { ActiveKeyProvider } from '../../contexts/ProviderContext/ActiveKeyContext';
import { FilterProvider } from '../../contexts/ProviderContext/DashboardFilterContext';
import { ListFilterProvider } from '../../contexts/ProviderContext/ClientFiltersContext';

function ProviderLayout({ routes, match }) {
  const location = useLocation(); 
  const currentRoute = location.pathname;
  
  const routeParts = currentRoute.split('/');
  const dashboardIndex = routeParts.indexOf('client_dashboard');
  let ID;
  if (dashboardIndex !== -1 && dashboardIndex + 1 < routeParts.length) {
    ID = routeParts[dashboardIndex + 1];
  }
  let sidebar = false;
  if (
    currentRoute === "/provider" || 
    currentRoute === "/provider/dashboard" || 
    currentRoute === "/client" ||
    currentRoute.startsWith('/provider/client_dashboard/')){
    sidebar = true;
  } else {
    sidebar = false;
  }

  let navbar = true;
  if (currentRoute.startsWith('/provider/edit_client/') || currentRoute === '/provider/create'){
    navbar = false
  } else {
    navbar = true;
  }

  return (
    <React.Fragment>
      <ActiveKeyProvider> <FilterProvider> <ListFilterProvider>
        <Container fluid className="gradient-background">
          {sidebar ? 
          <Row className="d-flex">
            <Col className='px-0'>
              <Sidebar clientId={ID} currentPage={currentRoute ? currentRoute : ''} />
            </Col>
            <Col className='content-column'>
              {navbar && <ProviderNavbarHeader />}
              <Container fluid className="mt-5"  data-testid="provider-layout">
                <Switch>
                  {routes.map((route, _i) => (
                    <PrivateRoute
                      path={`${match.path}${route.path}`}
                      exact={route.exact}
                      roleLevel={route.roleLevel}
                      key={route.key}
                      component={route.component}
                    />
                  ))}
                  <Route component={() => <h1>Not Found!</h1>} />
                </Switch>
              </Container>
            </Col>
          </Row> 
          :
          <Row>
              {navbar && <ProviderNavbarHeader />}
              <Container fluid style={{margin: '5% 15%'}}  data-testid="provider-layout">
                <Switch>
                  {routes.map((route, _i) => (
                    <PrivateRoute
                      path={`${match.path}${route.path}`}
                      exact={route.exact}
                      roleLevel={route.roleLevel}
                      key={route.key}
                      component={route.component}
                    />
                  ))}
                  <Route component={() => <h1>Not Found!</h1>} />
                </Switch>
              </Container>
          </Row> 
          }
          <Row className='my-0 py-0'>
            <Container fluid className="bg-darks m-0">
              <Footer className="footer"/>
            </Container>
          </Row>
        </Container>
        </ListFilterProvider></FilterProvider></ActiveKeyProvider>
    </React.Fragment>
  );
}

export default ProviderLayout;
