import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const TooltipBody = ({ base, intermediate, high, unit, dataTestId, label }) => {
  return (
    <div className="custom-tooltip p-2" data-testid={dataTestId}>
      <p className="label text-secondary">{label}</p>
      {high.value !== null && (
        <p className="text-yellow my-1" style={{fontSize: "15px"}} >
          {high.name}: {high.value} {unit}
        </p>
      )}
      {intermediate.value !== null && (
        <p className="text-cyan my-1" style={{fontSize: "15px"}}>
          {intermediate.name}: {intermediate.value} {unit}
        </p>
      )}
      {base.value !== null && (
        <p className="text-blue my-1" style={{fontSize: "15px"}}>
          {base.name}: {base.value} {unit}
        </p>
      )}
      
    </div>
  );
}

const DemandTooltip = ({ payload, active, label }) => {
  if (active) {
    const dataInfo = payload ? (payload[0] !== undefined ? payload[0] : null) : null;
    const chartInfo = dataInfo?.payload ?? null;
    const dataKey = dataInfo?.dataKey ?? null;
    const unit = dataInfo?.unit ?? null;
    const date = dayjs(label, 'DD/MM/YYYY').format('D [de] MMMM, YYYY');

    if (
      dataKey === 'baseDemand' ||
      dataKey === 'intDemand' ||
      dataKey === 'highDemand'
    ) {
      return (
          <TooltipBody
            base={{
              name: 'Demanda Base',
              value: chartInfo?.baseDemand.toLocaleString()
            }}
            intermediate={{
              name: 'Demanda Intermedia',
              value: chartInfo?.intDemand.toLocaleString()
            }}
            high={{
              name: 'Demanda en Punta',
              value: chartInfo?.highDemand.toLocaleString()
            }}
            unit={unit}
            dataTestId={'demanda'}
            label={date}
          />
      );
    }
  }

  return null;
};

export default DemandTooltip;
