import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';
import GATEWAY_ACTIVATION from '../../../graphql/mutations/gateway_activation';
import Loader from '../../shared/Loader/Loader';
import InfoMessage from '../../shared/InfoMessage/InfoMessage';
import ROUTES from '../../../routing/routes';
import ConfirmationModal from '../../shared/ConfirmationModal/ConfirmationModal';

function ServiceStatus({ initialStatus, gatewayId, canEdit, clientId, hasMeterGateway=false }) {
  const [status, setStatus] = useState(initialStatus);
  const [updateStatus, { loading: loadingNewStatus }] = useMutation(GATEWAY_ACTIVATION);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errorInModal, setErrorInModal] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const infoMessage = 'Si el sistema se encuentra inactivo, te recomendamos contactar a tu proveedor.';

  const history = useHistory();

  const handleClose = () => {
    setShowModal(false);
    setErrorInModal('');
    setModalMessage('');
  }

  const handleConfirmation = (newStatus) => {
    updateStatus({ variables: { gatewayId: gatewayId, active: newStatus } })
      .then((res) => {
        setStatus(res.data.activateGatewayMutation.gateway.active);
        setShowModal(false);
      })
      .catch((err) => {
        const error = err.message.replace('GraphQL error: ', '');
        setErrorInModal(error);
    });
  };

  const changeStatus = (newStatus) => {
    const activationMsg = '¿Seguro que quieres activar el gateway?';
    const deactivationMsg = 'Al desactivar la generación, Los paneles solares de este hogar dejarán de generar hasta que sean activados nuevamente. ¿Seguro que lo quieres desactivar?';
    setModalMessage(newStatus ? activationMsg : deactivationMsg);
    setModalTitle(newStatus ? 'Activar Sistema' : 'Desactivar Sistema');
    setShowModal(true);
  }

  const handleEditClient= (event) => {
    event.persist();
    event.stopPropagation();
    history.push(ROUTES[1].routes[3].constructor + clientId);
  };


  const showSettingsMenu = (action, status) => {
    return (
      <>
      <Dropdown className="ml-1" style={{ display: 'flex' }}>
        <Dropdown.Toggle variant="link" className='cog-dropdown' data-testid="dashboard-tool" style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon="cog" />
          <p className='mb-0 ml-2'>Editar</p>
        </Dropdown.Toggle>

        <Dropdown.Menu >
          <Dropdown.Item onClick={() => {changeStatus(status)}} disabled={hasMeterGateway} data-testid="action-button">{action}</Dropdown.Item>
          <Dropdown.Item onClick={handleEditClient}>Editar Cliente</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmationModal show={showModal} handleClose={handleClose} handleConfirmation={handleConfirmation} message={modalMessage} modalTitle={modalTitle} error={errorInModal} inputConfirmation={status}/>
      </>
    )
  }

  if (loadingNewStatus) { return <Loader message="Actualizando..." /> }

    return (
      <>
        {status ? (
          <div className="d-flex mt-4">
            {canEdit && showSettingsMenu('Desactivar Sistema', false)}
          </div>
        ) : (
          <div className="d-flex mt-4">
            {canEdit && showSettingsMenu('Activar Sistema', true)}
          </div>
        )}
      </>
    );
  }

export default ServiceStatus;
