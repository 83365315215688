import React from 'react';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale('es');
  
export default function ProviderSetupTable({ readings }) {
  const reverseReadings = readings.slice().reverse();

  return (
    <Table responsive role="gateway-table" className="table-sm border-bottom-1 text-secondary">
      <thead>
        <tr>
          <th scope="col" className="header border-right">Fecha</th>
          <th scope="col" className="header border-right">nkwh</th>
          <th scope="col" className="header border-right">fkwh</th>
          <th scope="col" className="header border-right">rkwh</th>
          <th scope="col" className="header" width="15"></th>
          <th scope="col" className="header border-right text-right">Línea 1</th>
          <th scope="col" className="header border-right text-right">Línea 2</th>
          <th scope="col" className="header text-right">Línea 3</th>
        </tr>
      </thead>

      <tbody className="table-body text-secondary">
      {reverseReadings.map((r, index) => (
        <tr className="mt-4" key={index}>
          <td className="border-right">{dayjs(r.timestamp).local().format('HH:mm:ss DD-MM-YYYY')}</td>
          <td className="border-right text-monospace">
            <p>{r.nkwh.toLocaleString()}</p>
            <p><small>(Δ {index < (reverseReadings.length - 1) && (r.nkwh - reverseReadings[index + 1].nkwh).toLocaleString()}
              { index === (reverseReadings.length - 1) && "0" })</small></p> 
          </td>
          <td className="border-right text-monospace"> 
            <p>{r.fkwh.toLocaleString()}</p>
            <p><small>(Δ {index < (reverseReadings.length - 1) && (r.fkwh - reverseReadings[index + 1].fkwh).toLocaleString()}
              { index === (reverseReadings.length - 1) && "0" })</small></p></td>
          <td className="border-right text-monospace">
            <p>{r.rkwh.toLocaleString()}</p>
            <p><small>(Δ {index < (reverseReadings.length - 1) && (r.rkwh - reverseReadings[index + 1].rkwh).toLocaleString()}
              { index === (reverseReadings.length - 1) && "0" })</small></p></td>
          <td className="text-right text-monospace">
            <div><small><strong>Amp:</strong></small></div>
            <div><small><strong>Volts:</strong></small></div>
            <div><small><strong>Watts:</strong></small></div>
            <div><small><strong>Pf:</strong></small></div>
          </td>
          <td className= "text-right text-monospace border-right">
            <div>{r.lines[0].amps.toLocaleString()} </div>
            <div>{r.lines[0].volts.toLocaleString()} </div>
            <div>{r.lines[0].watts.toLocaleString()} </div>
            <div>{r.lines[0].pf.toLocaleString()} </div>
          </td>
          <td className= "text-right text-monospace border-right">
            <div>{r.lines[1].amps.toLocaleString()} </div>
            <div>{r.lines[1].volts.toLocaleString()} </div>
            <div>{r.lines[1].watts.toLocaleString()} </div>
            <div>{r.lines[1].pf.toLocaleString()} </div>
          </td>
          <td className= "text-right text-monospace">
            <div>{r.lines[2].amps.toLocaleString()} </div>
            <div>{r.lines[2].volts.toLocaleString()} </div>
            <div>{r.lines[2].watts.toLocaleString()} </div>
            <div>{r.lines[2].pf.toLocaleString()} </div>
          </td>
        </tr>
      ))}  
      </tbody>
    </Table>
    )  
}
