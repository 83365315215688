export const NON_EMPTY_REGEX = /([^\s])/;
export const EMAIL_REGEX = /[^@\s]+@([^@\s]+\.)+[^@\W]+/;
export const PASSWORD_REGEX = /(?=.{6,128})/;

export function handleGeneralInputChange(event, setState, type) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;
  // Check https://reactjs.org/docs/hooks-reference.html#functional-updates
  // or https://reactjs.org/docs/hooks-reference.html#usereducer if forms start having complex states
  let updatedValues;
  if (type === 'boolean') {
    updatedValues = {[name]: value === 'true'}
  } else if (type === 'number' || type === 'ID' || type === 'float') {
    updatedValues = {[name]: isNaN(Number(value)) || value === '' ? '' : Number(value)}
  } else {
    updatedValues = {[name] : value};
  }
  setState(prevState => {
    return {...prevState, ...updatedValues};
  });
}

// inputsObject: { key_1: value_1, key_2: value_2, ... }
export function validateInputs(inputsObject, validationRegex) {
  for(let i of Object.values(inputsObject)) {
    if (!validationRegex.test(i)) {
      return false
    }
  }
  return true;
}

export function validateInput(value, validationRegex) {
  return validationRegex.test(value);
}

export function nullifyObject(objToReplaceEmptyStrings) {
  let newObject = {};
  Object.keys(objToReplaceEmptyStrings).forEach(function(key) {
    if(Array.isArray(this[key])) {
      newObject[key] = nullifyEmptyStrings(this[key]);
    } else if(this[key] === '') {
      newObject[key] = null;
    } else {
      newObject[key] = this[key];
    }

  }, objToReplaceEmptyStrings);

  return newObject;
}

export function nullifyEmptyStrings(objToReplaceEmptyStrings) {
  let newObject = {};
  if(Array.isArray(objToReplaceEmptyStrings)) {
    newObject = objToReplaceEmptyStrings.map(obj => nullifyEmptyStrings(obj));
  } else {
    newObject = nullifyObject(objToReplaceEmptyStrings)
  }

  return newObject;
}
