import { gql } from "apollo-boost";

const PROVIDER_CLIENTS = gql`
  query providerClients($page: Int, $perPage: Int, $q: BaseScalar){
    providerClients(
      page: $page,
      perPage: $perPage,
      q: $q
    ) {
      hasNextPage
      hasPreviousPage
      pagesCount
      totalCount
      nodes {
        id
        name
        email
        meterGateway {
          id
          name
          active
          currentPeriodAvgKwhConsumption
          yieldGen
          kwhGenYesterday
          lowYieldAlert
        }
        home {
          city
          zipcode
          state {
            cfeShortName
          }
        }
        cfeHome {
          installedKw
          cfeInterconnectionAt
        }
      }
    }
  }
`;

export default PROVIDER_CLIENTS;
