import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/react-hooks';

import Paginator from '../../shared/Pagination/Paginator';
import { useHistory } from 'react-router-dom';
import { ClientsCards } from '../ClientsCards/ClientsCards';
import ClientsTable from '../ClientsTable/ClientsTable';
import ClientSort from './ClientSort';
import useClientsList from './useClientsList';
import ConfirmationToast from './../../shared/ConfirmationToast/ConfirmationToast';
import GATEWAY_LOGS from '../../../graphql/queries/gateway_logs';
import GatewayLogsModal from '../GatewayLogsModal/GatewayLogsModal';

const VIEW_TYPE = {
  list: 'list',
  card: 'card',
};

const PAGE_DEFAULTS = {
  page: 1,
  perPage: 25
}

const SORTING_COLUMNS = [
  { key: 'name', text: 'Nombre', column: 'name' },
  { key: 'current_period_avg_kwh_consumption', text: 'Consumo', column:'current_period_avg_kwh_consumption'},
  { key: 'gen_kwh', text: 'Generación', column: 'gen_kwh'},
  { key: 'yield', text: 'Yield', column: 'yield'},
  { key: 'installed_kw', text: 'kWp', column: 'installed_kw'},
  { key: 'is_cfe_interconnected', text: 'Interconectado', column: 'is_cfe_interconnected'},
  { key: 'sfv', text: 'Estatus SFV', column: 'sfv'},
]

function Clients() {
  const history = useHistory();
  const [viewType, setViewType] = useState(VIEW_TYPE.list);
  const [showToast, setShowToast] = useState(false);
  const {
    currentClients,
    loading,
    currentPage,
    pagesInfo,
    orderConfig,
    handlePageChange,
    handleClientsReorder,
  } = useClientsList(SORTING_COLUMNS, PAGE_DEFAULTS);
  const [loadGatewayLogs, { loading: logsLoading, data: logsData }] = useLazyQuery(GATEWAY_LOGS);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const openLogsModal = (e) => {
    const clientId = e.target.closest('.client-data-wrapper').dataset.id;
    setShowLogsModal(true);
    loadGatewayLogs({ variables: { clientId: clientId } });
  }

  const closeLogsModal = () => {
    setShowLogsModal(false);
  }


  useEffect(() => {
    if (history.location.state !== undefined) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [history.location.state])

  const createToastMessage = (state) => {
    const {name, isEdit, isEliminated} = state;
    if (isEdit) {
      return `${name} ha sido ${isEliminated ? 'borrado(a).' : 'actualizado(a).'}`
    }

    return `${name} ha sido dado(a) de alta.`;
  }

  const onToastClose = () => {
    history.replace({ ...history.location, state: undefined });
  }
  return (
    <React.Fragment>
      {viewType !== VIEW_TYPE.list && <ClientSort sortConfig={orderConfig} sortingBy={SORTING_COLUMNS} handleReorder={handleClientsReorder} />}
      <Row className="mt-3">
        <Col className='pr-0'>
          {viewType === VIEW_TYPE.list ? (
            <Container className='client-table-container pl-0' style={{ overflowX: 'scroll' }}>
              <ClientsTable
                clients={currentClients}
                loading={loading}
                sortingColumns={SORTING_COLUMNS}
                sortConfig={orderConfig}
                handleReorder={handleClientsReorder}
                openLogsModal={openLogsModal} />
            </Container>
          ) : (
            <ClientsCards clients={currentClients} loading={loading} openLogsModal={openLogsModal} closeLogsModal={closeLogsModal} />
          )}
          <Row>
            <Col className="d-flex justify-content-end mr-5">
              <Paginator
                totalPages={pagesInfo.pagesCount}
                handleOnPageChangePaginator={handlePageChange}
                currentPage={currentPage}
                itemsPerPage={PAGE_DEFAULTS.perPage}
                totalItems={pagesInfo.totalCount}
                pageRange={5}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          {history.location.state !== undefined && <ConfirmationToast header="Éxito" message={createToastMessage(history.location.state)} isSuccess={true} showToast={showToast} setShowToast={setShowToast} onClose={onToastClose}/>}
        </Col>
      </Row>
      <GatewayLogsModal
        show={showLogsModal}
        handleClose={closeLogsModal}
        loading={logsLoading}
        logs={logsData?.gatewayLogs?.logs || []}
        meterGateway={logsData?.gatewayLogs?.meterGateway} />
    </React.Fragment>
  );
}

export default Clients;
