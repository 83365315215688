import { gql } from 'apollo-boost';

const INVITE_CLIENT = gql`
  mutation inviteClientMutation(
    $clientId: ID!
    $skipInvitationDelivery: Boolean
  ) {
    inviteClientMutation(
      input: { clientId: $clientId, skipInvitationDelivery: $skipInvitationDelivery }
    ) {
      invitationUrl
    }
  }
`;

export default INVITE_CLIENT;
