import React, { useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import UserContext from '../../../contexts/UserContext/UserContext';
import {  NavbarBrand, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function ClientNavbarHeader() {
  const userContext = useContext(UserContext);
  const userName = userContext?.user?.name || 'Guest';

  return (
    <Navbar className="align-items-center justify-content-between px-4">
      <NavbarBrand className="text-secondary">{userName}</NavbarBrand>
      
          <Nav.Link 
            className="text-white"
            onClick={userContext.clearUser}
            data-testid="logout-button"
          >
            Salir
          <Button variant="link" type="button" className='text-secondary'>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Nav.Link >
    </Navbar>
  );
}

export default ClientNavbarHeader;
