import { gql } from 'apollo-boost';

const CFE_FEES = gql`
  query cfeFees {
    cfeFees {
      feeType
      id
    }
  }
`;

export default CFE_FEES;