import { gql } from 'apollo-boost';

const GDMTH_DEMANDS = gql`
  query demandsByDate($clientId: ID!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    demandsByDate(clientId: $clientId, startDate: $startDate, endDate: $endDate) {
      cfeBaseDemand {
        x
        y
    }
    cfeIntDemand {
        x
        y
    }
    cfeHighDemand {
        x
        y
    }
    cfeMaxBaseDemand {
        x
        y
    }
    cfeMaxIntDemand {
        x
        y
    }
    cfeMaxHighDemand {
        x
        y
    }
    totalBaseDemand {
        x
        y
    }
    totalIntDemand {
        x
        y
    }
    totalHighDemand {
        x
        y
    }
    totalMaxBaseDemand {
        x
        y
    }
    totalMaxIntDemand {
        x
        y
    }
    totalMaxHighDemand {
        x
        y
    }
    }
  }
`;

export default GDMTH_DEMANDS;