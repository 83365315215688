import { gql } from 'apollo-boost';

const LIVE_CONSUMPTION = gql`
  query liveConsumption($clientId: ID!, $startDate: ISO8601Date , $endDate: ISO8601Date ) {
    liveConsumption(clientId: $clientId, startDate: $startDate, endDate: $endDate) {
      lastUpdateAt
      injectedGeneration {
        x
        y
      }
      cfeConsumption {
        x
        y
      }
      solarConsumption {
        x
        y
      }
    }
  }
`;

export default LIVE_CONSUMPTION;
