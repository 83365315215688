import React from 'react';
import { Surface, Symbols } from 'recharts';

import InfoMessage from '../../../shared/InfoMessage/InfoMessage';
import { I_ICON_MESSAGES } from '../../../shared/InfoMessage/messages';

const ChartLegend = ({
  payload,
  symbolType = 'circle',
  handleDisplay,
  hasTotalDevices,
  isTotalConsumption,
  disabledKeys,
  operative = false
}) => {
  return (
    <div className='flex-row d-flex flex-wrap justify-content-center mb-5 '>
      {payload.map((entry) => {
        const { dataKey, color, name } = entry;
        const active = disabledKeys !== undefined ? !disabledKeys.includes(dataKey) : true;

        return (
          <span
            key={dataKey}
            className={`${
              active ? 'text-secondary' : 'text-muted'
            } chart-legend mb-3 ml-4`}
            onClick={
              typeof handleDisplay === 'function'
                ? () => handleDisplay(dataKey)
                : undefined
            }
          >
            <Surface
              width={10}
              height={10}
              viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
            >
              <Symbols cx={5} cy={5} type={symbolType} size={50} fill={color} />
            </Surface>
            <span>
              {` ${name}`}
              {!hasTotalDevices && isTotalConsumption && (
                <span className="text-muted">
                  *{' '}
                  <InfoMessage
                    placement="bottom"
                    header={I_ICON_MESSAGES.totalConsumption.header}
                    message={I_ICON_MESSAGES.totalConsumption.message}
                    buttonClassNames={{ margin: 'ml-1' }}
                  />
                </span>
              )}
            </span>
          </span>
        );
      })}
    </div>
  );
};

export default ChartLegend;
