import React, { createContext, useContext, useState } from 'react';

const ListContext = createContext();

export function ListFilterProvider({ children }) {
  const [filters, setFilters] = useState({
    filter_by_city_i_cont: '',
    filter_by_state_eq: '',
    filter_by_yield_gteq: '',
    filter_by_yield_lteq: '',
    filter_by_zipcode_cont: '',
    filter_by_gen_kwh_gteq: '',
    filter_by_gen_kwh_lteq: '',
    filter_by_sfv_eq: '',
    filter_by_cfe_fee_eq: ''
  })

  const [searchText, setSearchText] = useState('');
  return (
    <ListContext.Provider value={{ filters, setFilters, searchText, setSearchText }}>
      {children}
    </ListContext.Provider>
  );
}

export function useFilters() {
  const context = useContext(ListContext);
  if (!context) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
}
