import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../shared/Loader/Loader';
import 'dayjs/locale/es';

import { toCurrency } from '../../../shared/sharedFunctions/sharedFunctions';
import { I_ICON_MESSAGES } from '../../../shared/InfoMessage/messages';
import InfoMessage from '../../../shared/InfoMessage/InfoMessage';

dayjs.extend(relativeTime);
dayjs.locale('es');

const SavingsWithPanels = ({ costProjections, savings, loading }) => {
  const toDayjsDate = (periodDate) => {
    if (periodDate === null) {
      return null;
    }

    const sub = periodDate.split('/');
    return dayjs(sub[2] + '-' + sub[1] + '-' + sub[0]);
  };

  const dataCostProjections = costProjections?.currentPeriodCostsProjections?.costsProjectionsByDay || [];
  const periodStartDate = dataCostProjections.length ? dataCostProjections[0].date : null;
  const periodEndDate = dataCostProjections.length ? dataCostProjections[dataCostProjections.length - 1].date : null;
  const formattedStartDate = periodStartDate ? toDayjsDate(periodStartDate).format('D MMM') : null;
  const formattedEndDate = periodEndDate ? toDayjsDate(periodEndDate).format('D MMM YYYY') : null;
  const nextPaymentWithPanels = costProjections?.currentPeriodCostsProjections?.nextPaymentWithPanels ?? null;
  const nextPaymentWithoutPanels = costProjections?.currentPeriodCostsProjections?.nextPaymentWithoutPanels ?? null;
  const calculatedSavings = nextPaymentWithPanels !== null && nextPaymentWithoutPanels !== null
    ? nextPaymentWithoutPanels - nextPaymentWithPanels
    : null;
  const savingsPercentage = costProjections?.currentPeriodCostsProjections?.savingsPercentage ?? null;
  const co2Avoided = savings?.savingsWithPanels?.tonsCo2Avoided ?? null;
  return (
    <Card className="h-100 dark-gradient-bg justify-content-end" width='100%' data-testid="savings-with-panels">
      <Card.Body className="d-flex flex-column">
        <Row >
          <Col>
            <Card.Title className="text-secondary mt-1">
              {I_ICON_MESSAGES.savings.header} <InfoMessage placement="right" message={I_ICON_MESSAGES.savings.message} />
            </Card.Title>
          </Col>
          <Col>
            <div className="d-flex flex-lg-column text-right" data-testid="next-payment">
              <p className="text-muted mb-0">Tu período de facturación: </p>
              <p className="text-dark-cyan mt-0">
                {(formattedStartDate && formattedEndDate) && `${formattedStartDate} - ${formattedEndDate}`}
                {(!formattedStartDate || !formattedEndDate) && 'n/d'}
              </p>
            </div>
          </Col>
        </Row>
        {loading && <Loader />}
          {!loading && (
            <Row className="mx-0 d-flex flex-grow-1 align-top mt-5">
              <Col className="mx-0 my-1 d-flex flex-column h-100">
                <SavingsData
                  title="Cantidad de ahorro"
                  value={calculatedSavings}
                  variant="cost"
                  icon="dollar-sign"
                />
                </Col>
                <Col className="mx-0 my-1 d-flex flex-column h-100">
                <SavingsData
                  title="Porcentaje de ahorro"
                  value={savingsPercentage}
                  variant="percentage"
                  icon="piggy-bank"
                />
                </Col>
                <Col className="mx-0 my-1 d-flex flex-column h-100">
                <SavingsData
                  title="Emisiones de CO2 evitadas"
                  value={co2Avoided}
                  variant="tons"
                  icon="leaf"
                />
              </Col>
            </Row>
          )}
      </Card.Body>
    </Card>
  );
};

export default SavingsWithPanels;

const SavingsData = ({ title, value, variant, icon }) => {

  if (value === null) {
    return (
      <Col className="d-flex flex-column text-center align-items-center">
        <FontAwesomeIcon icon={icon} size="3x" className="mb-3 text-primary" />
        <div className="text-secondary">{title}</div>
        <div className="text-yellow" data-testid="no-available-data">
              n/d
        </div>
      </Col>
    );
  }
  if (variant === 'cost')  {
    return (
      <Col className="d-flex flex-column text-center align-items-center">
         <FontAwesomeIcon icon={icon} size="3x" className="mb-3 text-primary" />
         <div className="text-secondary ">{title}</div>
        <div className='text-yellow' data-testid="savings-data">
          {toCurrency(value)}
        </div>
        {variant === 'cost' && <p className="text-yellow">pesos</p>}
      </Col>
    );
  }
  else if (variant === 'percentage')  {
    return (
      <Col className="d-flex flex-column text-center align-items-center">
        <FontAwesomeIcon icon={icon} size="3x" className="mb-3 text-primary" />
        <div className="text-secondary ">{title}</div>
        <div className='text-yellow mb-0 ' data-testid="savings-data">
          {`${value}%`}
        </div>
      </Col>
    );
  }
  else
    return (
      <Col className="d-flex flex-column text-center align-items-center">
        <FontAwesomeIcon icon={icon} size="3x" className="mb-3 text-primary" />
        <div className="text-secondary ">{title}</div>
        <div className='text-yellow mb-0' data-testid="savings-data">
          {value.toFixed(1)}
        </div>
        {variant === 'tons' && <p className="text-yellow">toneladas</p>}
      </Col>
    );
  
};