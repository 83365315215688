import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart
} from 'recharts';
import ChartLegend from '../ChartLegend/ChartLegend';
import { getConfigPairs, getLegendPayload } from '../../../shared/sharedFunctions/sharedFunctions';
import InfoMessage from '../../../shared/InfoMessage/InfoMessage';
import { Col, Row, Container, Button } from 'react-bootstrap';

const OperativeReadingsCharts = ({
  title,
  message,
  graphData,
  meterDeviceIndex,
  dataType,
  configs
}) => {
  const [disabledKeys, setDisabledKeys] = useState([]);
  const [showNewXaxis, setShowNewXaxis] = useState(false);
  const lineConfigPairs = getConfigPairs(configs);
  const isOperativeData = configs.l1 !== undefined;
  const legendPayload = getLegendPayload(lineConfigPairs);

  const [refArea, setRefArea] = useState({ refAreaLeft: '', refAreaRight: '' });
  const [zoomData, setZoomData] = useState(null);
  
  const handleDisplay = (dataKey) => {
    if (disabledKeys.includes(dataKey)) {
      setDisabledKeys(disabledKeys.filter(obj => obj !== dataKey));
    } else {
      setDisabledKeys(disabledKeys.concat([dataKey]));
    }
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 800 && showNewXaxis === false) {
        setShowNewXaxis(true);
      } else if (window.innerWidth >= 800 && showNewXaxis) {
        setShowNewXaxis(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [showNewXaxis]);

  const generateTicks = (data) => {
    if (data.length === 0) {
      return [];
    }
  
    const minHour = data[0].h;
    const maxHour = data[data.length - 1].h;
  
    const numTicks = 3;
  
    const tickInterval = Math.ceil((maxHour - minHour) / numTicks);
  
    const ticks = [];
    for (let i = minHour; i <= maxHour; i += tickInterval) {
      ticks.push(i);
    }
  
    return ticks;
  };


  const getDataType = () => {
    if (dataType === 'amps') {
      return 'ampData';
    } else if (dataType === 'pf') {
      return 'pfData';
    } else if (dataType === 'volts') {
      return 'voltData';
    } else if (dataType === 'watts') {
      return 'wattData';
    }
  };
  
  const lineGraphData = (meterDeviceIndex) => {
    let lineGraphData = [];

    const line1 = graphData[getDataType()].meterDevices[meterDeviceIndex].lines[0];
    const line2 = graphData[getDataType()].meterDevices[meterDeviceIndex].lines[1];
    const line3 = graphData[getDataType()].meterDevices[meterDeviceIndex].lines[2];

    for (let i = 0; i < line1.length; i++) {
      lineGraphData.push({
        "h": line1[i].hour,
        "l1": line1[i][dataType],
        "l2": line2[i][dataType],
        "l3": line3[i][dataType],
      });
    }

    return lineGraphData;
  };

  function formatNumber(value) {
    return Number(value).toLocaleString()
  };

  const handleZoom = () => {
    if (refArea.refAreaLeft === refArea.refAreaRight || refArea.refAreaRight === '') {
      setZoomData(null);
      return;
    }
    const data = lineGraphData(meterDeviceIndex);
    const [start, end] = [refArea.refAreaLeft, refArea.refAreaRight].sort();
  
    const zoomedData = data.filter((entry) => {
      const h = entry.h;
      return h >= start && h <= end;
    });
  
    setZoomData(zoomedData);
  };

  const resetZoom = () => {
    setZoomData(null);
    setRefArea({ refAreaLeft: '', refAreaRight: '' });
  };

  
  const [draggingCoords, setDraggingCoords] = useState(null);

  const handleDragStart = (e) => {
    if (e) {
      setRefArea({ refAreaLeft: e.activeLabel });
      setDraggingCoords({ startX: e.chartX });
    }
  };

  const handleDragMove = (e) => {
    if (e && refArea.refAreaLeft && draggingCoords) {
      setRefArea({ ...refArea, refAreaRight: e.activeLabel });
      setDraggingCoords({ ...draggingCoords, endX: e.chartX });
    }
  };

  const handleDragEnd = () => {
    if (draggingCoords) {
      setDraggingCoords(null);
      handleZoom();
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <h5 className="mt-2 mb-3 text-center text-secondary">{`${title} `}
            <InfoMessage className="pl-4" placement="right" message={message} />
          </h5>
        </Col>
      </Row>
      <Row>
         
      </Row>
      <Row>
        <Col>
          <ResponsiveContainer width="100%" height={465} debounce={5}>
            <LineChart  
              data={zoomData || lineGraphData(meterDeviceIndex)}
              margin={{left: -10, right: 0}}
              key={meterDeviceIndex + "-" + dataType}
              syncId="operativeChartId"
              onMouseDown={handleDragStart}
              onMouseMove={handleDragMove}
              onMouseUp={handleDragEnd}
            >
              <XAxis 
                dataKey="h"
                name="horas"
                ticks={
                  showNewXaxis === false
                    ? ['4:00 am', '8:00 am', '12:00 pm', '4:00 pm', '8:00 pm', '24:00']
                    : ['12:00 am', '12:00 pm', '24:00']
                }
                tickFormatter={(value) => {
                  if (value === '24:00') {
                  return '12:00 am';
                } else {
                  return value;
                }
              }}
              tick={{ fill: '#EDEDED', fontSize: "12px"}}
            />
            <YAxis tick={{ fill: '#EDEDED', fontSize: "12px"}}/>
            <CartesianGrid  stroke="rgba(245,245,245, 0.3)" horizontal={true} vertical={false}/>
            <Legend
              verticalAlign="top" height={50}
              content={
                <ChartLegend
                  symbolType="circle"
                  handleDisplay={handleDisplay}
                  isTotalConsumption={isOperativeData}
                  disabledKeys={disabledKeys}
                  hasTotalDevices={true}
                  operative={true}
                />
              }
              payload={legendPayload}
            />
            <Tooltip 
              contentStyle={{
                backgroundColor: "#0d122e",
                color: "white",
                outline: '1px solid rgba(#454a61, 1)',
                borderColor: "#454a61",
                borderRadius: "10px",
                boxShadow: "0 0 50px rgba(0, 0, 0, .25)",
              }}
              formatter = {(value, name, props) => 
              formatNumber(value)}/>
           
              {draggingCoords && (
                <g>
                  <rect
                    x={draggingCoords.startX}
                    y={0}
                    width={draggingCoords.endX - draggingCoords.startX}
                    height={435}
                    fill="rgb(79, 215, 207)"
                    fillOpacity={0.2}
                  />
                </g>
              )}
            {lineConfigPairs
              .filter((pair) => !disabledKeys.includes(pair[0]))
              .map((config) => {
                return (
                  <Line 
                    type="monotone" 
                    dataKey={config[0]} 
                    key={config[1].key}
                    name={config[1].legendName} 
                    stroke={config[1].fill} 
                    dot={false} 
                    strokeWidth={config[1].strokeWidth}/>
                  );
                })}
            </LineChart>
            </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type="button" variant="link" className=" " size="sm" onClick={resetZoom}>
            Reset Zoom
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
  
  export default OperativeReadingsCharts;