import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

import DAILY_CONSUMPTION from '../../../../graphql/queries/daily_consumption';
import { chartMaxValue } from '../../../shared/sharedFunctions/sharedFunctions';
import { currentMonthlyDate } from '../ChartsContainerMockData';

dayjs.extend(relativeTime);
dayjs.locale('es');

const createMinutesInAday = (intervalMinutes) => {
  let minutesInAday = [];

  for (let i = 0; i < 24; i++) {
    let hour = i <= 9 ? `0${i}` : i;
    for (let k = 0; k < 60; k+=intervalMinutes) {
      let minutes = k <= 9 ? `0${k}`: k;
      minutesInAday.push({x: `${hour}:${minutes}`});
    }
  }
  minutesInAday.push({x: '24:00'});
  return minutesInAday;
}

const formatTime = (value) => {
  if (parseInt(value) === 24) {
    return value;
  }

  const hourAndMinutes = `${value} ${ parseInt(value) <= 11 ? 'am' : 'pm'}`
  const [hour, minutes] = hourAndMinutes.split(':');
  const parsedHour = parseInt(hour);
  
  if (hour === '00') {
    return `12:${minutes}`
  }

  return parsedHour > 12 ? `${hour - 12}:${minutes}` : `${parsedHour}:${minutes}`;
}

export const useDailyReadingsChart = (clientId, dateFilter, intervalMinutes = 5 ) => {
  let minValue = Infinity;
  let maxValue = -Infinity;
  const { data, loading, error } = useQuery(DAILY_CONSUMPTION, {
    variables: {clientId, dateFilter, intervalMinutes}
  });
  const graphData = useMemo(() => {
    const source = data?.dailyConsumption?.source || []
    const cfeConsumption = data?.dailyConsumption?.cfeConsumption || [];
    const solarConsumption = data?.dailyConsumption?.solarConsumption || [];
    const injectedGeneration = data?.dailyConsumption?.injectedGeneration || [];
    const combinedData = [];
    if (cfeConsumption.length || solarConsumption.length || injectedGeneration.length) {
      let minutesInAday = createMinutesInAday(intervalMinutes);

      const mappedCfeConsumption = cfeConsumption.map(item => {
        const cfeConsumptionValue = Number((item.y).toFixed(2));

        return {
          x: item.x,
          y: {
            cfeConsumption: cfeConsumptionValue
          }
        }
      });

      const mappedSolarConsumption = solarConsumption.map(item => {
        const solarConsumptionValue = Number((item.y).toFixed(2));

        return {
          x: item.x,
          y: {
            solarConsumption: solarConsumptionValue
          }
        }
      });
      
      const mappedInjectedGeneration = injectedGeneration.map(item => {
        const injectedGenerationValue = Number((item.y).toFixed(2));

        return {
          x: item.x,
          y: {
            injectedGeneration: injectedGenerationValue
          }
        }
      });

      if(source.includes("Solis::V1::ApiService")) { //TODO: this should be a constant in a place where we could save 
        minutesInAday = deleteMinutesBetween(minutesInAday, mappedSolarConsumption[0].x, mappedSolarConsumption.slice(-1)[0].x)
      } 

      const consumptionAndGeneration = [].concat(
        ...mappedCfeConsumption, 
        ...mappedSolarConsumption, 
        ...mappedInjectedGeneration, 
        ...minutesInAday);
    
      const reducedCombinedData = consumptionAndGeneration.reduce((acc, item) => {
        let current = item.x
        if(current in acc) {
          const solarConsumption = acc[current].solarConsumption !== null ? Number(acc[current].solarConsumption) : null;

          acc[current] = {
            ...acc[current],
            ...item.y,
            consumedGeneration: solarConsumption,
          }
        } else {
          acc[current] = {
            cfeConsumption: null,
            injectedGeneration: null,
            solarConsumption: null,
            consumedGeneration: null,
            ...item.y
          }
        }
        return acc;
      }, {});
      
      for (let key in reducedCombinedData) {
        combinedData.push(
          {
            hour: formatTime(key),
            ...reducedCombinedData[key]
          }
        )
      }

      combinedData.sort((a, b) => {
        let [aHour, aMinuteAndStatus] = a.hour.split(':');
        let [bHour, bMinutesAndStatus] = b.hour.split(':');
        let [aMinute, aDayStatus] = aMinuteAndStatus.split(' ');
        let [bMinute, bDayStatus] = bMinutesAndStatus.split(' ')
        aHour = parseInt(aHour);
        bHour = parseInt(bHour);
        aMinute = parseInt(aMinute);
        bMinute = parseInt(bMinute);

        if (aDayStatus === bDayStatus) {
          if (aHour === 12 && aHour > bHour) {
            return -1;
          } else if (aHour !== 12 && bHour === 12) {
            return 1;
          }
        }

        if (aDayStatus < bDayStatus) {
          return -1;
        } else if (aDayStatus > bDayStatus) {
          return 1;
        }

        if (aHour < bHour) {
          return -1;
        }

        if (aHour > bHour) {
          return 1;
        }

        if (aHour === bHour) {
          if (aMinute < bMinute) {
            return -1
          }

          if (aMinute > bMinute) {
            return 1;
          }
        }
        return 0;
      })
    }

    return combinedData;
  }, [data]);


  if (graphData.length) {
    graphData.forEach(data => {
       const consumption = data.solarConsumption + data.cfeConsumption;
       const generation = data.consumedGeneration + data.injectedGeneration;

       minValue = Math.min(minValue, consumption, generation);
       maxValue = chartMaxValue(Math.max(maxValue, consumption, generation));
    })
  }
  return {
    loading,
    error,
    graphData,
    minValue: Math.floor(minValue),
    maxValue: Math.ceil(maxValue),
  };

  function deleteMinutesBetween(minutesInAday, startTime, endTime) {
    const filteredMinutes = minutesInAday.filter((minute) => {
      return minute.x < startTime || minute.x > endTime;
    });
  
    return filteredMinutes;
  }

};