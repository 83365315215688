import React from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar
} from 'recharts';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

import { CHARTS_COLORS } from '../../../shared/Charts/ChartsColors';
import TotalConfigTooltip from '../ChartTooltips/TotalConfigTooltip/TotalConfigTooltip';
import ConsumptionConfigTooltip from '../ChartTooltips/ConsumptionConfigTooltip/ConsumptionConfigTooltip';
import { getConfigPairs, getLegendPayload } from '../../../shared/sharedFunctions/sharedFunctions';
import ChartLegend from '../ChartLegend/ChartLegend';

dayjs.extend(relativeTime);
dayjs.locale('es');

function MonthlyConsumption({
  graphData,
  minValue,
  maxValue,
  hasTotalDevices,
  monthlyConsumptionConfig
}) {
  const isTotalConsumption = monthlyConsumptionConfig.cfeConsumption !== undefined;
  const monthlyConsumptionPairs = getConfigPairs(monthlyConsumptionConfig);
  const legendPayload = getLegendPayload(monthlyConsumptionPairs);

  return (
    <ResponsiveContainer height={430} width='100%' className='pr-0 mr-0'>
      <BarChart
        data={graphData}
        syncId="monthlyChartId"
        width='90%'
        margin={{
          top: 10,
          right: 0,
          left: 20,
          bottom:10
        }}
      >
        <XAxis dataKey="date" name="fecha" xAxisId={0} tick={{ fill: '#EDEDED', fontSize: "14px"}}/>
        <XAxis dataKey="date" name="fecha" xAxisId={1} hide />
        <YAxis
          type="number"
          domain={[minValue, maxValue]}
          tickFormatter={(value) => {
            if (value === 0) {
              return '';
            }
            return value.toLocaleString();
          }}
          
          tick={{ fill: '#EDEDED', fontSize: "12px"}}
          label={{
            height: 30,
            value: 'Kilowatt hora', 
            angle: -90, 
            position: 'insideLeft', 
            style: { fill: '#EDEDED'},
            offset: -5
          }}
        />
        <CartesianGrid  stroke="rgba(245,245,245, 0.3)" horizontal={true} vertical={false}/>
        <Legend
          content={
            <ChartLegend
              symbolType="circle"
              hasTotalDevices={hasTotalDevices}
              isTotalConsumption={isTotalConsumption}
              className='pt-6 chart-legend mb-4'
            />
          }
          payload={legendPayload} 
          verticalAlign="top" 
          align="right"
        />
        {hasTotalDevices && (
          <Tooltip
            cursor={{ fill: CHARTS_COLORS.bg_highlight, strokeWidth: 2, fillOpacity:0.6 }}
            content={
              <ConsumptionConfigTooltip
                chartConfigs={monthlyConsumptionConfig}
                data-testId="cosumption-tooltip"
              />}
          />
        )}
        {!hasTotalDevices && (
          <Tooltip
            cursor={{ fill: CHARTS_COLORS.bg_highlight, strokeWidth: 2 }}
            content={
              <ConsumptionConfigTooltip
                chartConfigs={monthlyConsumptionConfig}
                data-testId="cosumption-tooltip"
              />
            }
          />
        )}
        
        {monthlyConsumptionPairs.map((config, i) => {
          return (
            <Bar
              dataKey={config[0]}
              name={config[1].legendName}
              unit=" kWh"
              fill={config[1].color}
              strokeWidth={config[1].strokeWidth}
              stroke={config[1].color}
              key={config[0] + i}
              barSize={graphData.length < 3 ? config[1].barSize : undefined}
              stackId="a"
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default MonthlyConsumption;
