import { gql } from "apollo-boost";

const GATEWAY_LOGS = gql`
  query gatewayLogs($clientId: ID!) {
    gatewayLogs(clientId: $clientId) {
      meterGateway {
        id
        name
      }
      logs {
        id
        action
        auditedChanges
        createdAt
        changesMadeBy {
          email
        }
      }
    }
  }
`;

export default GATEWAY_LOGS;
