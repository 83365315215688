import { gql } from 'apollo-boost';

const DAILY_CONSUMPTION = gql`
  query dailyConsumption($clientId: ID!, $dateFilter: ISO8601Date, $intervalMinutes: Int) {
    dailyConsumption(clientId: $clientId, dateFilter: $dateFilter, intervalMinutes: $intervalMinutes) {
      lastUpdateAt
      cfeConsumption {
        x
        y
      }
      solarConsumption {
        x
        y
      }
      injectedGeneration {
        x
        y
      }
    }
  }
`;

export default DAILY_CONSUMPTION;