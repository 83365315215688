import React from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from '../../Dashboard/Dashboard';

const ClientDashboard = () => {
  const { clientId } = useParams();

  return <Dashboard clientId={Number(clientId)} canEdit={true} />
}

export default ClientDashboard;
