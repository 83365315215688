import React, { useState, useEffect, useCallback } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Form, Button, Row, Col, Toast, Alert, InputGroup, Tabs, Tab } from "react-bootstrap";
import { handleGeneralInputChange } from "../../services/inputs_service/inputs_service";
import INSTALLATION_SETUP from "../../graphql/mutations/installationSetup";
import GATEWAY_VALIDATION from "../../graphql/mutations/meterGatewayValidation";
import STATES from "../../graphql/queries/states";
import { stripTypenames } from "../../graphql/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PanelsInfoSection, GatewaySection } from "../Provider/ClientForm/ClientFormSections";
import { fetchReadings } from '../../services/greenapsis_service/readings_service';
import ProviderSetupTable from "./ProviderSetupTable";

dayjs.extend(utc);

const ProviderSetupForm = ({
  getResponse,
  setSuccesfulResponse,
  clientData,
  setupToken,
}) => {
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [validatedValidationForm, setValidatedValidationForm] = useState(false);
  const [showValidationToast, setShowValidationToast] = useState(false);
  const [showGatewayInfoToast, setShowGatewayInfoToast] = useState(false);
  const [successInClientByToken, setSucessInClientByToken] = useState(false);
  const [errorValidationResponse, setErrorValidationResponse] = useState("");
  const [errorGatewayInfoResponse, setErrorGatewayInfoResponse] = useState("");
  const succesfulGatewayInfoResponse = 'Se guardaron los cambios.';
  const [meterReadings, setMeterReadings] = useState({});
  const [meterGateways, setMeterGateways] = useState([]);
  const [newMeterGateway, setNewMeterGateway] = useState({ name: '', key: '' });
  const [gatewayError, setGatewayError] = useState();
  const { data: dataStates } = useQuery(STATES);
  const [setupMutation] = useMutation(INSTALLATION_SETUP, { errorPolicy: "all", });
  const [validateGatewayMutation, { loading: validationLoading }] = useMutation(GATEWAY_VALIDATION, { errorPolicy: "all", });
  const [clientAttributes, setClientAttributes] = useState({
    name: "",
    email: "",
  });
  const states = dataStates?.states || [];
  const [homeAttributes, setHomeAttributes] = useState({
    street: "",
    externalNumber: "",
    neighborhood: "",
    zipcode: "",
    city: "",
    stateId: "",
    latitude: 0,
    longitude: 0,
  });

  const [cfeHomeAttributes, setCfeHomeAttributes] = useState({
    cfeName: "",
    cfeRpu: "",
    cfeFeeId: "",
    installedKw: "",
  });

  const [meterDevicesAttributes, setDeviceAttributes] = useState([]);

  const [tokenGreenapsis, setGreenapsisToken] = useState({activationCode: ""});

  const refetchReadings = useCallback(
    async (devicesAttributes) => {
      // * 1000 to turn into milliseconds
      const startDay = dayjs().startOf('day').utc().unix() * 1000;
      const endDay = dayjs().endOf('day').utc().unix() * 1000;
      for (const device of devicesAttributes) {
        try {
          const resp = await fetchReadings(device.name, startDay, endDay)
          const readings = resp.data.data;
          // NOTE: timestamps of readings come in UTC
          setMeterReadings(prevState => {
            return { ...prevState, [device['name']]: readings }
          })
        } catch {
          setMeterReadings(prevState => {
            return { ...prevState, [device['name']]: [] }
          })
        }
      }
    },
    [],
  )

  useEffect(() => {
    if (clientData) {
      const response = stripTypenames(clientData);

      setClientAttributes((prevState) => {
        const clientResp = response.client;
        const { setupToken, ...noSetupToken } = clientResp;
        return { ...prevState, ...noSetupToken };
      });
      setHomeAttributes((prevState) => {
        return { ...prevState, ...response.home };
      });

      setCfeHomeAttributes((prevState) => {
        return { ...prevState, ...response.cfeHome };
      });
      
      setMeterGateways(response.meterGateways || []);
      setDeviceAttributes(response.meterDevices);
      refetchReadings(response.meterDevices);

      if (response.cfeHome && response.meterGateways.length && response.meterDevices.length) {
        setSucessInClientByToken(true);
      }
    }
  }, [clientData, refetchReadings]);
  useEffect(() => {
    if (meterDevicesAttributes.length) {
      const fetchData = () => refetchReadings(meterDevicesAttributes)
      const interval = setInterval(fetchData, 60000)
  
      return () => {
        clearInterval(interval);
      }
    }
  }, [meterDevicesAttributes, refetchReadings]);

  const getStateByStateId = (stateId) => {
    let st = states.find((s) => {
      return s.id === stateId;
    });
    return st?.name;
  };
  const buildDirectionAsLabel = (clientData) => {
    return clientData.home.street + " " +
           clientData.home.externalNumber + ", " +
           clientData.home.neighborhood + " " +
           clientData.home.city + ", " +
           getStateByStateId(clientData.home.stateId) + ". CP. " +
           clientData.home.zipcode;
  }
  const saveGateWayInfo = () => {
    const formattedMeterGateways = meterGateways.filter(item => item.name !== '' && item.key !== '');

    setupMutation({
      variables: {
        setupToken: setupToken,
        meterGateways: formattedMeterGateways.map(item => ({ ...item, meterDevices: item.meterDevices?.map(({ note, ...rest }) => rest) })),
        installedKw: cfeHomeAttributes.installedKw,
      },
    })
      .then((res) => {
        setValidated(false);
        setShowGatewayInfoToast(true);
        setSuccess(true);
        setErrorGatewayInfoResponse('');
      })
      .catch((err) => {
        const grapqhlErrors = err?.graphQLErrors || [];
        const errMsg = grapqhlErrors.length ? grapqhlErrors[0].message : '';
        const errorMsg =`Lo sentimos no se pudo editar la información, intente más tarde. ${errMsg}`;
        setErrorGatewayInfoResponse(errorMsg);
        setShowGatewayInfoToast(true);
        setSuccess(false);
      });
  }

  const setMeterGateway = (index, meterGateway) => {
    const copy = [...meterGateways];
    copy[index] = meterGateway;
    setMeterGateways(copy);
  }

  const validateGateway = () => {
    validateGatewayMutation({
      variables: {
        setupToken: setupToken,
        activationCode: tokenGreenapsis.activationCode,
      },
    })
      .then((res) => {
        const succesfulResponseData = res?.data?.meterGatewayValidation;
        if(succesfulResponseData.validated){
          getResponse(succesfulResponseData, setSuccesfulResponse);
        }else{
          const errorMsg = `Token de instalación no valido`;
          setErrorValidationResponse(errorMsg);
          setShowValidationToast(true);
        }
      })
      .catch((_err) => {
        const errorMsg = `Lo sentimos ha ocurrido un error con la validación del token, intente más tarde.`;
        setErrorValidationResponse(errorMsg);
        setShowValidationToast(true);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      saveGateWayInfo();
    }
  };

  const handleSubmitValidation = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidatedValidationForm(true);
    } else {
      validateGateway();
    }
  };

  const displayValidateForm = () => {
    if (success || successInClientByToken) {
      return <ValidateTokenForm
                handleSubmit={handleSubmitValidation}
                setGreenapsisToken={setGreenapsisToken}
                loading={validationLoading} error={errorValidationResponse}
                showToast={showValidationToast}
                setShowToast={setShowValidationToast}
                validated={validatedValidationForm} />
    }
    return null;
  }

  const handleAddMeterGateway = () => {
    if (newMeterGateway.name === '' && newMeterGateway.key === '') {
      setMeterGateways([...meterGateways, newMeterGateway]);
      setNewMeterGateway({ name: '', key: '' });
    }
  };
  return (
    <div className="mb-3 text-secondary container">
      <Form
        noValidate
        validated={validated}
        role="form"
        onSubmit={handleSubmit}
        data-testid="gateway-form"
      >
        <Row className="my-4">
          <Col>
            <h4>Custom ID</h4>
            <p>{clientData?.client?.customId || 'No asignado'}</p>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <h4>Nombre del Cliente</h4>
            <p data-testid="client-name">{clientData?.client?.name}</p>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <h4>Dirección</h4>
            <p>{buildDirectionAsLabel(clientData)}</p>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <h4>Paneles - Información</h4>
            <PanelsInfoSection
              cfeHomeAttributes={cfeHomeAttributes}
              setCfeHomeAttr={setCfeHomeAttributes}
              requiredField={true}
            />
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <h4>Gateway - Información</h4>
            {meterGateways.map((g, index) => (
              <GatewaySection
                isEdit={false}
                meterGateway={g}
                setMeterGateway={setMeterGateway}
                hideFields={{ positiveSign: true }}
                hideGatewayFields={{ cfeInterconnectionAt: true }}
                requiredFields={[{gatewayName:true, gatewayToken:true}]}
                setDeviceAttributes={setDeviceAttributes}
                setGatewayError={setGatewayError}
                index={index}
                key={index}
              />
            ))}
          </Col>
        </Row>
        <Button className="btn-outline" onClick={handleAddMeterGateway}>Agregar Medidor</Button>
        <Form.Row className="d-flex justify-content-end">
          {displayToast(showGatewayInfoToast, setShowGatewayInfoToast, errorGatewayInfoResponse.length ? 'Error' : 'Éxito', errorGatewayInfoResponse.length ? errorGatewayInfoResponse : succesfulGatewayInfoResponse)}
          <Col md={3}>
          <Button
              variant="primary"
              type="submit"
              name="createClient"
              className="submit-button mt-4 inline-block w-100 mb-2"
              id="formCreateClientButton"
              disabled={gatewayError}
          >
            Guardar
          </Button>
        </Col>
      </Form.Row>
    </Form>
    {displayValidateForm()}
    <Tabs id="meterReadingContainer"  data-testid="meter-reading-container" >
      {Object.keys(meterReadings).map((key) => {
        return (
          <Tab
            key={key}
            eventKey={key}
            title={key}
            className="border-left border-right border-bottom rounded-bottom p-4 px-2">
              <div className="container overflow-auto" style={{maxHeight:"500px"}}>
                <ProviderSetupTable readings={meterReadings[key]} className="w-50"
                />
              </div>
          </Tab>
      )})}
    </Tabs> 
   </div> 
  );
};

export default ProviderSetupForm;

const ValidateTokenForm = ({setGreenapsisToken, handleSubmit, loading, showToast, setShowToast, error, validated}) => {
  const sinapsisTokenValidation = () => {
    return (
      <div className="pt-3">
        <Alert className="warning-alert">
          <div className="container d-flex justify-content-center">
            <div className="row align-items-center">
              <p>
                Contacte al soporte-Greenapsis +52 81 23 40 83 43 para validar
                que la instalación de los medidores está correcta, y ellos le
                proporcionarán un código numérico que debe introducirse en este
                nuevo campo (El código serán 6 caracteres alfanuméricos).
              </p>
            </div>
          </div>
        </Alert>
        <Form.Row>
          <Form.Group as={Col} md={6} controlId="formGridFullName">
            <Form.Label>Código de validación de instalación</Form.Label>
            <InputGroup className="mb-3 mute-form">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FontAwesomeIcon icon="keyboard" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                required
                name="activationCode"
                placeholder="Código de validación de instalación"
                onChange={(e) =>
                  handleGeneralInputChange(e, setGreenapsisToken)
                }
                data-testid="token-sinapsis"
                className="rounded-right"
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingrese el código de instalación proporcionado por Greenapsis
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form.Row>
      </div>
    );
  };

  return (
    <Form
      noValidate
      validated={validated}
      role="form"
      onSubmit={handleSubmit}
      data-testid="validation-form"
    >
      {sinapsisTokenValidation()}
      <Form.Row className="justify-content-end form-group">
        {displayToast(showToast, setShowToast, 'Error', error)}

        <Col md={3} className="text-align-right">
          <Button
            type="submit"
            name="createClient"
            className="btn-primary mt-4 inline-block w-100 mb-2"
            data-testid="validate-button"
            id="formCreateClientButton"
            disabled={loading}
          >
            Validar Token
          </Button>
        </Col>
      </Form.Row>
    </Form>
  )
}

const displayToast = (showToast, setShowToast, title, message) => {
  return (
    <Toast
      show={showToast}
      onClose={() => {
        setShowToast(false);
      }}
      delay={3000}
      autohide
      className="create-toast position-fixed"
    >
      <Toast.Header className={`${title === 'Error' ? "bg-danger": "bg-success"}`}>
        <img
          src="holder.js/20x20?text=%20"
          className="mr-2"
          alt=""
        />
        <strong className="mr-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body
          className="p-3 text-wrap"
          data-testid="alert-message"
        >
          <div>{message}</div>
        </Toast.Body>
    </Toast>
  )
}



