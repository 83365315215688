import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

function Loader({ message }) {
  return (
    <Row data-testid="loader" className="m-0">
      <Col className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2 text-muted loader-text">{message}</span>
      </Col>
    </Row>
  )
}

export default Loader;