import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoginForm from './LoginForm';
import UserContext from '../../contexts/UserContext/UserContext';
import { manageAuth } from '../../services/auth_service/auth_service';
import { homeLogo, homeLogoAlt } from '../shared/whiteLabel/whiteLabel';

function LoginPage({ history }) {
  const userContext = useContext(UserContext);

  const redirectUser = () => {
    manageAuth(userContext.user, history)
  }

  return (
    <Container fluid className="d-flex flex-column align-items-center my-auto login-form-container background" >
      <Row >
        <Col className="d-flex flex-column justify-content-center mt-5">
          <Row>
            <Col className="text-center my-3">
              <img src={homeLogo()} alt={homeLogoAlt()} className="home-logo" />
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="text-center mt-4">
              <div className='mb-3'><h5 >Bienvenido</h5></div>
              <div><h3 className="text-primary">Iniciar Sesión</h3></div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="mt-4">
          { userContext.user &&
              <div className="text-center mt-5">
                <p>Continuar como: </p>
                <Button variant="primary" onClick={redirectUser} > { userContext.user.name || userContext.user.email }</Button>
              </div>
          }
          { !userContext.user && <LoginForm history={history} userContext={userContext} /> }
        </Col>
      </Row>

      <Row className="align-self-end h-25">
        <Col className="d-flex flex-column mr-5">
          <Link to="#">
            <Button className="btn-outline fixed-bottom start-100 px-3 m-4 help-btn">
                <FontAwesomeIcon icon="comment-dots" className="mr-2" />
                Ayuda
            </Button> 
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
