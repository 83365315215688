import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
} from 'recharts';

import TotalConfigTooltip from '../ChartTooltips/TotalConfigTooltip/TotalConfigTooltip';
import ConsumptionConfigTooltip from '../ChartTooltips/ConsumptionConfigTooltip/ConsumptionConfigTooltip';
import ChartLegend from '../ChartLegend/ChartLegend';
import { getConfigPairs, getLegendPayload } from '../../../shared/sharedFunctions/sharedFunctions';

const DailyReadingsChart = ({
  graphData,
  minValue,
  maxValue,
  configs,
  hasTotalDevices
}) => {
  const [disabledKeys, setDisabledKeys] = useState([]);
  const [showNewXaxis, setShowNewXaxis] = useState(false);
  const isTotalConsumption = configs.cfeConsumption !== undefined;
  const areaConfigPairs = getConfigPairs(configs);
  const legendPayload = getLegendPayload(areaConfigPairs);
  
  const handleDisplay = (dataKey) => {
    if (disabledKeys.includes(dataKey)) {
      setDisabledKeys(disabledKeys.filter(obj => obj !== dataKey));
    } else {
      setDisabledKeys(disabledKeys.concat([dataKey]));
    }
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 800 && showNewXaxis === false) {
        setShowNewXaxis(true);
      } else if (window.innerWidth >= 800 && showNewXaxis) {
        setShowNewXaxis(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [showNewXaxis]);

  return (
    <ResponsiveContainer width='100%' height={430}  debounce={5}>
      <AreaChart
        data={graphData}
        syncId="dailyChartId"
      >
        <XAxis
          dataKey="hour"
          name="hora"
          tick={{ fill: '#EDEDED', fontSize: "14px"}}
          ticks={
            showNewXaxis === false
              ? ['4:00 am', '8:00 am', '12:00 pm', '4:00 pm', '8:00 pm', '24:00']
              : ['12:00 am', '12:00 pm', '24:00']
          }
          tickFormatter={(value) => {
            if (value === '24:00') {
              return '12:00 am';
            } else {
              return value;
            }
          }}
        />
        <YAxis
          type="number"
          domain={[minValue, maxValue]}
          tickFormatter={(value) => {
            if (value === 0) {
              return '';
            }
            return value.toLocaleString();;
          }}
          tick={{ fill: '#EDEDED', fontSize: "12px"}}
          label={{ value: 'Kilowatt hora', angle: -90, position: 'insideLeft', style: { fill: '#EDEDED' } }}
        /> 
        <CartesianGrid  stroke="rgba(245,245,245, 0.3)" horizontal={true} vertical={false}/>
        <Legend
          content={
            <ChartLegend
              symbolType="circle"
              handleDisplay={handleDisplay}
              hasTotalDevices={hasTotalDevices}
              isTotalConsumption={isTotalConsumption}
              disabledKeys={disabledKeys}
              className='pt-6 chart-legend mb-4'
            />
          }
          payload={legendPayload}
          verticalAlign="top" 
          align="right"
        />
          {hasTotalDevices && <Tooltip content={<TotalConfigTooltip />} />}
          {!hasTotalDevices && (
            <Tooltip
              content={<ConsumptionConfigTooltip chartConfigs={configs} />}
            />
          )}
        
        {areaConfigPairs
          .filter((pair) => !disabledKeys.includes(pair[0]))
          .map((config) => {
            return (
              <Area
                key={config[0]}
                dataKey={config[0]}
                name={config[1].legendName}
                stroke={config[1].color}
                dot={false}
                stackId="1"
                connectNulls={true}
                fillOpacity={config[1].opacity}
                fill={config[1].color}
                unit=" kW"
                type="monotone"
              />
            );
          })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DailyReadingsChart;
