import React, { useState } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar
} from 'recharts';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

import { CHARTS_COLORS } from '../../../shared/Charts/ChartsColors';
import GdmthDayTooltip from '../ChartTooltips/GdmthDayTooltip/GdmthDayTooltip';
import { getConfigPairs, getLegendPayload } from '../../../shared/sharedFunctions/sharedFunctions';
import ChartLegend from '../ChartLegend/ChartLegend';

dayjs.extend(relativeTime);
dayjs.locale('es');

function GdmthDayConsumption({
  graphData,
  minValue,
  maxValue,
  gdmthDayConsumptionConfig,
  hasTotalDevices
}) {
  const [disabledKeys, setDisabledKeys] = useState([]);
  const isTotalConsumption = gdmthDayConsumptionConfig.cfeConsumption !== undefined;
  const gdmthDayConsumptionPairs = getConfigPairs(gdmthDayConsumptionConfig);
  const legendPayload = getLegendPayload(gdmthDayConsumptionPairs);

  const handleDisplay = (dataKey) => {
    if (disabledKeys.includes(dataKey)) {
      setDisabledKeys(disabledKeys.filter(obj => obj !== dataKey));
    } else {
      setDisabledKeys(disabledKeys.concat([dataKey]));
    }
  }
  const dayOfMonth = (date) => {
    const [day, month, year] = date.split('/');
    return day
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    const [day, month, year] = payload.value.split('/');
    dayjs(day, 'DD').format('D');
    if (day % 5 === 0) { 
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fill={CHARTS_COLORS.gray40}
            fontSize={12}
          >
            {day}
          </text>
        </g>
      );
    } else {
      return null; 
    }
  };
  const getMonthAndYear = (graphData) => {
    if (graphData && graphData.length > 0){
      const [startDay, startMonth, startYear] = graphData[0].date.split("/");
      const [endDay, endMonth, endYear] = graphData[graphData.length - 1].date.split("/");
      const monthOne = dayjs(startMonth, 'MM').format('MMMM');
      const monthLast= dayjs(endMonth, 'MM').format('MMMM')

      if (startYear === endYear) {
        if (startMonth === endMonth) {
          return monthOne + " " + startYear;
        } else {
          return monthOne + " - " + monthLast + " " + endYear;
        }
      } else {
        return monthOne + " " + startYear + " - " + monthLast + " " + endYear;
      }
    }
    return "Undefined date";
  }
  

  return (
        <ResponsiveContainer width="100%" height={430} style={{marginLeft: '-30px'}}>
          <BarChart
            data={graphData}
            margin={{ bottom: 20}} 
            syncId="gdmthConsumptionChartId"
          >
            <XAxis 
              dataKey="date" 
              name="fecha" 
              xAxisId={0} 
              tickFormatter={(value) => dayOfMonth(value)}
              tick={<CustomizedAxisTick />}
              label={{ value: `${getMonthAndYear(graphData)}`, position: 'bottom', style: { fill: '#EDEDED', marginBottom: "10px" }}}
            />
            <YAxis
              type="number"
              domain={[minValue, maxValue]}
              tickFormatter={(value) => {
                if (value === 0) {
                  return '';
                }
                return value.toLocaleString();
              }}
              tick={{ fill: '#EDEDED', fontSize: "12px"}}
              label={{ value: 'Kilowatt hora', angle: -90, position: 'insideLeft', style: { fill: '#EDEDED' } }}
            />
            <CartesianGrid stroke="rgba(245,245,245, 0.3)" horizontal={true} vertical={false}/>
              <Legend
              content={
                <ChartLegend
                  symbolType="circle"
                  hasTotalDevices={hasTotalDevices}
                  isTotalConsumption={isTotalConsumption}
                  handleDisplay={handleDisplay}
                  disabledKeys={disabledKeys}
                  className='pt-6 chart-legend mb-2'
                />
              }
              payload={legendPayload}
              verticalAlign="top" 
              align="right"
            />
            {hasTotalDevices ? (
              <Tooltip
                cursor={{ fill: CHARTS_COLORS.bg_highlight, strokeWidth: 2 }}
                content={<GdmthDayTooltip data-testId="total-tooltip" />}
              />
            ) : (
              <Tooltip
                cursor={{ fill: CHARTS_COLORS.bg_highlight, strokeWidth: 2, fillOpacity:0.6 }}
                content={
                  <GdmthDayTooltip
                    chartConfigs={gdmthDayConsumptionConfig}
                    data-testId="consumption-tooltip"
                  />
                } 
              />
            )}
        
            {gdmthDayConsumptionPairs
            .filter((pair) => !disabledKeys.includes(pair[0]))
            .map((config, i) => {
              return (
                <Bar
                  dataKey={config[0]}
                  name={config[1].legendName}
                  unit=" kWh"
                  fill={config[1].color}
                  strokeWidth={config[1].strokeWidth}
                  stroke={config[1].color}
                  key={config[0] + i}
                  barSize={graphData.length < 3 ? config[1].barSize : undefined}
                  stackId="a"
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
  );
}

export default GdmthDayConsumption;