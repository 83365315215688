import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';

import { FEE_TYPES } from '../../shared/CfeFees/feeTypes';
import CurrentPeriodCostsBreakdown from './CurrentPeriodCostsBreakdown/CurrentPeriodCostsBreakdown';
import CurrentPeriodCostsProjections from './CurrentPeriodCostsProjections/CurrentPeriodCostsProjections';
import COSTS_BREAKDOWN_DATA from '../../../graphql/queries/costs_breakdown';
import CURRENT_PERIOD_COSTS_PROJECTIONS from '../../../graphql/queries/current_period_costs_projections';

const SavingsContainer = ({ clientId, feeType='' }) => {
  const [costsBreakdownPeriod, setCostsBreakdownPeriod] = useState(0)
  const [period, setPeriod] = useState(0)
  const { data: dataCostsBreakdown, loading: loadingCostsBreakdown } = useQuery(COSTS_BREAKDOWN_DATA, {
    variables: { clientId, period: costsBreakdownPeriod }
  });
  const { data: dataCostsProjections, loading: loadingCostsProjections } = useQuery(CURRENT_PERIOD_COSTS_PROJECTIONS, {
    variables: { clientId, period }
  });

  return (
    <>
      {feeType === FEE_TYPES['gdmth'] && (
        <Row className="d-flex mt-3" data-testid="savings-container">
          <Col lg={12}>
            <CurrentPeriodCostsBreakdown data={dataCostsBreakdown} 
                                        loading={loadingCostsBreakdown} 
                                        setPeriod={setCostsBreakdownPeriod} />
          </Col>
        </Row>
      )}
      <Row className="d-flex my-5" data-testid="savings-container">
        <Col lg={12}>
          <CurrentPeriodCostsProjections data={dataCostsProjections} loading={loadingCostsProjections} setPeriod={setPeriod} />
        </Col>
      </Row>
    </>
  );
};

export default SavingsContainer;
