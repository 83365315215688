import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/es';
import GDMTH_DEMANDS from '../../../../../graphql/queries/gdmth_demanda';
import { chartMaxValue } from '../../../../shared/sharedFunctions/sharedFunctions';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.locale('es');

const reducedDates = (data) => {
    return data.reduce((acc, item) => {
      const value = Number((item.y).toFixed(2));
      const date = item.x;
      const dayjsDate = dayjs(date, "DD/MM/YYYY");
  
        acc[date] = {
          value,
          dayjsDate,
        }
  
        return acc;
    }, {});
  }

  export const useCfeDemandChart = (clientId, startDate, endDate) => {
    let minValue = Infinity;
    let maxValue = -Infinity;
    const { loading, error, data } = useQuery(GDMTH_DEMANDS, {
      variables: {clientId, startDate, endDate},
    });

    const graphData = useMemo(() => {
      const baseDemand = data?.demandsByDate?.cfeBaseDemand || [];
      const intDemand = data?.demandsByDate?.cfeIntDemand || [];
      const highDemand = data?.demandsByDate?.cfeHighDemand || [];
      const maxBaseDemand = data?.demandsByDate?.cfeMaxBaseDemand?.y || 0 ;
      const maxIntDemand = data?.demandsByDate?.cfeMaxIntDemand?.y || 0;
      const maxHighDemand = data?.demandsByDate?.cfeMaxHighDemand?.y || 0;
      

      if (baseDemand.length || intDemand.length || highDemand.length) {
        const reducedbaseDemandDates = reducedDates(baseDemand);
        const reducedintDemandDates = reducedDates(intDemand);
        const reducedhighDemandDates = reducedDates(highDemand);
       
        const dates = [
          ...Object.keys(reducedbaseDemandDates),
          ...Object.keys(reducedintDemandDates),
          ...Object.keys(reducedhighDemandDates),
          
        ].sort((a, b) => {
          const dateA = dayjs(a, 'YYYY/MM/DD', 'es');
          const dateB = dayjs(b, 'YYYY/MM/DD', 'es');
  
          return dateA.isBefore(dateB) ? -1 : 1;
        })
  
        const uniqueDates = Array.from(new Set(dates));
        
        const days = uniqueDates.map(date => {
           let newFormatDate = dayjs(date, "YYYY/MM/DD").format('DD/MM/YYYY');
           newFormatDate = newFormatDate.charAt(0).toUpperCase() + newFormatDate.slice(1);
           newFormatDate = newFormatDate.replace('.-', ' ');
  
           return {
             date: newFormatDate,
             baseDemand: reducedbaseDemandDates[date]?.value || 0,
             intDemand: reducedintDemandDates[date]?.value || 0,
             highDemand: reducedhighDemandDates[date]?.value || 0,
           }
        });
        const  results = {
          days: days,
          maxDemandBase: maxBaseDemand,
          maxDemandInt: maxIntDemand,
          maxDemandHigh: maxHighDemand,
        }

        return results;
      }
  
      return [];
    }, [data]);
  
    if (graphData.days?.length) {
      minValue = Math.min(minValue);
      maxValue = chartMaxValue(Math.max(graphData.maxDemandBase, graphData.maxDemandInt, graphData.maxDemandHigh));
    }
  
    return {
      loading,
      error,
      graphData,
      minValue: Math.floor(minValue),
      maxValue: Math.ceil(maxValue),
    };
  };
  
  export const useTotalDemandChart = (clientId, startDate, endDate) => {
    let minValue = Infinity;
    let maxValue = -Infinity;
    const { loading, error, data } = useQuery(GDMTH_DEMANDS, {
      variables: {clientId, startDate, endDate},
    });

    const graphData = useMemo(() => {
      const baseDemand = data?.demandsByDate?.totalBaseDemand || [];
      const intDemand = data?.demandsByDate?.totalIntDemand || [];
      const highDemand = data?.demandsByDate?.totalHighDemand || [];
      const maxBaseDemand = data?.demandsByDate?.totalMaxBaseDemand?.y || 0 ;
      const maxIntDemand = data?.demandsByDate?.totalMaxIntDemand?.y || 0;
      const maxHighDemand = data?.demandsByDate?.totalMaxHighDemand?.y || 0;
      

      if (
        baseDemand.length || intDemand.length ||
        highDemand.length
      ) {
  
        const reducedbaseDemandDates = reducedDates(baseDemand);
        const reducedintDemandDates = reducedDates(intDemand);
        const reducedhighDemandDates = reducedDates(highDemand);
       
        const dates = [
          ...Object.keys(reducedbaseDemandDates),
          ...Object.keys(reducedintDemandDates),
          ...Object.keys(reducedhighDemandDates),
          
        ].sort((a, b) => {
          const dateA = dayjs(a, 'YYYY/MM/DD', 'es');
          const dateB = dayjs(b, 'YYYY/MM/DD', 'es');
  
          return dateA.isBefore(dateB) ? -1 : 1;
        })
  
        const uniqueDates = Array.from(new Set(dates));
        
        const days = uniqueDates.map(date => {
          let newFormatDate = dayjs(date, "YYYY/MM/DD").format('DD/MM/YYYY');
          newFormatDate = newFormatDate.charAt(0).toUpperCase() + newFormatDate.slice(1);
          newFormatDate = newFormatDate.replace('.-', ' ');

          return {
            date: newFormatDate,
            baseDemand: reducedbaseDemandDates[date]?.value || 0,
            intDemand: reducedintDemandDates[date]?.value || 0,
            highDemand: reducedhighDemandDates[date]?.value || 0,
          }
        });
        const  results = {
          days: days,
          maxDemandBase: maxBaseDemand,
          maxDemandInt: maxIntDemand,
          maxDemandHigh: maxHighDemand,
        }

        return results;
      }
  
      return [];
    }, [data]);
  
    if (graphData.days?.length) {
      if (graphData.days?.length) {
        minValue = Math.min(minValue);
        maxValue = chartMaxValue(Math.max(graphData.maxDemandBase, graphData.maxDemandInt, graphData.maxDemandHigh));
      }
    }
  
    return {
      loading,
      error,
      graphData,
      minValue: Math.floor(minValue),
      maxValue: Math.ceil(maxValue),
    };
  };
  
  