import { gql } from 'apollo-boost';

const SAVINGS_WITH_PANELS = gql`
  query savingsWithPanels($clientId: ID!) {
    savingsWithPanels(clientId: $clientId) {
      nextPaymentToDate
      nextPaymentToDateWithNoPanels
      nextPaymentAt
      savingsPercentage
      tonsCo2Avoided
    }
  }
`

export default SAVINGS_WITH_PANELS;