import { gql } from "apollo-boost";

const CLIENT_BY_TOKEN = gql`
  query clientByToken($setupToken: String!) {
    clientByToken(setupToken: $setupToken) {
      client {
        id
        name
        email
        customId
      }
      home {
        street
        externalNumber
        neighborhood
        zipcode
        city
        stateId
        latitude
        longitude
      }
      cfeHome {
        installedKw
      }
      meterGateways {
        id
        name
        key
        meterDevices {
          id
          name
          measureType
          note
        }
      }
      meterDevices {
        name
        measureType
        note
      }
    }
  }
`

export default CLIENT_BY_TOKEN;
