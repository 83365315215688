import React from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import InfoMessage from '../../../../../shared/InfoMessage/InfoMessage';
import { I_ICON_MESSAGES } from '../../../../../shared/InfoMessage/messages';

  const BlueSwitch = withStyles({
    switchBase: {
      color: '#4FD7CF',
      '&$checked': {
        color: '#4FD7CF', 
      },
      '&$checked + $track': {
        backgroundColor: '#4FD7CF', 
      },
    },
    track: {
      backgroundColor: '#4FD7CF', 
    },
    checked: {},
  })(Switch);

  export default function DemandSwitch({ isDemandSwitchOn, setIsDemandSwitchOn }) {
    const handleChange = (event) => {
      setIsDemandSwitchOn(event.target.checked);
    };
  
    const CFElabelStyle = {
      color: '#ffffff',
    };
  
    const TotallabelStyle = {
      color:  '#ffffff',
    };

  return (
    <div className="d-flex justify-content-center">
       <InfoMessage className="pl-4" placement="left" message={I_ICON_MESSAGES.demandChart.message} />
        <label className="text-right mt-2"
               style={CFElabelStyle}>
               CFE
        </label>
        <BlueSwitch
          defaultChecked
          className="switch"
          checked={isDemandSwitchOn}
          onChange={handleChange}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <label
          className='mt-2'
          style={TotallabelStyle}>
          Operacional / Total
        </label>
      </div>
    
  );
}