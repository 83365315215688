import { gql } from "apollo-boost";

const USERNAME_GATEWAY = gql`
  query usernameGateway($clientId: ID!) {
    usernameGateway(clientId: $clientId) {
      client {
        id
        name
        email
        invitedToSignUp
        invitationAccepted
        home {
          address
        }
        cfeHome {
          cfeFeeType
          cfeInterconnectionAt
        }
      }
      meterGateways {
        id
        active
        status
        activationCode
        meterDevices {
          name
          measureType
        }
      }
      acceptInvitationUrl
    }
  }
`;

export default USERNAME_GATEWAY;
