import React from 'react';

export const getTotal = (valueOne, valueTwo) => {
  if (valueOne === null && valueTwo === null) {
    return null;
  }

  valueOne = valueOne ?? 0;
  valueTwo = valueTwo ?? 0;

  return Math.round((valueOne + valueTwo) * 100) / 100;
};

const TotalConfigTooltip = ({ payload, active, label }) => {
  if (active) {
    const dataInfo = payload[0] !== undefined ? payload[0] : null;
    const chartInfo = dataInfo?.payload ?? null;
    const dataKey = dataInfo?.dataKey ?? null;
    const unit = dataInfo?.unit ?? null;
    
    const totalConsumption = getTotal(
      chartInfo?.cfeConsumption,        
      chartInfo?.solarConsumption
    );
      
      return (
        <div className="custom-tooltip p-2"  data-testid="config-tooltip">
          <p className="label text-secundary">{label}</p>
          <p className="text-primary label">
          Consumo CFE: {chartInfo?.cfeConsumption?.toLocaleString() || 0} {unit}
          </p>
          <p className="text-yellow label">
          Consumo Solar: {chartInfo?.solarConsumption?.toLocaleString() || 0} {unit}
          </p>
          <p className="text-blue label">
          Generación Inyectada: {chartInfo?.injectedGeneration?.toLocaleString() || 0} {unit}
          </p>
          <p className="text-secondary label">
          Consumo Total: {totalConsumption?.toLocaleString() || 0} {unit}
          </p>
        </div>
            
      );
  } else {
    return null;
  };
}
export default TotalConfigTooltip;
