import { gql } from "apollo-boost";

const USER_INFO = gql`
  query userInfo {
    userInfo {
      id
      name
      email
      authorityLevel
      headerImageUrl
    }
  }
`;

export default USER_INFO;
