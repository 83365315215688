import { CHARTS_COLORS } from './ChartsColors';

export const ALL_DAILY_READINGS_CHART_CONFIGS = {
  solarConsumption: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Consumo Solar',
    opacity: 0.9,
    tooltipClass: 'text-yellow'
  },
  cfeConsumption: {
    color: CHARTS_COLORS.cyan,
    legendName: 'Consumo de CFE',
    opacity: 0.9,
    tooltipClass: 'text-cyan'
  },
  consumedGeneration: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Generación Consumida',
    opacity: 0.9,
    tooltipClass: 'text-yellow'
  },
  injectedGeneration: {
    color: CHARTS_COLORS.blue,
    legendName: 'Generación Inyectada',
    opacity: 0.9,
    tooltipClass: 'text-blue'
  }
};

export const ALL_DAILY_READINGS_CHART_CONSUMPTION_CONFIGS = {
  cfeConsumption: {
    color: CHARTS_COLORS.blue,
    legendName: 'Consumo Total',
    opacity: 0.9,
    tooltipClass: 'text-blue'
  },
  consumedGeneration: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Generación Total',
    opacity: 0.9,
    tooltipClass: 'text-yellow'
  }
};

export const ALL_LIVE_AND_MONTHLY_CHART_CONFIGS = {
  solarConsumption: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Consumo Solar',
    opacity: 0.9,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 0,
    barSize: 100,
    tooltipClass: 'text-yellow'
  },
  cfeConsumption: {
    color: CHARTS_COLORS.blue,
    legendName: 'Consumo de CFE',
    opacity: 0.9,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 0,
    barSize: 100,
    tooltipClass: 'text-blue'
  },
  consumedGeneration: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Generación Consumida',
    opacity: 0.8,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-yellow'
  },
  injectedGeneration: {
    color: CHARTS_COLORS.blue,
    legendName: 'Generación Inyectada',
    opacity: 0.8,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-blue'
  },
}

export const ALL_LIVE_AND_MONTHLY_CHART_CONSUMPTION_CONFIGS = {
  cfeConsumption: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Consumo Total',
    opacity: 0.7,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 0,
    barSize: 100,
    tooltipClass: 'text-yellow'
  },
  consumedGeneration: {
    color: CHARTS_COLORS.blue,
    legendName: 'Generación Total',
    opacity: 0.8,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-blue'
  },
}

export const MEDIUM_VOLTAGE_CONSUMPTION_CONFIGS = {
  baseConsumption: {
    color: CHARTS_COLORS.blue,
    legendName: 'Base',
    opacity: 0.7,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 0,
    barSize: 100,
    tooltipClass: 'text-blue'
  },
  intermediateConsumption: {
    color: CHARTS_COLORS.cyan,
    legendName: 'Intermedio',
    opacity: 0.8,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-cyan'
  },
  highConsumption: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Punta',
    opacity: 0.8,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-yellow'
  },
}

export const MEDIUM_VOLTAGE_GENERATION_CONFIGS = {
  baseGeneration: {
    color: CHARTS_COLORS.blue,
    legendName: 'Base',
    opacity: 0.7,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 0,
    barSize: 100,
    tooltipClass: 'text-blue'
  },
  intermediateGeneration: {
    color: CHARTS_COLORS.cyan,
    legendName: 'Intermedia',
    opacity: 0.8,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-cyan'
  },
  highGeneration: {
    color: CHARTS_COLORS.yellow,
    legendName: 'Punta',
    opacity: 0.8,
    strokeWidth: 2,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-yellow'
  },
}

export const CFE_DEMAND_CONFIGS = {
  highDemand: {
    color: CHARTS_COLORS.yellow,
    legendName: 'en Punta',
    opacity: 0.3,
    strokeWidth: 1,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-yellow'
  },
  
  intDemand: {
    color: CHARTS_COLORS.cyan,
    legendName: 'Intermedia',
    opacity: 0.3,
    strokeWidth: 1,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-cyan'
  },
  baseDemand: {
    color: CHARTS_COLORS.blue,
    legendName: 'Base',
    opacity: 0.3,
    strokeWidth: 1,
    radius: 10,
    xAxisId: 0,
    barSize: 100,
    tooltipClass: 'text-blue'
  },
 }

export const TOTAL_DEMAND_CONFIGS = {
  baseDemand: {
    color: CHARTS_COLORS.blue,
    legendName: 'Base',
    opacity: 0.3,
    strokeWidth: 1,
    radius: 10,
    xAxisId: 0,
    barSize: 100,
    tooltipClass: 'text-blue'
  },
  intDemand: {
    color: CHARTS_COLORS.cyan,
    legendName: 'Intermedia',
    opacity: 0.3,
    strokeWidth: 1,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-cyan'
  },
  highDemand: {
    color: CHARTS_COLORS.yellow,
    legendName: 'en Punta',
    opacity: 0.3,
    strokeWidth: 1,
    radius: 10,
    xAxisId: 1,
    barSize: 100,
    tooltipClass: 'text-yellow'
  },
 }

export const REFERENCE_LINES_CONFIGS = {
  maxBaseDemand: {
    strokeWidth: 2,
    position: 'insideTop',
    value: 'Demanda Base Máxima',
    fill: CHARTS_COLORS.blue, 
    fontSize: 14,
    textStroke: CHARTS_COLORS.bg,
  },
  maxIntDemand:  {
    strokeWidth: 2,
    position: 'insideTop',
    value: 'Demanda Intermedia Máxima',
    fill: CHARTS_COLORS.cyan, 
    fontSize: 14,
    textStroke: CHARTS_COLORS.bg,
  },
  maxHighDemand: {
    strokeWidth: 2,
    position: 'insideTop',
    value: 'Demanda en Punta Máxima',
    fill: CHARTS_COLORS.yellow, 
    fontSize: 14 ,
    textStroke: CHARTS_COLORS.bg,
  },
 
};

export const OPERATIVE_LINES_CONFIGS = {
  l1: {
    key: 1,
    strokeWidth: 1,
    fill: CHARTS_COLORS.cyan, 
    legendName: ' Linea 1',
    opacity: 0.7,
    tooltipClass: 'text-cyan',
    color: CHARTS_COLORS.cyan,
  },
  l2: {
    key: 2,
    strokeWidth: 1,
    fill: CHARTS_COLORS.yellow, 
    legendName: ' Linea 2',
    opacity: 0.7,
    tooltipClass: 'text-yellow',
    color: CHARTS_COLORS.yellow,
  },
  l3: {
    key: 3,
    strokeWidth: 1,
    fill: CHARTS_COLORS.blue, 
    legendName: ' Linea 3',
    opacity: 0.7,
    tooltipClass: 'text-blue',
    color: CHARTS_COLORS.blue,
  },

  
};

export const selectConfigs = (desiredConfigs, configs) => {
  return Object.fromEntries(
    Object.entries(configs).filter((config) =>
      desiredConfigs.includes(config[0])
    )
  );
};
